<template>
    <v-sheet class="top-banner-wrapper">
        <v-expand-transition>
            <v-alert v-show="shows" width="100%" dismissible class="top-banner">
                <component v-bind:is="linkAttrs.is" v-bind="linkAttrs">
                    <v-img v-bind="{ src }" contain max-width="100%" max-height="160" width="100%" height="100%" class="mx-auto" />
                </component>
                <template #close>
                    <v-btn absolute right center fab color="transparent" @click="hideItem(item)">
                        <v-icon size="24">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-alert>
        </v-expand-transition>
    </v-sheet>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
    props: {
        item: Object,
        isPc: Boolean,
    },
    computed: {
        ...mapState({ disableds: (state) => state.bandBanners.disableds }),
        shows() {
            return !this.disableds.includes(this.item?._id);
        },
        src() {
            return this.isPc ? this.item?.imagePc?.url : this.item?.imageMb?.url;
        },
        linkAttrs() {
            const { href } = this.item || {};
            if (href.includes("://")) return { is: "a", href };
            else return { is: "router-link", to: href };
        },
    },
    methods: {
        ...mapMutations("bandBanners", ["hideItem"]),
    },
};
</script>

<style lang="scss" scoped>
// .top-banner-wrapper {
//     margin-top: var(--header-body-height);
// }
.top-banner {
    margin: 0;
    padding: 0;
    &.v-sheet.v-alert:not(.v-sheet--outlined) {
        border-radius: 0 !important;
        box-shadow: none !important;
    }
    ::v-deep {
        .v-alert__content {
            width: 100%;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    // .top-banner-wrapper {
    //     margin: 0;
    // }
}
@media (min-width: 1200px) {
}
</style>
