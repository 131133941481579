var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "map-sheet-actions",
    attrs: {
      "rounded": "sm",
      "color": "white",
      "elevation": "6"
    }
  }, [_c('v-btn', {
    staticClass: "white d-flex",
    attrs: {
      "icon": "",
      "small": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.zoom('in');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1), _c('v-divider'), _c('v-btn', {
    staticClass: "white d-flex",
    attrs: {
      "icon": "",
      "small": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.zoom('out');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }