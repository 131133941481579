var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', _vm._b({
    attrs: {
      "top": "",
      "offset-y": "",
      "close-on-content-click": false,
      "content-class": "map-overlay-menu"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('overlay-marker', _vm._b({
          ref: "map-overlay"
        }, 'overlay-marker', {
          attrs,
          on,
          locationsCount: 1
        }, false))];
      }
    }]),
    model: {
      value: _vm.showsMenu,
      callback: function ($$v) {
        _vm.showsMenu = $$v;
      },
      expression: "showsMenu"
    }
  }, 'v-menu', {
    nudgeTop: _vm.nudgeTop,
    nudgeLeft: _vm.nudgeLeft,
    rounded: _vm.rounded
  }, false), [_c('div', {
    staticClass: "map-overlay-menu__inner"
  }, [_c('v-card', {
    staticClass: "white pa-20px",
    attrs: {
      "elevation": "6",
      "rounded": "xs"
    }
  }, [_c('v-row', {
    staticClass: "ma-n4px"
  }, [_c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "62"
    }
  }, [_vm.item.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.item.thumb.src
    }
  }) : _c('v-img', {
    attrs: {
      "src": "/images/minishop/profile-default.png"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-4px txt"
  }, [_c('div', {
    staticClass: "txt--xs"
  }, [_vm._v(_vm._s(_vm.item.companyName))]), _c('div', {
    staticClass: "txt--sm txt--dark text-truncate-2"
  }, [_vm._v(_vm._s(_vm.item.address1) + " " + _vm._s(_vm.item.address2))])]), _c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small",
    attrs: {
      "to": `/minishop/${_vm.item._id}`,
      "block": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("바로가기")])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }