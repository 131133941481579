<template>
    <footer class="footer">
        <!-- <v-sheet class="d-lg-none">
            <div class="py-12px">
                <v-container>
                    <v-expansion-panels flat class="d-lg-none">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div class="txt txt--dark font-weight-medium">법적고지</div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="txt txt--sm">
                                모아고(레일리)는 통신판매중개자이며, 통신판매의 당사자가 아님을 알려드립니다. 입점사의 서비스, 상품 거래에 관한 의무와 책임은 판매자에게 있습니다.<br />
                                또한 당사는 판매자와 구매자간의 직거래에 대하여 관여하지 않습니다.
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-container>
            </div>
            <v-divider />
        </v-sheet> -->

        <div class="footer-head py-12px pt-lg-40px pb-lg-0">
            <v-container class="container--lg">
                <!-- S: PC footer-head -->
                <main-footer-head class="d-none d-lg-flex">
                    <div class="tit-wrap tit-wrap--sm">
                        <p class="txt txt--sm txt--dark line-height-2">CUSTOMER CENTER</p>
                        <h3 class="tit tit--xs font-weight-regular">070-5151-6665</h3>
                    </div>
                </main-footer-head>
                <!-- E: PC footer-head -->

                <!-- S: Mobile footer-head -->
                <v-sheet class="d-lg-none">
                    <v-expansion-panels flat>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <p class="txt txt--sm txt--dark line-height-2">CUSTOMER CENTER</p>
                                    <h3 class="tit tit--xs font-weight-regular">070-5151-6665</h3>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <main-footer-head></main-footer-head>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-sheet>
                <!-- E: Mobile footer-head -->
                <v-divider class="mt-26px d-none d-lg-block" />
            </v-container>
        </div>

        <div class="footer-body">
            <v-container class="container--lg">
                <!-- S: PC footer-info -->
                <v-row no-gutters align="center" class="d-none d-lg-flex">
                    <v-col>
                        <v-row class="row--xxs d-none d-lg-block">
                            <v-col cols="12">
                                <div class="divider-group">
                                    <ul class="font-weight-medium">
                                        <li><router-link to="/sub/business/about">COMPANY</router-link></li>
                                        <li><router-link to="/sub/business/terms">AGREEMENT</router-link></li>
                                        <li><router-link to="/sub/business/privacy-policy">PRIVACY POLICY</router-link></li>
                                        <li><router-link to="/sub/business/site-guide">GUIDE</router-link></li>
                                    </ul>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <!-- <v-col cols="12">
                                <span class="txt txt--xs">모아고(레일리)는 통신판매중개자이며, 통신판매의 당사자가 아님을 알려드립니다. 입점사의 서비스, 상품 거래에 관한 의무와 책임은 판매자에게 있습니다. 또한 당사는 판매자와 구매자간의 직거래에 대하여 관여하지 않습니다.</span>
                            </v-col> -->
                            <v-col cols="12">
                                <div class="inline-group">
                                    <ul>
                                        <li>COMPANY: 주식회사 레일리</li>
                                        <li>CEO: 김지호</li>
                                        <li>ADDRESS: 경기도 부천시 원미구 조마루로385번길 92(부천테크노밸리 U1 center)1712호</li>
                                        <li>TEL: 070-5151-6665</li>
                                        <li>BUSINESS LICENCE: 378-88-03325</li>
                                    </ul>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="inline-group">
                                    <ul>
                                        <li>ONLINE LICENCE: 2024-부천원미-1027</li>
                                        <li>발행인:김지호</li>
                                    </ul>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="footer-copyright">
                                    <p>COPYRIGHT (c) 모아고  ALL RIGHTS RESERVED.</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto">
                        <u-dialog v-model="shows" width="auto">
                            <template #activator="{ attrs, on }">
                                <v-img v-bind="{ attrs, on }" v-on="on" width="84" :aspect-ratio="150 / 176" src="/images/nicepay-escrow.png" class="cursor-pointer" />
                            </template>
                            <v-img max-width="594" src="/images/nicepay-img1.png" />
                            <v-img max-width="594" src="/images/nicepay-img2.png" />
                        </u-dialog>
                    </v-col>
                </v-row>
                <!-- E: PC footer-info -->

                <!-- S: Mobile footer-info -->
                <v-expansion-panels flat class="d-lg-none">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="txt txt--dark font-weight-medium">모아고(Moago)</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="txt txt--sm">
                            <v-row class="row--xxs">
                                <v-col cols="auto"><v-card min-width="130">CEO</v-card></v-col>
                                <v-col>: 김지호</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto"><v-card min-width="130">ADDRESS</v-card></v-col>
                                <v-col>: 경기도 부천시 조마루로385번길 92(부천테크노밸리 U1 center)1712호</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto"><v-card min-width="130">TEL</v-card></v-col>
                                <v-col>: 070-5151-6665</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto"><v-card min-width="130">BUSINESS LICENCE</v-card></v-col>
                                <v-col>: 378-88-03325</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto"><v-card min-width="130">ONLINE LICENCE</v-card></v-col>
                                <v-col>: 2024-부천원미-1027</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto"><v-card min-width="130">발행인</v-card></v-col>
                                <v-col>: 김지호</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">
                                    <u-dialog v-model="shows">
                                        <template #activator="{ attrs, on }">
                                            <v-img v-bind="{ attrs, on }" v-on="on" width="60" :aspect-ratio="150 / 176" src="/images/nicepay-escrow.png" class="cursor-pointer" />
                                        </template>
                                        <v-img src="/images/nicepay-img.png" />
                                    </u-dialog>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <!-- E: Mobile footer-info -->

                <div class="divider-group mt-22px d-lg-none">
                    <ul>
                        <li><router-link to="/sub/business/about">COMPANY</router-link></li>
                        <li><router-link to="/sub/business/terms">AGREEMENT</router-link></li>
                        <li><router-link to="/sub/business/privacy-policy">PRIVACY POLICY</router-link></li>
                        <li><router-link to="/sub/business/site-guide">GUIDE</router-link></li>
                    </ul>
                </div>
            </v-container>
        </div>
    </footer>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import IconArrowRounded from "../icons/icon-arrow-rounded.vue";

import MainFooterHead from "@/components/client/inc/main-footer-head.vue";

export default {
    components: {
        UDialog,
        IconArrowRounded,

        MainFooterHead,
    },
    data: () => {
        return {
            attrs_input,

            carts: [],

            searchKey: "name",
            searchValue: null,

            shows: false,

            // scrollTop
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            this.$nextTick(function () {
                this.footer();
            });
        },

        // footer
        footer: function () {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function (e) {
                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
                scrollTop();
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                }
                scrollAnimate();
            }

            // Scroll Top
            function scrollTop() {
                if (scrollPosition > window.innerHeight / 2) {
                    HTML.setAttribute("data-scroll-top", "true");
                } else {
                    HTML.removeAttribute("data-scroll-top");
                }
            }
        },
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel {
    &-header {
        padding: 0 !important;
    }
    ::v-deep() {
        .v-expansion-panel-content__wrap {
            padding: 0 0 16px !important;
        }
    }
}
</style>
