import { SHOP_COUPON_UNITS } from "../constants";
import { initUsage } from "./common.inits";
import { initIssue } from "./common.inits";

export const initShopCoupon = (coupon = {}) => ({
    ...coupon,

    _seller: coupon._seller ?? null,

    name: coupon.name ?? null,
    unit: coupon.unit ?? SHOP_COUPON_UNITS.AMOUNT.value,
    value: coupon.value ?? 0,
    limit: coupon.limit ?? 0,
    share: coupon.share ?? (!!coupon._seller ? 100 : 0),
    count: coupon.count ?? 1,

    issue: initIssue(coupon.issue),
    usage: initUsage(coupon.usage),

    onConsole: coupon.onConsole ?? true,
    onDisplay: coupon.onDisplay ?? false,
});
