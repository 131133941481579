var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": "/sub/store"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_txt), false), [_vm._v("더보기 "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, _vm._l(_vm.stores, function (store, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6",
        "xl": "4"
      }
    }, [_c('recommed-store-item', {
      attrs: {
        "value": store
      }
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("모아고 추천업체")])]);

}]

export { render, staticRenderFns }