/**
 * 공급받는자 데이터
 * @param {TaxinvoiceInvoiceeData} data
 * @returns {TaxinvoiceInvoiceeData}
 */
export const initShopTaxinvoiceInvoicee = (data = {}) => ({
    invoiceeMgtKey: data.invoiceeMgtKey,
    invoiceeType: data.invoiceeType,
    invoiceeCorpNum: data.invoiceeCorpNum,
    invoiceeTaxRegID: data.invoiceeTaxRegID,
    invoiceeCorpName: data.invoiceeCorpName,
    invoiceeCEOName: data.invoiceeCEOName,
    invoiceeAddr: data.invoiceeAddr,
    invoiceeBizType: data.invoiceeBizType,
    invoiceeBizClass: data.invoiceeBizClass,
    invoiceeContactName1: data.invoiceeContactName1,
    invoiceeDeptName1: data.invoiceeDeptName1,
    invoiceeTEL1: data.invoiceeTEL1,
    invoiceeHP1: data.invoiceeHP1,
    invoiceeEmail1: data.invoiceeEmail1,
    invoiceeSMSSendYN: data.invoiceeSMSSendYN,
});

export default initShopTaxinvoiceInvoicee;

/**
 * @typedef {object} TaxinvoiceInvoiceeData 공급받는자 데이터
 *
 * @property {string} [invoiceeMgtKey] 33.공급받는자 문서번호
 * * 문서 관리를 위해 파트너가 할당하는 고유 식별번호
 * * 영문 대소문자, 숫자, 특수문자('-', '_')만 입력 가능
 * * {issueType}="역발행" 경우 필수
 *
 * @property {'사업자' | '개인' | '외국인'} invoiceeType 34.공급받는자 구분 (택 1)
 *
 * @property {string} invoiceeCorpNum 35.공급받는자 등록번호
 * * '-' 제외
 * * {invoiceeType}="사업자" 경우 사업자번호
 * * {invoiceeType}="개인" 경우 주민등록번호
 * * {invoiceeType}="외국인" 경우 "9999999999999"
 *
 * @property {string} [invoiceeTaxRegID] 36.공급받는자 종사업장 식별번호
 * * 종사업장 식별번호는 4자리 숫자로만 구성
 *
 * @property {string} invoiceeCorpName 37.공급받는자 상호
 *
 * @property {string} invoiceeCEOName 38.공급받는자 대표자 성명
 *
 * @property {string} [invoiceeAddr] 39.공급받는자 주소
 *
 * @property {string} [invoiceeBizType] 40.공급받는자 업태
 *
 * @property {string} [invoiceeBizClass] 41.공급받는자 종목
 *
 * @property {string} [invoiceeContactName1] 42.공급받는자 담당자 성명
 * 
 * @property {string} [invoiceeDeptName1] 43.공급받는자 담당자 부서명
 *
 * @property {string} [invoiceeTEL1] 44.공급받는자 담당자 연락처
 *
 * @property {string} [invoiceeHP1] 45.공급받는자 담당자 휴대폰
 *
 * @property {string} [invoiceeEmail1] 46.공급받는자 담당자 이메일
 *
 * @property {boolean} [invoiceeSMSSendYN] 47.공급받는자 알림문자 전송 여부
 * * true: 전송
 * * false: 미전송(기본값)
 * * 수신자 : 공급자 휴대폰번호 {invoicerHP}
 * * 문자 전송 포인트가 차감(단, 전송실패 건은 환불)
 * * {issueType}="역발행" 경우 세금계산서 역발행 요청시점에 알림문자 전송
 */
