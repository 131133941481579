import { DISPLAY_CODES } from "../constants";

export const initSettingShopDisplayCode = (code = {}) => ({
    ...code,

    text: code.text || Object.values(DISPLAY_CODES).find(({ value }) => value == code.value)?.text || null,
    value: code.value ?? null,
    color: code.color || "#424242FF" || null,
    shows: code.shows ?? true,

    tempId: code.tempId ?? Math.random(),
    disabled: Object.keys(DISPLAY_CODES).includes(code.value),
});

export const initSetting = (setting = {}) => ({
    ...(setting || {}),

    shop: {
        ...(setting?.shop || {}),

        cashReceipt: {
            ...(setting?.shop?.cashReceipt || {}),
        },

        displayCodes: [...(setting?.shop?.displayCodes || [])],
        showroomCodes: [...(setting?.shop?.showroomCodes || [])],
    },

    seo: {
        ...(setting?.seo || {}),
        meta: setting?.seo?.meta || [],
    },

    main: {
        ...(setting?.main || {}),
        care: {
            ...(setting?.main?.care || {}),
            text: setting?.main?.care?.text || null,
            link: setting?.main?.care?.link || null,
            desc: setting?.main?.care?.desc || null,
            isOuter: /https?:\/\//.test(setting?.main?.care?.link?.trim?.() || ""),
        },
    },

    bannedKeywords: setting?.bannedKeywords || null,
});
