import { SHOP_MONEY_UNITS, USER_MONEY_TYPES } from "../constants";
import { initIssue, initUsage } from "./common.inits";

export const initUserMoney = (money = {}) => ({
    ...money,

    _user: money._user ?? null,
    _money: money._money ?? null,
    _purchase: money._purchase ?? null,
    _withdrawals: [...(money._withdrawals ?? [])],

    code: money.code ?? null,
    name: money.name ?? null,
    type: money.type ?? 0 <= (money.amount || 0) ? USER_MONEY_TYPES.INCREASE.value : USER_MONEY_TYPES.DECREASE.value,
    unit: money.unit ?? SHOP_MONEY_UNITS.AMOUNT.value,

    amount: money.amount ?? 0,
    amount_left: money.amount_left ?? 0,
    amount_used: money.amount_used ?? 0,

    remain: money.remain ?? 0,
    remain_site: money.remain_site ?? 0,

    isDeleted: money.isDeleted ?? false,
    isDisabled: money.isDisabled ?? false,
    isExcluded: money.isExcluded ?? false,
    isWithdrawn: money.isWithdrawn ?? false,

    issue: initIssue(money.issue),
    usage: initUsage(money.usage),

    withdrawnAt: money.withdrawnAt ?? null,
});
