import { RESERVATION_PAYMENT_STATUS, RESERVATION_STATUS } from "../constants";

export const initScheduleTime = (time = {})=> ({
    startsAt: time.startsAt || "09:00",
    endsAt: time.endsAt || "10:00",
})

export const initSchedule = (schedule = {})=> ({
    ...schedule,

    open: schedule.open || initScheduleTime(),
    breaks: schedule.breaks || [],
    reservationTime: schedule.reservationTime || [ initScheduleTime() ],
    holidays: schedule.holidays || [],
    excludedDates: schedule.excludedDates || [],
});

export const initService = (service = {})=> ({
    ...service,

    name: service.name || null,
    description: service.description || null,
    price: service.price ?? 0,
    type: service.type || null,

    sequence: service.sequence ?? 0,
    sells: service.sells ?? false,
    shows: service.shows ?? false,
});

export const initReservation = (form = {})=> ({
    ...form,

    _user: form?._user || null,
    _seller: form?._seller || null,
    _service: form?._service || null,

    orderNo: form?.orderNo || null,
    reservationStatus: form?.reservationStatus || RESERVATION_STATUS.RESERVATION_AWAITING.value,
    paymentStatus: form?.paymentStatus || RESERVATION_PAYMENT_STATUS.PAYMENT_AWAITING.value,

    information: {
        date: form?.information?.date || null,
        startsAt: form?.information?.startsAt || null,
        endsAt: form?.information?.endsAt || null,
    },
    model: form?.model || null,
    content: form?.content || null,

    paymentAt: form?.paymentAt || null,
    completedAt: form?.completedAt || null,
    canceledAt: form?.canceledAt || null,
});