import axios from "@/plugins/axios";
import comments from "./comments";

let url = "/api/v1/users";

export default {
    comments,

    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`, data).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    certify(data) {
        return axios.post(`${url}/certify`, data).then((result) => result.data);
    },
    recovery(data) {
        return axios.post(`${url}/recovery`, data).then((result) => result.data);
    },
    existence(data) {
        return axios.post(`${url}/existence`, data).then((result) => result.data);
    },
};
