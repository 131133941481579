<template>
    <v-card rounded="sm" color="white" elevation="6" class="map-sheet-actions">
        <v-btn icon small tile @click="zoom('in')" class="white d-flex">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-divider />
        <v-btn icon small tile @click="zoom('out')" class="white d-flex">
            <v-icon>mdi-minus</v-icon>
        </v-btn>
    </v-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
    data: () => {
        return {
            zoomLvl: 0,
        };
    },
    computed: {
        ...mapState("location", ["lvl"]),
    },
    methods: {
        ...mapMutations("location", ["setLvl"]),
        async zoom(type) {
            switch (type) {
                case "in":
                    this.setLvl({ lvl: --this.zoomLvl });
                    break;
                case "out":
                    this.setLvl({ lvl: ++this.zoomLvl });
                    break;
            }
        },
        sync() {
            this.zoomLvl = this.lvl;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        lvl() {
            this.sync();
        },
    },
};
</script>

<style lang="scss" scoped>
.map-sheet-actions {
    position: absolute;
    bottom: 12px;
    right: 10px;
    z-index: 2;
    overflow: hidden;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
