<template>
    <v-card elevation="8" class="rounded-sm px-20px px-lg-24px py-18px" @click="go">
        <div class="tit-wrap tit-wrap--xs">
            <v-row align="center" class="ma-n4px">
                <v-col cols="auto" class="pa-4px">
                    <!-- 알림 제목 -->
                    <v-badge :value="value?.shows" dot color="accent2">
                        <v-card-title class="pa-0 txt txt--dark font-weight-medium">{{ value?.alarm?.name || value?.title }}</v-card-title>
                    </v-badge>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="pa-4px">
                    <!-- 날짜 -->
                    <div class="pa-0 txt txt--sm">{{ value.createdAt.toDate() }}</div>
                </v-col>
                <v-col cols="auto" class="pa-4px">
                    <v-card-actions>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon text class="v-size--xx-small" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-btn x-small color="#fff" @click="remove">삭제</v-btn>
                        </v-menu>
                    </v-card-actions>
                </v-col>
            </v-row>
        </div>
        <!-- 알림 내용 -->
        <v-card-text class="txt pa-0">
            <p class="grey--text text-truncate-2">{{ value?.content }}</p>
            <!-- 상품명이 있을 경우 -->
            <!-- <p class="grey--text mt-6px">상품명 : 락브로스 LF1030 겨울방한 토커버</p> -->
        </v-card-text>
    </v-card>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: Object, default: ()=> ({}) }
    },
    methods: {
        async remove() {
            try {
                await api.v1.me.alarms.delete(this.value);
                this.$emit("update");
            } catch(error) {
                this.$handleError(error);
            }
        },
        async go(){
            try {
                await api.v1.me.alarms.put({...this.value, shows: false});

                if(this.value?.url) this.$router.push(this.value.url);
                this.$emit("update");
            } catch(error) {
                this.$handleError(error);
            }
        }
    }
};
</script>