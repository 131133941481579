<template>
    <div>
        <div class="tit-wrap text-center">
            <h2 class="tit">가까운 AS 협력점 찾기</h2>
            <p class="txt txt--dark">위치(예 : 우편번호, 주소, 도시 등)를 입력하면 가까운 위치의 AS 협럭점이 표시됩니다.</p>
        </div>
        <map-main-search-bar/>
        <map-main-search-content/>
    </div>
</template>

<script>
import MapMainSearchBar from "./map-main-search-bar.vue"
import MapMainSearchContent from "./map-main-search-content.vue"
export default {
    components: {
        MapMainSearchBar,
        MapMainSearchContent
    },
    props: {
    },
    data: () => ({
    }),
    mounted() {
        // this.syncRoute();
    },
    watch: {
        // "$route.path"() {
        //     this.syncRoute();
        // },
    },
    methods: {
        // syncRoute() {
        //     const { query } = this.$route;
        //     let path = `/map/${this.mode}`;
        //     if (this.keyword) path += `/${this.keyword}`;
        //     if (this.$route.path !== path) this.$router.replace({ path, query });
        // },
    },
};
</script>

<style scoped>
.map-content {
    height: 70vh !important;
    padding-top: var(--header-body);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .map-content {
        height: 100vh !important;
    }
}
</style>
