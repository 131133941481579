import detailItem from "./detailItem";

/**
 * 품목상세항목 데이터
 * @param {TaxinvoiceDetailListData} data
 * @returns {TaxinvoiceDetailListData}
 */
export const initShopTaxinvoiceDetailList = (data = []) => data.map((item, index) => detailItem({ serialNum: item.serialNum ?? index + 1, ...item }));

export default initShopTaxinvoiceDetailList;

/**
 * @typedef {import("./detailItem").TaxinvoiceDetailItemData[]} TaxinvoiceDetailListData 품목상세목록 데이터
 */
