import { initUsage } from "./common.inits";
import { initIssue } from "./common.inits";

import { SHOP_MONEY_TYPES, SHOP_MONEY_UNITS } from "../constants/shop-moneys.constants";
import { ISSUE_EVENT_TYPES, ISSUE_ROUND_TYPES, ISSUE_TYPES } from "../constants/common.issue.constants";

export const initShopMoney = (money = {}) => ({
    ...money,

    type: money.type ?? SHOP_MONEY_TYPES.REWARD.value,

    name: money.name ?? null,
    unit: money.unit ?? SHOP_MONEY_UNITS.PERCENT.value,
    value: money.value ?? 0,
    limit: money.limit ?? 0,
    count: money.count ?? 1,

    issue: initIssue(money.issue),
    usage: initUsage(money.usage),
});

export const initShopMoney__charge = (money = {}) => {
    return initShopMoney({
        ...money,

        type: SHOP_MONEY_TYPES.CHARGE.value,

        issue: initIssue({
            ...(money.issue || {}),

            type: ISSUE_TYPES.INSTANT.value,

            event: {
                ...(money.issue?.event || {}),
                type: ISSUE_EVENT_TYPES.PURCHASE_COMPLETE.value,
            },

            round: {
                ...(money.issue?.round || {}),
                type: ISSUE_ROUND_TYPES.INFINITE.value,
            },
        }),

        usage: initUsage({
            ...(money.usage || {}),

            isActive: money.usage?.isActive ?? true,
        }),
    });
};
