var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-head py-12px pt-lg-40px pb-lg-0"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('main-footer-head', {
    staticClass: "d-none d-lg-flex"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark line-height-2"
  }, [_vm._v("CUSTOMER CENTER")]), _c('h3', {
    staticClass: "tit tit--xs font-weight-regular"
  }, [_vm._v("070-5151-6665")])])]), _c('v-sheet', {
    staticClass: "d-lg-none"
  }, [_c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', [_c('p', {
    staticClass: "txt txt--sm txt--dark line-height-2"
  }, [_vm._v("CUSTOMER CENTER")]), _c('h3', {
    staticClass: "tit tit--xs font-weight-regular"
  }, [_vm._v("070-5151-6665")])])]), _c('v-expansion-panel-content', [_c('main-footer-head')], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-26px d-none d-lg-block"
  })], 1)], 1), _c('div', {
    staticClass: "footer-body"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "d-none d-lg-flex",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-row', {
    staticClass: "row--xxs d-none d-lg-block"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider-group"
  }, [_c('ul', {
    staticClass: "font-weight-medium"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/about"
    }
  }, [_vm._v("COMPANY")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/terms"
    }
  }, [_vm._v("AGREEMENT")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/privacy-policy"
    }
  }, [_vm._v("PRIVACY POLICY")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/site-guide"
    }
  }, [_vm._v("GUIDE")])], 1)])])])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "inline-group"
  }, [_c('ul', [_c('li', [_vm._v("COMPANY: 주식회사 레일리")]), _c('li', [_vm._v("CEO: 김지호")]), _c('li', [_vm._v("ADDRESS: 경기도 부천시 원미구 조마루로385번길 92(부천테크노밸리 U1 center)1712호")]), _c('li', [_vm._v("TEL: 070-5151-6665")]), _c('li', [_vm._v("BUSINESS LICENCE: 378-88-03325")])])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "inline-group"
  }, [_c('ul', [_c('li', [_vm._v("ONLINE LICENCE: 2024-부천원미-1027")]), _c('li', [_vm._v("발행인:김지호")])])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "footer-copyright"
  }, [_c('p', [_vm._v("COPYRIGHT (c) 모아고  ALL RIGHTS RESERVED.")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-dialog', {
    attrs: {
      "width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-img', _vm._g(_vm._b({
          staticClass: "cursor-pointer",
          attrs: {
            "width": "84",
            "aspect-ratio": 150 / 176,
            "src": "/images/nicepay-escrow.png"
          }
        }, 'v-img', {
          attrs,
          on
        }, false), on))];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "594",
      "src": "/images/nicepay-img1.png"
    }
  }), _c('v-img', {
    attrs: {
      "max-width": "594",
      "src": "/images/nicepay-img2.png"
    }
  })], 1)], 1)], 1), _c('v-expansion-panels', {
    staticClass: "d-lg-none",
    attrs: {
      "flat": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', {
    staticClass: "txt txt--dark font-weight-medium"
  }, [_vm._v("모아고(Moago)")])]), _c('v-expansion-panel-content', {
    staticClass: "txt txt--sm"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "min-width": "130"
    }
  }, [_vm._v("CEO")])], 1), _c('v-col', [_vm._v(": 김지호")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "min-width": "130"
    }
  }, [_vm._v("ADDRESS")])], 1), _c('v-col', [_vm._v(": 경기도 부천시 조마루로385번길 92(부천테크노밸리 U1 center)1712호")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "min-width": "130"
    }
  }, [_vm._v("TEL")])], 1), _c('v-col', [_vm._v(": 070-5151-6665")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "min-width": "130"
    }
  }, [_vm._v("BUSINESS LICENCE")])], 1), _c('v-col', [_vm._v(": 378-88-03325")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "min-width": "130"
    }
  }, [_vm._v("ONLINE LICENCE")])], 1), _c('v-col', [_vm._v(": 2024-부천원미-1027")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "min-width": "130"
    }
  }, [_vm._v("발행인")])], 1), _c('v-col', [_vm._v(": 김지호")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-img', _vm._g(_vm._b({
          staticClass: "cursor-pointer",
          attrs: {
            "width": "60",
            "aspect-ratio": 150 / 176,
            "src": "/images/nicepay-escrow.png"
          }
        }, 'v-img', {
          attrs,
          on
        }, false), on))];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/nicepay-img.png"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "divider-group mt-22px d-lg-none"
  }, [_c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/about"
    }
  }, [_vm._v("COMPANY")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/terms"
    }
  }, [_vm._v("AGREEMENT")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/privacy-policy"
    }
  }, [_vm._v("PRIVACY POLICY")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/sub/business/site-guide"
    }
  }, [_vm._v("GUIDE")])], 1)])])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }