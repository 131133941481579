import bandBanners from "./bandBanners";
import bannerLogs from "./banner-logs";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import cert from "./cert";
import files from "./files";
import forms from "./forms";
import map from "./map";
import me from "./me";
import promoter from "./promoter";
import setting from "./setting";
import shop from "./shop";
import showcases from "./showcases";
import story from "./story";
import terms from "./terms";
import users from "./users";

export default {
    bandBanners,
    bannerLogs,
    banners,
    boards,
    center,
    cert,
    files,
    forms,
    map,
    me,
    promoter,
    setting,
    shop,
    story,
    showcases,
    terms,
    users,
};
