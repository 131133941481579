var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "map-sheet-actions"
  }, [_c('v-btn', {
    staticClass: "mr-6 mr-md-10",
    attrs: {
      "small": "",
      "fab": "",
      "color": "white",
      "elevation": "6"
    },
    on: {
      "click": _vm.setCurrent
    }
  }, [_c('v-icon', [_vm._v("mdi-crosshairs")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }