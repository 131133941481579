import axios from "@/plugins/axios";

let url = "/api/console/users/moneys/statistics";

export const statistics = {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
};

export default statistics;
