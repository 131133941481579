var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', {
    staticClass: "txt txt--xs font-weight-light"
  }, _vm._l(_vm.tags, function (tag, index) {
    return _c('v-chip', _vm._b({
      key: index,
      on: {
        "click": function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.search(tag);
        }
      }
    }, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("# " + _vm._s(tag))]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }