var render = function render(){
  var _vm$value, _vm$value2, _vm$value3, _vm$value3$mini;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "recommend-card text-center pa-12px",
    attrs: {
      "tile": "",
      "href": `/minishop/${_vm.value._id}`
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', [_c('v-avatar', {
    staticClass: "border mb-30px",
    attrs: {
      "size": "120"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "src": _vm.value.thumb ? _vm.value.thumb.src : '/images/minishop/profile-default.png'
    }
  })], 1), _c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit tit--xs font-weight-regular"
  }, [_vm._v(_vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.nickname))])]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v(_vm._s(_vm.value.address1) + " " + _vm._s(_vm.value.address2))]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.tel))]), _c('div', {
    staticClass: "mt-30px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "center"
    }
  }, [_vm._l((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$mini = _vm$value3.mini) === null || _vm$value3$mini === void 0 ? void 0 : _vm$value3$mini.tag, function (tag, index) {
    return [index < 3 ? _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', _vm._b({}, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("#" + _vm._s(tag))])], 1) : _vm._e()];
  })], 2)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }