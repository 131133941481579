<template>
    <!-- <v-card :to="value?.isOuterLink ? null : value?.url || null" :href="value?.isOuterLink ? value?.url || null : null" :target="value?.target" tile :img="value?.image"> -->
    <v-card :img="value?.image" @click="go" tile>
        <v-responsive :aspect-ratio="566 / 768">
            <div class="d-flex flex-column h-100 px-20px py-30px px-md-30px pa-xl-40px">
                <div class="tit-wrap mb-0">
                    <h2 v-html="value?.meta?.title" style="white-space: pre-line" class="tit tit--lg white--text font-weight-medium" />
                    <p class="txt txt--light font-weight-light">{{ value?.meta?.description }}</p>
                </div>
                <hashtag-search v-bind="{ tags: value?.meta?.tag }"/>
            </div>
        </v-responsive>
    </v-card>
</template>

<script>
import api from "@/api";
import { chip_secondary, COMMON_LOG_TYPES } from "@/assets/variables";
import { mapMutations } from 'vuex';
import HashtagSearch from '@/components/dumb/hashtag-search.vue';

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    components: { 
        HashtagSearch 
    },
    data: () => ({
        chip_secondary,
    }),
    computed:{
        searches() {
            return this.$store.state.searches;
        },   
    },
    methods:{
        ...mapMutations(["setSearches"]),
        async search(tag){
            try {
                await api.v1.shop.logs.saving({
                    type: COMMON_LOG_TYPES.SEARCH.value,
                    value: tag,
                }); 
                if(this.searches.length > 4) this.searches.shift();
                this.setSearches({ searches: [...this.searches, tag ]});

                this.$router.push({ path: "/search", query: { 
                    searchValue: tag,
                }});
            } catch(error){ 
                this.$handleError(error);
            }
        },
        async go(){
            try {
                await api.v1.bannerLogs.post({ _tagBanner: this.value._id });

                if(this.value?.isOuterLink){
                    window.open(this.value?.url, this.value?.target || "_self" );
                } else {
                    this.$router.push(this.value?.url);
                };
            } catch(error){
                this.$handleError(error);
            }
        }
    }
};
</script>

<style>
</style>