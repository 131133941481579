<template>
    <div class="pb-32px">
        <search-list-template v-if="!mode" v-bind="{ totalCount: summary.totalCount, mode: SEARCH_LIST.STORY.value }" />

        <v-row class="row--xxs">
            <template v-for="(board, index) in items">
                <v-col cols="6" md="12" :key="index">
                    <search-boards-item v-bind="{ value: board, path: `/story/${board.code}` }" />
                </v-col>
            </template>
        </v-row>

        <div v-if="mode" class="pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { SEARCH_LIST } from "../search-bar.vue";
import SearchListTemplate from "../search-list-template.vue";
import SearchBoardsItem from "./search-boards-item.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        SearchListTemplate,
        SearchBoardsItem,
    },
    props: {
        hasCompanyScope: { type: Boolean, default: false },
    },
    data: () => ({
        SEARCH_LIST,
        boards: [],
        summary: {
            totalCount: 0,
        },
        loading: false,
    }),
    computed: {
        ...mapGetters("story", ["getCategory"]),
        items() {
            return [...this.boards];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
        mode() {
            return this.$route.query.mode;
        },
        limit() {
            if (this.mode) return 12;
            else return 4;
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { skip, limit } = this;
                let { searchValue } = this.params;

                let params = { searchValue };

                const ownerCodes = await this.getOwnerCodes();

                params = this.hasCompanyScope ? params : { ...params, code: JSON.stringify({ $nin: ownerCodes }) };

                let { summary, boards } = await api.v1.boards.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.boards = boards;
                this.$emit("input", { type: "storiesCount", totalCount: summary.totalCount });
                this.loading = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async getOwnerCodes() {
            const { categories } = await api.v1.story.categories.gets({ params: { depth: 1, code: "owner" } });

            let ownerCodes = categories.map((category) => category.code);
            return [...ownerCodes, ...categories.flatMap((category) => category.children.map((child) => child.code))];
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        "$route.query"() {
            this.search();
        },
    },
};
</script>

<style>
</style>