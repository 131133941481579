<template></template>

<script>
import api from "@/api";
import { initStoryBoardDisplay, STORY_DISPLAY_CODES } from "@/store/story";
export default {
    methods: {
        async search() {
            try {
                const { displays } = await api.v1.boards.displays.gets({
                    headers: { sort: JSON.stringify({ index: -1, _id: -1 }) },
                    params: { code: STORY_DISPLAY_CODES.STORY_MAIN_1.value },
                });

                this.$emit("input", displays.map(initStoryBoardDisplay));
            } catch(error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style>
</style>