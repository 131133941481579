var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.mode ? _c('search-list-template', _vm._b({}, 'search-list-template', {
    totalCount: _vm.summary.totalCount,
    mode: _vm.SEARCH_LIST.MINISHOP.value
  }, false)) : _vm._e(), _c('v-row', _vm._l(_vm.stores, function (store, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "xl": "4"
      }
    }, [_c('store-profile', {
      attrs: {
        "value": store
      },
      scopedSlots: _vm._u([{
        key: "avatar",
        fn: function () {
          return [store.thumb ? _c('v-img', {
            attrs: {
              "src": store.thumb.src
            }
          }) : _c('v-img', {
            attrs: {
              "src": "/images/minishop/profile-default.png"
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1), _vm.mode ? _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }