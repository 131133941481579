var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-32px"
  }, [_c('v-divider', {
    staticClass: "mb-20px",
    attrs: {
      "dark": ""
    }
  }), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v(_vm._s(_vm.title) + " 검색 결과 "), _c('span', {
    staticClass: "txt--dark font-weight-bold"
  }, [_vm._v(_vm._s(_vm.totalCount) + "개")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold cursor-pointer",
    on: {
      "click": _vm.go
    }
  }, [_vm._v("더보기")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }