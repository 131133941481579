<template>
    <div>
        <div class="tit-wrap text-center">
            <h2 class="tit">모아고 추천업체</h2>
        </div>
        <div>
            <v-row justify="end" class="row--xs">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_txt }" to="/sub/store">더보기 <v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row class="row--sm" justify="center">
            <v-col v-for="(store, index) in stores" :key="index" cols="12" md="6" xl="4">
                <recommed-store-item :value="store"/>
            </v-col>
        </v-row>
  </div>
</template>

<script>
import api from "@/api";
import { btn_txt } from "@/assets/variables";
import RecommedStoreItem from "./recommend-store-item.vue";
export default {
    components:{
        RecommedStoreItem
    },
    data: ()=>({
        btn_txt,
        stores: null
    }),
    methods:{
        async search(){
            try{
                let { stores } = await api.v1.shop.stores.gets({
                    headers: { skip: 0, limit: 3, sort: JSON.stringify({ index: -1 })},
                });

                this.stores = stores;
            } catch(error){
                this.$handleError(error);
            }
        }
    },
    mounted(){
        this.search();
    }
}
</script>

<style>

</style>