import dayjs from "./vue-dayjs";

export class CookieStorage {
    getItem(name) {
        const matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    /**
     *
     * @param {string} name
     * @param {string} value
     * @param {object} [options]
     * @param {string} [options.path]
     * @param {number} [options.maxAge]
     * @param {string|Date|number} [options.expires]
     */
    setItem(name, value, options = {}) {
        options = {
            path: "/", // 경로 지정
            ...options, // 아규먼트로 옵션을 넘겨줬을경우 전개연산자로 추가 갱신
        };

        if (options.maxAge != undefined) {
            options["max-age"] = options.maxAge;
            delete options.maxAge;
        }

        if (!!options.expires) {
            try {
                options.expires = dayjs(options.expires).toString();
            } catch (error) {
                options.expires = options.expires;
            }
        }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                // 밸류가 없다면
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie; // 새로 갱신
    }

    removeItem(name) {
        // 해당 쿠키 요소만 삭제
        this.setItem(name, "", {
            "max-age": -1,
        });
    }

    removeList() {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            this.removeItem(name.trim());
        }
    }
}

export default CookieStorage;
