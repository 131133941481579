/**
 * 공급자 데이터
 * @param {TaxinvoiceInvoicerData} data
 * @returns {TaxinvoiceInvoicerData}
 */
export const initShopTaxinvoiceInvoicer = (data = {}) => ({
    invoicerMgtKey: data.invoicerMgtKey,
    invoicerCorpNum: data.invoicerCorpNum,
    invoicerTaxRegID: data.invoicerTaxRegID,
    invoicerCorpName: data.invoicerCorpName,
    invoicerCEOName: data.invoicerCEOName,
    invoicerAddr: data.invoicerAddr,
    invoicerBizType: data.invoicerBizType,
    invoicerBizClass: data.invoicerBizClass,
    invoicerContactName: data.invoicerContactName,
    invoicerDeptName: data.invoicerDeptName,
    invoicerTEL: data.invoicerTEL,
    invoicerHP: data.invoicerHP,
    invoicerEmail: data.invoicerEmail,
    invoicerSMSSendYN: data.invoicerSMSSendYN,
});

export default initShopTaxinvoiceInvoicer;

/**
 * @typedef {object} TaxinvoiceInvoicerData 공급자 데이터
 *
 * @property {string} [invoicerMgtKey] 19.공급자 문서번호
 * * 문서 관리를 위해 파트너가 할당하는 고유 식별번호
 * * 영문 대소문자, 숫자, 특수문자('-', '_')만 입력 가능
 * * {issueType}="정발행" 경우 필수
 *
 * @property {string} invoicerCorpNum 20.공급자 사업자번호
 * * '-' 제외
 *
 * @property {string} [invoicerTaxRegID] 21.공급자 종사업장 식별번호
 * * 종사업장 식별번호는 4자리 숫자로만 구성
 *
 * @property {string} invoicerCorpName 22.공급자 상호
 *
 * @property {string} invoicerCEOName 23.공급자 대표자 성명
 *
 * @property {string} [invoicerAddr] 24.공급자 주소
 *
 * @property {string} [invoicerBizType] 25.공급자 업태
 *
 * @property {string} [invoicerBizClass] 26.공급자 종목
 *
 * @property {string} [invoicerContactName] 27.공급자 담당자 성명
 *
 * @property {string} [invoicerDeptName] 28.공급자 담당자 부서명
 *
 * @property {string} [invoicerTEL] 29.공급자 담당자 연락처
 *
 * @property {string} [invoicerHP] 30.공급자 담당자 휴대폰
 *
 * @property {string} [invoicerEmail] 31.공급자 담당자 이메일
 *
 * @property {boolean} [invoicerSMSSendYN] 32.공급자 알림문자 전송 여부
 * * true: 전송
 * * false: 미전송(기본값)
 * * 수신자: 공급받는자 휴대폰번호 {invoiceeHP1}
 * * 문자 전송 포인트가 차감(단, 전송실패 건은 환불)
 * * {issueType}="정발행" 경우 세금계산서 발행시점에 알림문자 전송
 */
