<template>
    <div>
        <v-badge :value="isShow" :dot="isMobile" color="accent2" :content="notiCount">
            <button class="header-icon-menu__link header-icon-menu__link--notification" @click="shows" />
        </v-badge>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { initNotiOptions } from "./main-notification-options.vue";
import detect from "@/plugins/detect";

export default {
    components: {},
    data: () => ({
        detect,
        drawer: false,
    }),
    computed: {
        ...mapState(["accessToken"]),
        isMobile() {
            return this.detect == "mobile";
        },
        notiCount() {
            return this.$store.state?.notiCount;
        },
        notiOptions() {
            return initNotiOptions(this.$store.state?.user?.noti);
        },
        isShow(){
            if(!this.accessToken) return false;

            return this.notiOptions.isShow && this.notiCount > 0;
        }
    },
    methods: {
        ...mapMutations(["setNotiShows"]),
        shows() {
            if(!this.accessToken) {
                alert("로그인이 필요한 서비스입니다.");
                this.$router.push('/login');
                return;
            }

            this.setNotiShows(!this.$store.state?.noti?.shows);
        }
    }
};
</script>

<style lang="scss" scoped>
</style>