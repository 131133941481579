<template>
    <swiper v-if="banner" :options="swiperOptions" class="main-header-slide my-30px">
        <swiper-slide v-for="(slide, index) in banner.slides" :key="index">
            <v-card tile color="transparent">
                <v-responsive :aspect-ratio="294 / 160">
                    <div class="swiper-slide__bg" :style="'background-image: url('+slide.image+')'" />
                    <div v-html="slide?.meta?.title" style="white-space: pre-line" class="swiper-slide__txt"/>
                </v-responsive>
            </v-card>
        </swiper-slide>
    </swiper>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BANNERS } from "@/assets/variables";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 12,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                loopedSlides: 1,
            },
            banner: null
        };
    },
    computed: {
        isPc() {
            return detect === "pc";
        },
        code() {
            return BANNERS.MAIN_2.code__mb;
        }
    },
    methods:{
        async search(){
            try{
                let { banner } = await api.v1.banners.get({ code: this.code });
                this.banner = banner;

            } catch(error){
                this.$handleError(error);
            }
        }
    },
    mounted() {
        if(!this.isPc) this.search();
    }
};
</script>

<style lang="scss" scoped>
.main-header-slide {
    max-width: calc(100% - 48px);
    overflow: visible;
    .swiper-slide {
        .v-card {
            position: relative;
        }
        &__bg {
            background-position: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        &__txt {
            height: 100%;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            padding: 32px 26px;
        }
    }
}
</style>
