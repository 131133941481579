var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "px-18px px-lg-24px py-20px py-lg-40px"
  }, [_c('div', {
    staticClass: "mb-40px mb-lg-50px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper', {
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.btnItems, function (category, index) {
    return [_c('swiper-slide', {
      key: index
    }, [_c('v-btn-toggle', {
      staticClass: "notification-btn-toggle",
      model: {
        value: _vm.filter,
        callback: function ($$v) {
          _vm.filter = $$v;
        },
        expression: "filter"
      }
    }, [_c('v-btn', {
      staticClass: "rounded-pill",
      attrs: {
        "value": category.value,
        "block": "",
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(category.text) + " ")])], 1)], 1)];
  })], 2)], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xs"
  }, _vm._l(_vm.alarms, function (alarm, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('main-notification-card', _vm._b({
      on: {
        "update": _vm.search
      }
    }, 'main-notification-card', {
      value: alarm
    }, false))], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }