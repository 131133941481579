<template>
    <div class="mobile-searchbar d-lg-none">
        <v-row no-gutters>
            <v-col cols="auto">
                <button class="mobile-searchbar__close" type="button">
                    <span class="d-sr-only">모바일 검색창 닫기</span>
                </button>
            </v-col>
            <v-col>
                <search-bar class="pr-12px" />
            </v-col>
        </v-row>

        <div class="px-18px">
            <v-row>
                <v-col cols="12">
                    <search-recent />
                </v-col>
                <v-col cols="12">
                    <div class="tit-wrap tit-wrap--sm">
                        <div class="tit">실시간 검색어</div>
                    </div>
                    <search-rank-list />
                </v-col>
            </v-row>

            <!-- <search-product-list v-if="!mode || mode === SEARCH_LIST.PRODUCT.value" @input="total" v-show="!shows" /> -->
            <!-- <search-story-list v-if="!mode || mode === SEARCH_LIST.STORY.value" @input="total" v-show="!shows" /> -->
            <!-- <search-user-list v-if="!mode || mode === SEARCH_LIST.USER.value" @input="total" v-show="!shows" /> -->
            <!-- <search-minishop-list v-if="!mode || mode === SEARCH_LIST.MINISHOP.value" @input="total" v-show="!shows" /> -->
            <!-- <search-none-text v-if="shows" /> -->
        </div>
    </div>
</template>

<script>
import { attrs_input_secondary } from "@/assets/variables";
import SearchBar from "@/components/client/search/search-bar.vue";
import SearchProductList from "@/components/client/search/product/search-product-list.vue";
import SearchStoryList from "@/components/client/search/story/search-story-list.vue";
import SearchUserList from "@/components/client/search/nickname/search-user-list.vue";
import SearchMinishopList from "@/components/client/search/minishop/search-minishop-list.vue";
import SearchNoneText from "@/components/client/search/search-none-text.vue";
import { SEARCH_LIST } from "@/components/client/search/search-bar.vue";

import SearchRecent from "@/components/client//search/search-recent.vue";
import SearchRankList from "@/components/client/search/search-rank-list.vue";

import ContentsCardRank from "@/components/client/main/contents/contents-card-rank.vue";

export default {
    components: {
        SearchBar,
        SearchProductList,
        SearchStoryList,
        SearchUserList,
        SearchMinishopList,
        SearchNoneText,

        SearchRecent,
        SearchRankList,

        ContentsCardRank,
    },
    data: () => ({
        SEARCH_LIST,
        productsCount: 0,
        storiesCount: 0,
        usersCount: 0,
        minishopsCount: 0,

        attrs_input_secondary,
    }),
    computed: {
        searchValue() {
            return this.$route.query.searchValue;
        },
        mode() {
            return this.$route.query.mode;
        },
        shows() {
            if (!this.mode) {
                if (
                    // 전체 검색 시, 검색 결과가 0개일 때
                    this.productsCount === 0 &&
                    this.storiesCount === 0 &&
                    this.usersCount === 0 &&
                    this.minishopsCount === 0
                )
                    return true;
            } else {
                // 상세 검색 시, 해당 검색 결과가 0개일 때
                switch (this.mode) {
                    case SEARCH_LIST.PRODUCT.value:
                        if (this.productsCount === 0) return true;
                        break;
                    case SEARCH_LIST.STORY.value:
                        if (this.storiesCount === 0) return true;
                        break;
                    case SEARCH_LIST.USER.value:
                        if (this.usersCount === 0) return true;
                        break;
                    case SEARCH_LIST.MINISHOP.value:
                        if (this.minishopsCount === 0) return true;
                        break;
                    default:
                        return false;
                }
            }
        },
    },
    methods: {
        total({ type, totalCount }) {
            this[type] = totalCount;
        },
    },
};
</script>