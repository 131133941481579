<template></template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import { BANNERS } from "@/assets/variables";

export default {
    data: () => ({
        banner: null,
    }),
    computed: {
        isPc() {
            return detect === "pc";
        },
        code() {
            return this.isPc ? BANNERS.MAIN_2.code__pc : BANNERS.MAIN_2.code__mb;
        },
    },
    methods: {
        async search() {
            try {
                let { banner } = await api.v1.banners.get({ code: this.code });
                this.banner = banner;
                this.$emit("input", this.banner);
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style>
</style>