// 기존 attrs
export const attrs_input = {
    // dense: true,
    outlined: true,
    hideDetails: "auto",
    // persistentPlaceholder: true,
};

export const attrs_input__console = {
    dense: true,
    outlined: true,
    hideDetails: "auto",
    persistentPlaceholder: true,
};

export const attrs_input__secondary = {
    dense: false,
    outlined: true,
    hideDetails: "auto",
    persistentPlaceholder: true,
};

export const attrs_input__date__console = {
    ...attrs_input__console,
    type: "date",
    max: "2999-12-31",
};

export const attrs_input__verticalTable = {
    flat: true,
    solo: true,
    class: "rounded-0",
    hideDetails: true,
    "persistent-placeholder": true,
};

export const attrs_switch = {
    inset: true,
    hideDetails: "auto",
    // class: "pa-0 ma-0 ml-1 mr-n4",

    // Moago 수정
    color: "secondary",
};

export const attrs_switch__console = {
    inset: true,
    hideDetails: "auto",
    class: "pa-0 ma-0 d-inline-block mr-n2",
};

// Client
// Button
export const btn_primary = {
    // color: "grey darken-4",
    ripple: false,

    // Moago 수정
    color: "primary",
    tile: true,
};
export const btn_primary2 = {
    color: "primary",
    ripple: false,
    tile: true,
};
export const btn_primary3 = {
    color: "grey",
    ripple: false,
    tile: true,
};
export const btn_secondary = {
    // color: "grey darken-4",
    outlined: true,
    ripple: false,

    // Moago 수정
    color: "primary",
    tile: true,
};
export const btn_secondary2 = {
    color: "black",
    tile: true,
    outlined: true,
    ripple: false,
};
export const btn_tertiary = {
    // color: "grey",
    outlined: true,
    ripple: false,

    // Moago 수정
    color: "primary",
};

// Checkbox
export const checkbox_primary = {
    // color: "grey darken-4",
    "hide-details": "auto",

    // Moago 수정
    color: "secondary",
};
export const checkbox_secondary = {
    color: "primary",
    "hide-details": "auto",
};

// Radio
export const radio_primary = {
    // color: "grey darken-4",

    // Moago 수정
    color: "secondary",
};
export const radio_secondary = {
    color: "primary",
};

/////// Moago 신규 작성 ///////

// input
export const attrs_input_secondary = {
    hideDetails: "auto",
};

// btn
export const btn_tertiary2 = {
    outlined: true,
    ripple: false,
    class: "v-btn--width-fixed",
};
export const btn_quaternary = {
    color: "secondary",
    ripple: false,
};
export const btn_quaternary2 = {
    color: "secondary",
    ripple: false,
    class: "v-btn--width-fixed",
};
export const btn_icon = {
    icon: true,
    text: true,
};
export const btn_txt = {
    text: true,
    color: "primary",
    class: "v-btn--text-fit",
};

// chip
export const chip_primary = {
    outlined: true,
    color: "grey lighten-2",
    textColor: "primary",
};
export const chip_primary2 = {
    outlined: true,
    color: "primary",
};
export const chip_secondary = {
    color: "grey lighten-5",
    textColor: "primary",
};
export const chip_secondary2 = {
    color: "secondary",
    textColor: "white",
};
export const chip_secondary3 = {
    color: "primary",
    textColor: "white",
};

// rating
export const attrs_rating = {
    backgroundColor: "grey",
    color: "primary",
    hover: true,
};
