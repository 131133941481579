var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-32px"
  }, [!_vm.mode ? _c('search-list-template', _vm._b({}, 'search-list-template', {
    totalCount: _vm.summary.totalCount,
    mode: _vm.SEARCH_LIST.USER.value
  }, false)) : _vm._e(), _c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.users, function (user, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "12"
      }
    }, [_c('search-user-follow', _vm._b({}, 'search-user-follow', {
      item: user
    }, false))], 1);
  }), 1), _vm.mode ? _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }