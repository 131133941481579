import axios from "@/plugins/axios";

import alarms from "./alarms";
import carts from "./carts";
import coupons from "./coupons";
import destinations from "./destinations";
import follows from "./follows";
import inquires from "./inquires";
import inquiries from "./inquiries";
import likes from "./likes";
import orders from "./orders";
import points from "./points";
import payments from "./payments";
import purchases from "./purchases";
import reviews from "./reviews";
import visits from "./visits";
import watches from "./watches";

let url = "/api/v1/me";

export default {
    get() {
        return axios.get(`${url}/info`).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/info`, data).then((result) => result.data);
    },
    verify(data) {
        return axios.post(`${url}/verify`, data).then((result) => result.data);
    },
    withdraw(data) {
        return axios.post(`${url}/withdraw`, data).then((result) => result.data);
    },

    alarms,
    carts,
    coupons,
    destinations,
    follows,
    inquires,
    inquiries,
    likes,
    orders,
    points,
    payments,
    purchases,
    reviews,
    visits,
    watches,
};
