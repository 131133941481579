<template>
    <!-- Mobile Gnb -->
    <v-list v-if="mobile" class="mobile-sidebar__list">
        <template v-for="(item, index) in items">
            <template v-if="!item.hidesMenu">
                <v-list-group v-if="item.children" :key="index">
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title class="tit tit--xl font-weight-semibold">
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-for="(child, index) in item.children">
                        <v-list-group v-if="child.children" :key="index" sub-group>
                            <template #activator>
                                <v-list-item-title>
                                    {{ child.text }}
                                </v-list-item-title>
                            </template>
                            <v-list-item :to="child.value">
                                <v-list-item-title>전체보기</v-list-item-title>
                            </v-list-item>
                            <template v-for="(grandChild, index) in child.children">
                                <v-list-item :key="index" :to="grandChild.value">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ grandChild.text }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list-group>

                        <v-list-item v-else :key="child.text" :to="child.value">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>

                <template v-else>
                    <v-list-item :key="item.text" @click="go(item)">
                        <v-list-item-title class="tit tit--xl font-weight-semibold">
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item v-if="item.hasAccessToken" :key="item.text">
                        <v-list-item-title class="tit tit--xl font-weight-semibold">
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else :key="item.text" :to="item.value">
                        <v-list-item-title class="tit tit--xl font-weight-semibold">
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item> -->
                </template>
            </template>
        </template>
    </v-list>

    <!-- PC Gnb -->
    <div v-else class="header-menu">
        <ul class="header-menu__lists">
            <template v-for="(item, index) in items">
                <li v-if="!item.hidesMenuOnPC && !item.hidesMenu" class="headder-menu__list" :key="index">
                    <div class="header-category">
                        <router-link :to="item.value" class="header-menu__list-link">
                            {{ item.text }}
                        </router-link>

                        <template v-if="item.showsChildrenOnPC">
                            <div class="header-category__full">
                                <main-shop-categories pc />
                            </div>
                        </template>
                    </div>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MainShopCategories from "@/components/client/main/main-shop-categories.vue";

export default {
    components: {
        MainShopCategories,
    },
    props: {
        mobile: { type: Boolean, default: false },
    },
    computed: {
        ...mapGetters("ui", { items: "gnbs" }),
        ...mapState(["accessToken"]),
    },
    methods: {
        go(item) {
            if (item.hasAccessToken && !this.accessToken) {
                alert("로그인이 필요한 서비스입니다.");
                this.$router.push("/login");
            } else {
                return this.$router.push(item.value);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-sidebar__list {
    .v-list-item {
        padding: 0 var(--container-gutter);
    }
    ::v-deep() {
        .v-list-group__header {
            padding: 0 var(--container-gutter);
        }
    }
    > .v-list-item {
        min-height: 72px;
    }
    > .v-list-group {
        ::v-deep() {
            > .v-list-group__header {
                min-height: 72px;
            }
            > .v-list-group__items {
                > .v-list-item {
                    padding-left: calc(var(--container-gutter) * 2);
                }
                > .v-list-group--sub-group {
                    > .v-list-group__items {
                        > .v-list-item {
                            padding-left: calc(var(--container-gutter) * 2);
                        }
                    }
                    > .v-list-group__header {
                        padding-left: calc(var(--container-gutter) * 2);
                        > .v-list-item__title {
                            font-weight: 700;
                            font-size: var(--tit-font-size-xs);
                        }
                        > .v-list-item__icon {
                            order: 2;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>