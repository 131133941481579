import { SHIPPING_TARGET_TYPES } from "../constants";

export function initShopShipping(shipping = {}) {
    const { initShopShippingOption } = require("./shop-shipping-options.inits");
    return {
        ...shipping,

        _seller: shipping._seller ?? null,
        seller: shipping.seller ?? null,

        _options: [...(shipping._options || [])],
        options: [...(shipping.options || [{}])].map(initShopShippingOption),

        name: shipping.name ?? null,

        target: {
            ...(shipping.target || {}),
            type: shipping.target?.type ?? SHIPPING_TARGET_TYPES.EVERY_ASSET.value,
            _brands: [...(shipping.target?._brands || [])],
            _products: [...(shipping.target?._products || [])],
            _categories: [...(shipping.target?._categories || [])],
            _categories__overall: [...(shipping.target?._categories || [])],
        },

        endsAt: shipping.endsAt ?? null,
        startsAt: shipping.startsAt ?? null,

        isActive: shipping.isActive ?? false,
        isDefault: shipping.isDefault ?? false,
    };
}
