<template>
    <div>
        <v-row v-for="(log, index) in logs" :key="index" class="row--xxs">
            <v-col cols="auto">
                <v-card width="24" class="text-center">
                    <span class="tit tit--xxs font-weight-medium">{{ index + 1 }}</span>
                </v-card>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
                <v-card width="24" class="text-center">
                    <div v-if="!log.diff && log.diff !== 0" class="accent2--text font-size-10">NEW</div>
                    <v-icon v-if="log.diff > 0" class="accent2--text">arrow_drop_up</v-icon>
                    <v-icon v-if="log.diff < 0" class="secondary--text text--lighten-2">arrow_drop_down</v-icon>
                    <v-icon v-if="log.diff === 0" size="12">remove</v-icon>
                </v-card>
            </v-col>
            <v-col>
                <v-sheet class="rank-title-id">
                    <div class="txt cursor-pointer white text-truncate" @click="go(log._id)">{{ log._id }}</div>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import { COMMON_LOG_TYPES } from "@/assets/variables";

export default {
    data: () => ({
        limit: 10,
        logs: null,
    }),
    computed: {
        searches() {
            return this.$store.state.searches;
        },
    },
    methods: {
        ...mapMutations(["setSearches"]),
        async search() {
            try {
                let now = this.$dayjs();
                let aDayBefore = now.subtract(1, "day");
                let twoDaysBefore = now.subtract(2, "day");

                // 어제 ~ 오늘
                let { logs: todayLogs } = await api.v1.shop.logs.gets({
                    headers: { limit: this.limit },
                    params: {
                        type: COMMON_LOG_TYPES.SEARCH.value,
                        createdAt: [aDayBefore.toDate(), now.toDate()],
                    },
                });
                // 그저께 ~ 어제
                let { logs: yesterdayLogs } = await api.v1.shop.logs.gets({
                    headers: { limit: this.limit },
                    params: {
                        type: COMMON_LOG_TYPES.SEARCH.value,
                        createdAt: [twoDaysBefore.toDate(), aDayBefore.toDate()],
                    },
                });
                //비교 후 diff에 저장
                let logs = todayLogs.map((todayLog) => {
                    let yesterdayLog = yesterdayLogs.find((yesterdayLog) => todayLog._id === yesterdayLog._id);
                    if (yesterdayLog) {
                        return {
                            ...todayLog,
                            diff: todayLog.count - yesterdayLog.count,
                        };
                    } else {
                        return todayLog;
                    }
                });

                this.logs = logs;

                this.$emit("input");
            } catch (error) {
                this.$handleError(error);
            }
        },
        async go(tag) {
            try {
                await api.v1.shop.logs.saving({
                    type: COMMON_LOG_TYPES.SEARCH.value,
                    value: tag,
                });
                if (this.searches.length > 4) this.searches.shift();
                this.setSearches({ searches: [...this.searches, tag] });

                const header = document.querySelector(".header");
                header.setAttribute("data-searchbar", "closed");
                header.setAttribute("data-dimmed", "closed");

                this.$router.push({
                    path: "/search",
                    query: {
                        searchValue: tag,
                    },
                });
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    watch: {
        logs() {
            this.$emit("input", this.logs);
        },
    },
    mounted() {
        this.search();
    },
};
</script>