import axios from "@/plugins/axios";

let url = (data) => "/api/console/shop/orders/${_order}/moneys".interpolate(data);

export const moneys = {
    postIssue(data) {
        return axios.post(`${url(data)}/issue`, data).then((result) => result.data);
    },
};

export default moneys;
