var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main",
    scopedSlots: _vm._u([{
      key: "bandBanner",
      fn: function () {
        return [_c('main-band-banners')];
      },
      proxy: true
    }])
  }, [_c('main-visual'), _c('page-section', {
    staticClass: "main-section",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('main-contents')], 1), _c('page-section-wide', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "category-banner",
    attrs: {
      "href": "/shop/products?category=elctric-bicycle",
      "tile": "",
      "img": "/images/main/banner-bicycle.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 480 / 750
    }
  }, [_c('div', {
    staticClass: "category-banner__caption"
  }, [_c('div', [_c('h3', {
    staticClass: "tit font-weight-light mb-8px"
  }, [_vm._v("전기자전거")]), _c('p', {
    staticClass: "txt txt--light line-height-17 d-none d-xl-block"
  }, [_vm._v("전기 모터를 이용해 힘들이지 않고 주행할 수 있습니다. 핸들 스로틀로 속도를 올리는 방식과 모터가 페달을 쉽게 돌리도록 도와주는 PAS방식이 있습니다.")])]), _c('v-row', {
    staticClass: "row--xxs d-none d-xl-flex",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "gradient-line"
  })]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("go")])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "category-banner",
    attrs: {
      "to": "/shop/products?category=elctric-scooter",
      "tile": "",
      "img": "/images/main/banner-scooter.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 480 / 750
    }
  }, [_c('div', {
    staticClass: "category-banner__caption"
  }, [_c('div', [_c('h3', {
    staticClass: "tit font-weight-light mb-8px"
  }, [_vm._v("전동스쿠터")]), _c('p', {
    staticClass: "txt txt--light line-height-17 d-none d-xl-block"
  }, [_vm._v("두 개 혹은 세 개의 바퀴를 가지고 전기로 작동하는 스쿠터입니다.")])]), _c('v-row', {
    staticClass: "row--xxs d-none d-xl-flex",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "gradient-line"
  })]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("go")])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "category-banner",
    attrs: {
      "href": "/shop/products?category=electric-kickboard",
      "tile": "",
      "img": "/images/main/banner-kickboard.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 480 / 750
    }
  }, [_c('div', {
    staticClass: "category-banner__caption"
  }, [_c('div', [_c('h3', {
    staticClass: "tit font-weight-light mb-8px"
  }, [_vm._v("전동킥보드")]), _c('p', {
    staticClass: "txt txt--light line-height-17 d-none d-xl-block"
  }, [_vm._v("개인형 이동장치의 하나로, 우리가 흔히 말하는 킥보드를 기본 형태로 취하고 전동관련 장치를 달아 전기력으로 달릴 수 있는 1-2인용 운송수단인 퍼스널 모빌리티 입니다.")])]), _c('v-row', {
    staticClass: "row--xxs d-none d-xl-flex",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "gradient-line"
  })]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("go")])], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "category-banner",
    attrs: {
      "href": "/shop/products?category=accessory",
      "tile": "",
      "img": "/images/main/banner-accessories.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 480 / 750
    }
  }, [_c('div', {
    staticClass: "category-banner__caption"
  }, [_c('div', [_c('h3', {
    staticClass: "tit font-weight-light mb-8px"
  }, [_vm._v("악세서리")]), _c('p', {
    staticClass: "txt txt--light line-height-17 d-none d-xl-block"
  }, [_vm._v("이륜 모빌리티를 사용하는데 필요한 악세서리 및 주요 부품을 판매합니다.")])]), _c('v-row', {
    staticClass: "row--xxs d-none d-xl-flex",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "gradient-line"
  })]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("go")])], 1)], 1)])], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "main-section",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('main-recommend-stores')], 1), _c('page-section', {
    staticClass: "main-section",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('main-map')], 1), _vm._l(_vm.popups, function (popup) {
    return _c('popup-layer', {
      key: popup._id,
      attrs: {
        "popup": popup
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }