/**
 * 정보 데이터
 * @param {TaxinvoiceInformationData} data
 * @returns {TaxinvoiceInformationData}
 */
export const initShopTaxinvoiceInformation = (data = {}) => {
    const taxTotal = data.taxTotal ?? Math.ceil(data.totalAmount / 10);
    const supplyCostTotal = data.supplyCostTotal ?? data.totalAmount - taxTotal;

    return {
        serialNum: data.serialNum,
        kwon: data.kwon,
        ho: data.ho,
        supplyCostTotal,
        taxTotal,
        totalAmount: data.totalAmount,
        cash: data.cash,
        chkBill: data.chkBill,
        credit: data.credit,
        note: data.note,
        remark1: data.remark1,
        remark2: data.remark2,
        remark3: data.remark3,
        businessLicenseYN: data.businessLicenseYN,
        bankBookYN: data.bankBookYN,
    };
};

export default initShopTaxinvoiceInformation;

/**
 * @typedef {object} TaxinvoiceInformationData 정보 데이터
 *
 * @property {string} [serialNum] 4.일련번호
 *
 * @property {number} [kwon] 5.권
 * * 정수만 입력 가능
 *
 * @property {number} [ho] 6.호
 * * 정수만 입력 가능
 *
 * @property {string} supplyCostTotal 9.공급가액 합계
 * * 정수만 입력 가능(소수점 자동 절사)
 *
 * @property {string} taxTotal 10.세액 합계
 * * 정수만 입력 가능(소수점 자동 절사)
 *
 * @property {string} totalAmount 11.합계금액
 * * 정수만 입력 가능(소수점 자동 절사)
 *
 * @property {string} [cash] 12.현금
 *
 * @property {string} [chkBill] 13.수표
 *
 * @property {string} [credit] 14.외상
 *
 * @property {string} [note] 15.어음
 *
 * @property {string} [remark1] 16.비고1
 * * {invoiceeType}="외국인" 경우 외국인 등록번호 또는 여권번호 필수
 *
 * @property {string} [remark2] 17.비고2
 *
 * @property {string} [remark3] 18.비고3
 *
 * @property {boolean} [businessLicenseYN] 64.팝빌에 등록된 사업자등록증 첨부 여부
 * * true: 첨부
 * * false: 미첨부 (기본값)
 * * 팝빌 사이트 또는 [GetSealURL - 인감 및 첨부문서 등록 팝업 URL]을 이용하여 등록
 *
 * @property {boolean} [bankBookYN] 65.팝빌에 등록된 통장사본 첨부 여부
 * * true: 첨부
 * * false: 미첨부 (기본값)
 * * 팝빌 사이트 또는 [GetSealURL - 인감 및 첨부문서 등록 팝업 URL]을 이용하여 등록
 */
