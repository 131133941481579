import api from "@/api";

export const STORY_ITEM_TYPES = {
    SLIDE: { value: "SLIDE", text: "아티클" },
    THUMB: { value: "THUMB", text: "썸네일" },
};

export const STORY_MAIN_TYPES = {
    DEFAULT: { value: "DEFAULT", text: "기본" },
    GALLERY: { value: "GALLERY", text: "앨범" },
    ARTICLE: { value: "ARTICLE", text: "기사" },
    SUMMARY: { value: "SUMMARY", text: "요약" },
};

export const STORY_DISPLAY_CODES = {
    STORY_MAIN_1: {
        value: "STORY_MAIN_1",
        title: "섹션 3 - 스토리 카드",
    },
};

// export const STORY_TAG_REGEX = /<([^>]+)>{{_showcase:([\w\d\.]+)}}<\/[^>]+>/g;
export const STORY_TAG_REGEX = /<img([^>]+){{_showcase:([\w\d\.]+)}}[^>]+>/g;

/** @type {import("vuex").Module} */
export const story = {
    namespaced: true,
    state: {
        areCategoriesLoaded: false,
        categories: [],
    },
    mutations: {
        setCategories(state, { categories }) {
            state.categories = categories;
            state.areCategoriesLoaded = true;
        },
    },
    actions: {
        async getCategories({ commit }) {
            const { categories } = await api.v1.story.categories.gets({ params: { depth: 1 } });
            commit("setCategories", { categories });
        },
    },
    getters: {
        itemTypes() {
            return Object.values(STORY_ITEM_TYPES);
        },
        mainTypes() {
            return Object.values(STORY_MAIN_TYPES);
        },
        categories__flatMapped(state) {
            return state.categories.flatMap(function flatMap(category) {
                let categories = [category];
                if (0 < category.children.length) {
                    categories.push(...category.children.flatMap(flatMap));
                }
                return categories;
            });
        },
        getCategory(state, getters) {
            return (code) => (getters.categories__flatMapped || []).find((item) => item.code == code);
        },
        getCategoryWithId(state, getters) {
            return (_id) => (getters.categories__flatMapped || []).find((item) => item._id == _id);
        },
    },
};

export default story;

export function initStoryCategory(category = {}) {
    return {
        ...category,

        _id: category?._id,

        _parent: category?._parent,
        _parents: [...(category?._parents ?? [])],

        code: category?.code ?? null,
        name: category?.name ?? null,

        depth: category?.depth ?? 1,
        index: category?.index ?? 0,

        itemType: category?.itemType ?? STORY_ITEM_TYPES.THUMB.value,
        mainType: category?.mainType ?? STORY_MAIN_TYPES.DEFAULT.value,
        showcase: category?.showcase ?? false,

        scope__form: [...(category?.scope__form ?? [])],
        scope__main: [...(category?.scope__main ?? [])],
        scope__view: [...(category?.scope__view ?? [])],
    };
}

export function initStoryBoard(board = {}) {
    return {
        ...board,

        _id: board?._id,

        comments: [...(board?.comments ?? [])],
        _comments: [...(board?._comments ?? [])],

        showcases: [...(board?.showcases ?? [])].map(initCommonShowcase),
        _showcases: [...(board?._showcases ?? [])],

        code: board?.code ?? null,
        category: board?.category ?? null,

        subject: board?.subject ?? null,
        content: board?.content ?? null,
        contentText: initStoryBoardContentText(board),

        viewCount: board?.viewCount ?? 0,
        likeCount: board?.likeCount ?? 0,

        writer: {
            ...(board?.writer ?? {}),

            _id: board?.writer?._id ?? null,

            name: board?.writer?.name ?? null,
            nickname: board?.writer?.nickname ?? null,
        },

        meta: {
            ...(board?.meta ?? {}),
            tags: [...(board?.meta?.tags || [])],
            mini: {
                ...(board?.meta?.mini || {}),

                code: board?.meta?.mini?.code || null,
            },
        },
    };
}

export function initStoryBoardContentText(board = {}) {
    /** @type {string} */
    let content = board?.content || board?.contentText || "";
    content = content.replace(STORY_TAG_REGEX, " ");
    content = content.replace(/<[^>]*>/g, " ");
    content = content.replace(/&nbsp;/g, " ");
    content = content.replace(/\s+/g, " ");
    return content.trim();
}

export function initCommonShowcase(showcase = {}) {
    return {
        ...showcase,

        image: showcase?.image ?? null,
        _image: showcase?._image ?? null,

        tags: [...(showcase?.tags || [])].map(initCommonShowcaseTag),

        tempId: showcase?.tempId ?? Math.random(),
    };
}

export function initCommonShowcaseTag(tag = {}) {
    return {
        ...tag,

        _product: tag?._product || null,
        product: tag?.product || null,

        name: tag?.name || null,
        desc: tag?.desc || null,
        href: tag?.href || null,

        x: tag?.x || 0,
        y: tag?.y || 0,

        tempId: tag?.tempId ?? Math.random(),
    };
}

export function initStoryBoardDisplay(display = {}) {
    let thumb = display?.thumb || display?.image || display?.board?.showcases?.[0]?.image || null;
    let thumbSrc = thumb instanceof File ? URL.createObjectURL(thumb) : thumb?.src || null;

    let computedContent = display?.content || initStoryBoardContentText(display?.board) || null;

    return {
        ...display,

        _board: display?._board || null,
        board: display?.board || null,

        _image: display?._image || null,
        image: display?.image || null,

        _showcase: display?._showcase || null,
        showcase: display?.showcase || null,

        _products: [...(display?._products || display?.showcase?._products || [])],
        products: [...(display?.products || display?.showcase?.products || [])],

        code: display?.code || null,

        index: display?.index || null,
        shows: display?.shows || false,

        subject: display?.subject || display?.board?.subject || null,
        content: computedContent,

        startsAt: display?.startsAt || null,
        endsAt: display?.endsAt || null,

        thumb,
        thumbSrc,
        computedContent,
    };
}
