export const SHIPPING_CODES = {
    GENERAL: { value: "GENERAL", text: "택배배송" },
    EXPRESS: { value: "EXPRESS", text: "퀵서비스" },
    PICK_UP: { value: "PICK_UP", text: "방문수령" },
    COMPANY: { value: "COMPANY", text: "자체배송" },
};

export const SHIPPING_TARGET_TYPES = {
    EVERY_ASSET: { value: "EVERY_ASSET", text: "전체" },
    BY_PRODUCTS: { value: "BY_PRODUCTS", text: "상품별" },
    BY_CATBRAND: { value: "BY_CATBRAND", text: "카테고리별 + 브랜드별" },
    BY_CATEGORY: { value: "BY_CATEGORY", text: "카테고리별" },
    BY_BRANDING: { value: "BY_BRANDING", text: "브랜드별" },
};

export const SHIPPING_OPTION_BUNDLE_TYPES = {
    APIECE: { value: "APIECE", text: "개별배송" },
    BUNDLE: { value: "BUNDLE", text: "묶음배송" },
};

export const SHIPPING_OPTION_CHARGE_TYPES = {
    BY_FIXED_AMOUNT: { value: "BY_FIXED_AMOUNT", text: "고정" },
    PAY_ON_DELIVERY: { value: "PAY_ON_DELIVERY", text: "착불" },
    BY_PRICES_RANGE: { value: "BY_PRICES_RANGE", text: "구간별" },
    BY_TRAVEL_RANGE: { value: "BY_TRAVEL_RANGE", text: "거리별" },
};
