<template>
    <v-col cols="12" lg="6" order-lg="2">
        <div id="map_wrap">
            <div id="map">
                <actions-current />
                <actions-zoom />
                <kakao-map v-bind="{ ...kakaoProps, onZoomChanged, onBoundsChanged, onCenterChanged }" ref="kakao-map">
                    <template slot-scope="{ on, attrs }">
                        <!-- <kakao-map-cluster-overlay v-for="item in overlays" :key="item._id" v-bind="{ ...item, ...attrs }" v-on="on" /> -->
                        <kakao-map-overlay v-for="item in overlays" :key="item._id" v-bind="{ item, ...attrs }" v-on="on" />
                    </template>
                </kakao-map>
            </div>
        </div>
    </v-col>
</template>

<script>
import { mapMutations } from 'vuex';
import ActionsCurrent from '@/components/plugins/kakao/actions-current.vue';
import ActionsZoom from '@/components/plugins/kakao/actions-zoom.vue';
import KakaoMap from '@/components/plugins/kakao/kakap-map.vue'
import KakaoMapOverlay from '@/components/plugins/kakao/kakao-map-overlay.vue'
export default {
    components: {
        KakaoMap,
        KakaoMapOverlay,
        ActionsCurrent,
        ActionsZoom
    },
    props: {
        lat: { type: Number, default: 37.4835924256371 },
        lng: { type: Number, default: 127.032693842117 },
        lvl: { type: Number, default: 3 },
        maxLevel: { type: Number, default: 8 },
        locations: { type: Array, default: () => [] },
    },
    data: () => ({
        initialSearchCommitted: false,
    }),
    computed: {
        kakaoProps() {
            const { lat, lng, lvl: level, maxLevel } = this;

            return { width: "100%", height: "400px", level, centerPosition: { lat, lng }, usesCustomMarker: true, maxLevel };
        },
        overlays() {
            return this.locations.map((item) => ({
                ...item,
                lat: item.geolocation.coordinates[1],
                lng: item.geolocation.coordinates[0],
            }));
        },
        query() {
            return this.$route.query;
        },
    },
    methods: {
        ...mapMutations("location", ["setLvl", "setBounds", "setCoordinates"]),
        onZoomChanged() {
            const { map } = this.$refs["kakao-map"]?.$data || {};
            const lvl = map?.getLevel?.();
            this.setLvl({ lvl });
        },
        onBoundsChanged() {
            const { map } = this.$refs["kakao-map"]?.$data || {};
            const { ha: w, qa: s, oa: e, pa: n } = map?.getBounds?.();
            this.setBounds({ w, s, e, n });
            // Initial Search
            if (this.initialSearchCommitted) return;
            this.initialSearchCommitted = true;
            let { lat, lng, lvl } = this.$route.query;
            this.$nextTick(() => this.$emit("search", !!lat && !!lng && !!lvl ? { withoutRouting: true } : {}));
        },
        onCenterChanged() {
            const { map } = this.$refs["kakao-map"]?.$data || {};
            const center = map?.getCenter?.();
            this.setCoordinates({ lat: center.getLat(), lng: center.getLng() });
        },
    },
    watch: {
        query({ lat, lng } = {}) {
            if (!!lat && !!lng && (lat !== this.lat || lng !== this.lng)) {
                this.setCoordinates({ lat, lng });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#map {
    position: relative;
}
</style>