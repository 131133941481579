var render = function render(){
  var _vm$value, _vm$value2, _vm$value2$meta, _vm$value3, _vm$value3$meta, _vm$value4, _vm$value4$meta;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "img": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.image,
      "tile": ""
    },
    on: {
      "click": _vm.go
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 566 / 768
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column h-100 px-20px py-30px px-md-30px pa-xl-40px"
  }, [_c('div', {
    staticClass: "tit-wrap mb-0"
  }, [_c('h2', {
    staticClass: "tit tit--lg white--text font-weight-medium",
    staticStyle: {
      "white-space": "pre-line"
    },
    domProps: {
      "innerHTML": _vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$meta = _vm$value2.meta) === null || _vm$value2$meta === void 0 ? void 0 : _vm$value2$meta.title)
    }
  }), _c('p', {
    staticClass: "txt txt--light font-weight-light"
  }, [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$meta = _vm$value3.meta) === null || _vm$value3$meta === void 0 ? void 0 : _vm$value3$meta.description))])]), _c('hashtag-search', _vm._b({}, 'hashtag-search', {
    tags: (_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : (_vm$value4$meta = _vm$value4.meta) === null || _vm$value4$meta === void 0 ? void 0 : _vm$value4$meta.tag
  }, false))], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }