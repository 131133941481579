export const PURCHASE_ORDER_STATUSES = {
    ORDER_NONPAYMENT: { value: "ORDER_NONPAYMENT", text: "미결제" },
    ORDER_CANCELED: { value: "ORDER_CANCELED", text: "주문취소" },
    ORDER_PAYMENT: { value: "ORDER_PAYMENT", text: "결제완료" },
    ORDER_CONFIRM: { value: "ORDER_CONFIRM", text: "배송준비중" },
    SHIPPING_CONFIRM: { value: "SHIPPING_CONFIRM", text: "배송중" },
    SHIPPING_DELAYED: { value: "SHIPPING_DELAYED", text: "배송지연" },
    DELIVERY_COMPLETE: { value: "DELIVERY_COMPLETE", text: "배송완료" },
};

export const PURCHASE_CLAIM_STATUSES = {
    CANCEL_REQUESTED: { value: "CANCEL_REQUESTED", text: "취소요청" },
    CANCEL_COMPLETED: { value: "CANCEL_COMPLETED", text: "취소완료" },
    CANCEL_REJECTED: { value: "CANCEL_REJECTED", text: "취소거부" },
    EXCHANGE_REQUESTED: { value: "EXCHANGE_REQUESTED", text: "교환요청" },
    EXCHANGE_COMPLETED: { value: "EXCHANGE_COMPLETED", text: "교환완료" },
    EXCHANGE_REJECTED: { value: "EXCHANGE_REJECTED", text: "교환거부" },
    EXCHANGE_PENDING: { value: "EXCHANGE_PENDING", text: "교환보류" },
    EXCHANGE_DELAYED: { value: "EXCHANGE_DELAYED", text: "교환지연" },
    EXCHANGE_SHIPPING: { value: "EXCHANGE_SHIPPING", text: "교환재배송" },
    RETURN_REQUESTED: { value: "RETURN_REQUESTED", text: "반품요청" },
    RETURN_COMPLETED: { value: "RETURN_COMPLETED", text: "반품완료" },
    RETURN_REJECTED: { value: "RETURN_REJECTED", text: "반품거부" },
};

export const PURCHASE_PURCHASE_STATUSES = {
    PURCHASE_PENDING: { value: "PURCHASE_PENDING", text: "구매확정보류" },
    PURCHASE_AWAITING: { value: "PURCHASE_AWAITING", text: "구매확정대기" },
    PURCHASE_SCHEDULE: { value: "PURCHASE_SCHEDULE", text: "구매확정예정" },
    PURCHASE_COMPLETE: { value: "PURCHASE_COMPLETE", text: "구매확정완료" },
    PURCHASE_CANCELED: { value: "PURCHASE_CANCELED", text: "구매취소완료" },
};

export const PURCHASE_COLLECTION_STATUSES = {
    COLLECTION_PENDING: { value: "COLLECTION_PENDING", text: "수거보류" },
    COLLECTION_PROGRESS: { value: "COLLECTION_PROGRESS", text: "수거중" },
    COLLECTION_COMPLETE: { value: "COLLECTION_COMPLETE", text: "수거완료" },
};

const claimStatuses = Object.keys(PURCHASE_CLAIM_STATUSES);

export const PURCHASE_QUERY_CODES = (() => {
    ////////////////////////////////////////////////////////////////////////////
    // 일반
    ////////////////////////////////////////////////////////////////////////////
    const paymentProcessing = {
        text: "주문접수",
        value: "paymentProcessing",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.ORDER_NONPAYMENT.value] } },
    };
    const paymentCrossedOut = {
        text: "결제완료",
        value: "paymentCrossedOut",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.ORDER_PAYMENT.value] } },
    };
    const orderAcknowledged = {
        text: "상품준비중",
        value: "orderAcknowledged",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.ORDER_CONFIRM.value] } },
    };
    const shippingConfirmed = {
        text: "배송중",
        value: "shippingConfirmed",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.SHIPPING_CONFIRM.value] } },
    };
    const deliveryCompleted = {
        text: "배송완료",
        value: "deliveryCompleted",
        params: { orderStatus: { $in: [PURCHASE_ORDER_STATUSES.DELIVERY_COMPLETE.value] } },
    };
    const purchaseCompleted = {
        text: "구매확정",
        value: "purchaseCompleted",
        params: { purchaseStatus: { $in: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value] } },
    };

    ////////////////////////////////////////////////////////////////////////////
    // 반품
    ////////////////////////////////////////////////////////////////////////////
    const claimedForReturn1 = {
        text: PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.text,
        value: "claimedForReturn1",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedForReturn2 = {
        text: PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.text,
        value: "claimedForReturn2",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedForReturn3 = {
        text: PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.text,
        value: "claimedForReturn3",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedWithReturn = {
        text: "반품",
        value: "claimedWithReturn",
        params: {
            claimStatus: {
                $in: [
                    // 반품
                    PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value,
                    PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value,
                    PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value,
                ],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
        children: [
            // 반품
            claimedForReturn1.value,
            claimedForReturn2.value,
            claimedForReturn3.value,
        ],
    };

    ////////////////////////////////////////////////////////////////////////////
    // 교환
    ////////////////////////////////////////////////////////////////////////////
    const claimedForChange1 = {
        text: PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.text,
        value: "claimedForChange1",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedForChange2 = {
        text: PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.text,
        value: "claimedForChange2",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedForChange3 = {
        text: PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.text,
        value: "claimedForChange3",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedForChange4 = {
        text: PURCHASE_CLAIM_STATUSES.EXCHANGE_PENDING.text,
        value: "claimedForChange4",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.EXCHANGE_PENDING.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedForChange5 = {
        text: PURCHASE_CLAIM_STATUSES.EXCHANGE_DELAYED.text,
        value: "claimedForChange5",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.EXCHANGE_DELAYED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedForChange6 = {
        text: PURCHASE_CLAIM_STATUSES.EXCHANGE_SHIPPING.text,
        value: "claimedForChange6",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.EXCHANGE_SHIPPING.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedWithChange = {
        text: "교환",
        value: "claimedWithChange",
        params: {
            claimStatus: {
                $in: [
                    // 교환
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_PENDING.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_DELAYED.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_SHIPPING.value,
                ],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
        children: [
            // 교환
            claimedForChange1.value,
            claimedForChange2.value,
            claimedForChange3.value,
            claimedForChange4.value,
            claimedForChange5.value,
            claimedForChange6.value,
        ],
    };

    ////////////////////////////////////////////////////////////////////////////
    // 반품/교환
    ////////////////////////////////////////////////////////////////////////////
    const claimedWithRtrChg = {
        text: "반품/교환",
        value: "claimedWithRtrChg",
        params: {
            claimStatus: {
                $in: [
                    // 반품
                    PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value,
                    PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value,
                    PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value,
                    // 교환
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_PENDING.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_DELAYED.value,
                    PURCHASE_CLAIM_STATUSES.EXCHANGE_SHIPPING.value,
                ],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
        children: [
            // 반품
            claimedForReturn1.value,
            claimedForReturn2.value,
            claimedForReturn3.value,

            // 교환
            claimedForChange1.value,
            claimedForChange2.value,
            claimedForChange3.value,
            claimedForChange4.value,
            claimedForChange5.value,
            claimedForChange6.value,
        ],
    };

    ////////////////////////////////////////////////////////////////////////////
    // 취소/환불
    ////////////////////////////////////////////////////////////////////////////
    const claimedForCancel1 = {
        text: PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.text,
        value: "claimedForCancel1",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedForCancel2 = {
        text: PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.text,
        value: "claimedForCancel2",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedForCancel3 = {
        text: PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.text,
        value: "claimedForCancel3",
        params: {
            claimStatus: {
                $in: [PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
    };
    const claimedWithCancel = {
        text: "취소/환불",
        value: "claimedWithCancel",
        params: {
            claimStatus: {
                $in: [
                    // 취소
                    PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value,
                    PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value,
                    PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value,
                ],
            },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },
        children: [
            // 취소
            claimedForCancel1.value,
            claimedForCancel2.value,
            claimedForCancel3.value,
        ],
    };

    ////////////////////////////////////////////////////////////////////////////
    // 정상
    ////////////////////////////////////////////////////////////////////////////
    const purchaseOnProcess = {
        text: "정상진행",
        value: "purchaseOnProcess",
        params: {
            claimStatus: { $nin: claimStatuses },
        },

        children: [
            // 정상진행
            paymentProcessing.value,
            paymentCrossedOut.value,
            orderAcknowledged.value,
            shippingConfirmed.value,
            deliveryCompleted.value,
            purchaseCompleted.value,
        ],
    };

    ////////////////////////////////////////////////////////////////////////////
    // 이상
    ////////////////////////////////////////////////////////////////////////////
    const claimProcessGoing = {
        text: "취소·교환·반품",
        value: "claimProcessGoing",
        params: {
            claimStatus: { $in: claimStatuses },
            purchaseStatus: {
                $nin: [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value],
            },
        },

        children: [
            // 클레임
            claimedWithReturn.value,
            claimedWithChange.value,
            claimedWithCancel.value,
        ],
    };

    return {
        // 정상진행
        purchaseOnProcess,

        paymentProcessing,
        paymentCrossedOut,
        orderAcknowledged,
        shippingConfirmed,
        deliveryCompleted,
        purchaseCompleted,

        // 클레임
        claimProcessGoing,

        // 반품/교환
        claimedWithRtrChg,

        // 반품
        claimedWithReturn,

        claimedForReturn1,
        claimedForReturn2,
        claimedForReturn3,

        // 교환
        claimedWithChange,

        claimedForChange1,
        claimedForChange2,
        claimedForChange3,
        claimedForChange4,
        claimedForChange5,
        claimedForChange6,

        // 취소
        claimedWithCancel,

        claimedForCancel1,
        claimedForCancel2,
        claimedForCancel3,
    };
})();
