var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "button-top",
    attrs: {
      "fixed": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("arrow_drop_up")]), _c('span', {
    staticClass: "font-size-10 font-weight-semibold font-secondary"
  }, [_vm._v("TOP")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }