import { USER_PAYMENT_STATUS } from "@/assets/variables"

export const initUserPayment = (payment = {}) =>({
    ...payment,

    _id: payment?._id || null,
    _user: payment?._user || null,
    _seller: payment?._seller || null,
    _product: payment?._product || null,

    orderNo: payment?.orderNo || null,
    status: payment?.status || USER_PAYMENT_STATUS.PAY_AWAITING.value,
    price: payment?.price || 0,
    expire: {
        isActive: payment?.expire?.isActive || false,
        startsAt: payment?.expire?.startsAt || null,
        endsAt: payment?.expire?.endsAt || null,
    },

    paymentAt: payment?.paymentAt || null,
})