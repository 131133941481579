<template>
    <v-card tile class="recommend-card text-center pa-12px" :href="`/minishop/${value._id}`">
        <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
            <div>
                <v-avatar size="120" class="border mb-30px">
                    <v-img :src="value.thumb ? value.thumb.src : '/images/minishop/profile-default.png'"  class="mx-auto"/>
                </v-avatar>
                <div class="tit-wrap tit-wrap--xs">
                    <h3 class="tit tit--xs font-weight-regular">{{ value?.nickname }}</h3>
                </div>
                <p class="txt txt--sm txt--dark">{{ value.address1 }} {{ value.address2 }}</p>
                <p class="txt txt--sm txt--dark">{{ value?.tel }}</p>
                <div class="mt-30px">
                    <v-row justify="center" class="row--xxs">
                        <template v-for="(tag, index) in value?.mini?.tag">
                            <v-col v-if="index < 3" :key="index" cols="auto" ><v-chip v-bind="{ ...chip_secondary }">#{{ tag }}</v-chip></v-col>
                        </template>
                    </v-row>
                </div>
            </div>
        </v-responsive>
    </v-card>
</template>

<script>
import { chip_secondary } from "@/assets/variables";
export default {
    props:{
        value: { type: Object, default: ()=> ({})}
    },
    data: ()=>({
        chip_secondary
    })
}
</script>

<style lang="scss" scoped>
.recommend-card {
    border: 1px solid var(--v-grey-lighten3);
    &__img {
        border: 1px solid var(--v-grey-lighten3);
        border-radius: 999px;
    }
}
</style>