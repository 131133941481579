<template>
    <v-app>
        <!-- S: BANNER -->
        <slot name="bandBanner" />
        <!-- E: BANNER -->

        <!-- S: HEADER -->
        <main-header v-bind="{ disableScrollTop }" />
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="subHead" />
            <slot name="contentsImmersive" />
            <div id="contents">
                <slot />
                <!-- <v-container>
                    <slot />
                    <div data-v-sticky-container class="fixed-contents">
                        <v-row justify="center" class="row--contents flex-nowrap">
                            <v-col cols="12" xl="auto" class="flex-shrink-1" :class="contentsColClass">

                                <div :class="contentsClass">
                                    <slot />
                                </div>

                            </v-col>
                            <v-col cols="auto" class="align-self-stretch d-none d-xl-block">
                                <v-divider vertical class="h-100 mr-n1px" />
                            </v-col>
                            <v-col cols="auto" class="d-none d-xl-block py-0">

                                <div v-sticky="stickyOptions" class="w-290px">
                                    <main-aside v-if="!hasCustomAside" />
                                    <slot name="custom-aside" />
                                </div>

                            </v-col>
                        </v-row>
                    </div>
                </v-container> -->
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: ASIDE -->
        <aside-button-group />
        <aside-button-product />
        <aside-button-top />
        <!-- E: ASIDE -->

        <!-- S: FOOTER -->
        <main-footer />
        <!-- E: FOOTER -->

        <!-- S: NOTIFICATION -->
        <main-notification-drawer />
        <!-- E: NOTIFICATION -->
    </v-app>
</template>

<script>
import { mapActions } from "vuex";

// import VueStickyDirective from "@renatodeleao/vue-sticky-directive";
// import MainAside from "@/components/client/inc/main-aside/main-aside.vue";

import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

import AsideButtonGroup from "@/components/client/inc/aside-button-group.vue"
import AsideButtonProduct from "@/components/client/inc/aside-button-product.vue"
import AsideButtonTop from "@/components/client/inc/aside-button-top.vue"

import MainNotificationDrawer from "@/components/client/inc/main-notification-drawer.vue";

import "@mdi/font/css/materialdesignicons.css"


export default {
    components: {
        MainFooter,
        MainHeader,

        AsideButtonGroup,
        AsideButtonProduct,
        AsideButtonTop,

        MainNotificationDrawer,
    },
    props: {
        hasCustomAside: { type: Boolean, default: false },
        disableScrollTop: { type: Boolean, default: false },
        contentsColClass: { type: String, default: "flex-grow-1" },
        contentsClass: { type: String, default: "mw-1330px" },
    },
    // directives: {
    //     VueStickyDirective,
    // },
    data: () => ({
        loading: false,

        // stickyOptions: {
        //     resizeSensor: true,
        //     topSpacing: 12,
        //     bottomSpacing: 12,
        // },
    }),
    mounted() {
        this.init();
        // this.enlipleTracker();
        // this.naverWcs();
    },
    watch: {
        "$route.path"() {
            // this.enlipleTracker();
            // this.naverWcs();
        },
    },
    methods: {
        // ...mapActions("enlipleTracker", ["callMtm"]),
        // ...mapActions("naverWcs", ["wcs_do"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.$nextTick(() => {
                    setTimeout(() => {}, 500);
                });
            } finally {
                this.loading = false;
            }
        },

        // async enlipleTracker() {
        //     this.callMtm();
        // },
        // async naverWcs() {
        //     if (!this.$route.path.includes("/shop/result")) this.wcs_do();
        // },
    },
};
</script>

<style lang="scss" scoped></style>
