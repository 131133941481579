<template>
    <section v-if="banner" class="main-visual">
        <swiper :options="swiperOptions">
            <swiper-slide v-for="(slide, index) in banner?.slides" :key="index">
                <div class="main-visual__inner">
                    <!-- <v-card v-if="slide?.url" :to="slide?.isOuterLink ? null : slide?.url || null" :href="slide?.isOuterLink ? slide?.url || null : null" :target="slide?.target" class="main-banner__inner w-100 h-100" tile :img="slide?.image"> -->
                    <v-card class="main-banner__inner w-100 h-100 d-flex align-center" tile :img="slide?.image" @click="go(slide)">
                        <!-- <div class="main-visual__img" :style="'background-image: url('+slide?.image+')'" ></div>
                        {{ slide.url }} -->
                        <v-container class="container--lg">
                            <h2 v-html="slide?.meta?.title" style="white-space: pre-line;" class="tit tit--xl font-weight-light white--text" />
                        </v-container>
                    </v-card>
                </div>
            </swiper-slide>

            <div class="swiper-control" slot="pagination">
                <div class="swiper-button-prev" />
                <span class="swiper-button-divider" />
                <div class="swiper-button-next" />
            </div>
        </swiper>
    </section>
    <!-- <page-section>
        <v-row>
            <v-col cols="12" lg="auto" :style="$vuetify.breakpoint.lgAndUp ? 'flex: 0 0 77.7778%; max-width:77.7778%;' : ''">
                <main-visual-tabs />
            </v-col>
            <v-col cols="12" lg="auto" :style="$vuetify.breakpoint.lgAndUp ? 'flex: 0 0 22.2222%; max-width:22.2222%;' : ''">
                <main-visual-aside :code="BANNERS.MAIN_2.code__pc" isPc />
                <main-visual-aside :code="BANNERS.MAIN_2.code__mb" isMb />
            </v-col>
        </v-row>
    </page-section> -->
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BANNERS } from "@/assets/variables";
// import PageSection from "@/components/client/templates/page-section.vue";
// import MainVisualTabs from "@/components/client/main/main-visual/tabs/main-visual-tabs.vue";
// import MainVisualAside from "./main-visual-aside.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        // PageSection,
        // MainVisualTabs,
        // MainVisualAside,
    },
    data() {
        return {
            banner: null,
            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                effect: "fade",
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                // pagination: {
                //     el: ".swiper-pagination",
                //     clickable: true,
                // },
                navigation: {
                    nextEl: ".main-visual .swiper-button-next",
                    prevEl: ".main-visual .swiper-button-prev",
                },
            },
        };
    },
    computed:{
        isPc() {
            return detect === "pc";
        },
        code() {
            return this.isPc ? BANNERS.MAIN_1.code__pc : BANNERS.MAIN_1.code__mb;
        }
    },
    methods: {
        async search(){
            try{
                let { banner } = await api.v1.banners.get({ code: this.code });
                this.banner = banner;

            } catch(error){
                this.$handleError(error);
            }
        },
        async go(slide){
            try {
                await api.v1.bannerLogs.post({ _slide: slide._id });

                if(slide?.isOuterLink){
                    window.open(slide?.url, slide?.target || "_self" );
                } else {
                    this.$router.push(slide?.url);
                };
            } catch(error){
                this.$handleError(error);
            }
        }
    },
    mounted(){
        this.search();
    }
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    width: 100%;
    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 1920px;
        width: 100%;
        height: 500px;
        margin: auto;
    }
    &__img {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        max-width: calc(100% + 2px);
        width: calc(100% + 2px);
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.25);
    }
    .container {
        z-index: 1;
    }
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        &__inner {
            height: 700px;
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &__inner {
            height: 600px;
        }
    }
}

.swiper-control {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 60px;
    z-index: 1;
    display: flex;
    align-items: center;
    .swiper-button-prev,
    .swiper-button-next {
        position: relative;
        width: initial;
        width: 64px;
        height: 64px;
        margin-top: initial;
        top: initial;
        left: initial;
        right: initial;
        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 32px;
            color: #fff;
        }
    }
    .swiper-button-divider {
        width: 1px;
        height: 100%;
        max-height: 40px !important;
        background: var(--border-color);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
    }
}
</style>
