import axios from "@/plugins/axios";

let url = "/api/plugins/nice/nicepayments";

export const nicepayments = {
    config(){
        return axios.get(`${url}/config`).then(result => result.data);
    },
    generate(data, collection){
        return axios.post(`${url}/generate/${collection}`, data).then(result => result.data);
    },
    cancel(data){
        return axios.post(`${url}/cancel`, data).then(result => result.data);
    }
}