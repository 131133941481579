<template>
    <div>
        <search-list-template v-if="!mode" v-bind="{ totalCount: summary.totalCount, mode: SEARCH_LIST.MINISHOP.value }"/>
        <v-row>
            <v-col v-for="(store, index) in stores" :key="index" cols="6" xl="4">
                <store-profile :value="store">
                    <template #avatar>
                        <v-img v-if="store.thumb" :src="store.thumb.src" />
                        <v-img v-else src="/images/minishop/profile-default.png" />
                    </template>
                </store-profile>
            </v-col>
        </v-row>
        <div v-if="mode" class="pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { SEARCH_LIST } from '../search-bar.vue';
import SearchListTemplate from "../search-list-template.vue";
import StoreProfile from "@/components/client/sub/store/store-profile.vue";
export default {
    components:{
        SearchListTemplate,
        StoreProfile
    },
    data: () => ({
        SEARCH_LIST,
        stores: [],
        summary: {
            totalCount: 0
        },
        loading: false,
    }),
    computed: {
        items() {
            return [...this.stores];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
        mode() {
            return this.$route.query.mode;
        },
        limit() {
            if(this.mode) return 9;
            else return 3;
        }
    },
    methods: {
        async search() {
            if(this.loading) return;
            else this.loading = true;
            try{
                let { skip, limit }= this;
                let { searchValue } = this.params;
                let { summary, stores } = await api.v1.shop.stores.gets({
                    headers: { skip, limit },
                    params: { searchKey: 'search', searchValue }
                });
                this.summary = summary;
                this.stores = stores;
                this.$emit("input", { type: "minishopsCount", totalCount: summary.totalCount });
                this.loading = false;
            } catch(error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        }
    },
    mounted(){
        this.search();
    },
    watch: {
        "$route.query"(){
            this.search();
        }
    }

}
</script>

<style>

</style>