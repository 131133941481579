var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_vm._t("default"), _c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "d-inline-block w-80px"
  }, [_vm._v("MON-FRI")])]), _c('v-col', [_vm._v(": AM 10:00 ~ PM 06:00 / SAT, SUN, HOLIDAY "), _c('span', {
    staticClass: "accent1--text"
  }, [_vm._v("OFF")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "d-inline-block w-80px"
  }, [_vm._v("LUNCH")])]), _c('v-col', [_vm._v(": PM 12:00 ~ PM 01:00")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "d-inline-block w-80px"
  }, [_vm._v("BANK INFO")])]), _c('v-col', [_vm._v(": 기업은행 296-116453-04-018 / 주식회사 레일리")])], 1)], 1)], 1)], 2), _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "txt txt--dark font-weight-medium"
  }, [_vm._v("MOAGO NEWS")])]), _c('v-row', {
    staticClass: "row--xxs txt txt--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("법인명 : 주식회사 레일리")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("등록번호 : 경기, 자60101")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("창간일 : 2024-04-23")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("등록일 : 2024-04-23")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("편집인 : 김다영")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("청소년보호책임자 : 김다영")])], 1)], 1)], 1), _c('div', {
    staticClass: "mt-24px mt-lg-48px txt txt--dark txt--sm font-weight-bold"
  }, [_vm._v("모아고의 모든 콘텐츠(기사)는 저작권법의 보호를 받는 바, 무단 전재, 복사, 배포 등을 금합니다.")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }