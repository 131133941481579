<template>
    <div>
        <div class="tit-wrap tit-wrap--sm">
            <h3 class="tit">최근 검색어</h3>
        </div>

        <v-chip-group v-if="searches.length">
            <v-chip v-for="(search, index) in searches" :key="index" color="primary" @click="go(search)">{{ search }}</v-chip>
        </v-chip-group>

        <p v-else class="txt--lg">최근 입력한 검색어가 없습니다.</p>
    </div>
</template>
<script>
import api from "@/api";
import { mapActions } from "vuex";
import { COMMON_LOG_TYPES } from "@/assets/variables";

export default {
    data: () => ({
        loading: false,
        searches: [],
    }),
    methods: {
        ...mapActions(["getSearches"]),
        init() {
            this.getSearches();
            this.sync();
        },
        async sync() {
            this.searches = this.$store.state.searches;
        },
        async go(searchValue) {
            await api.v1.shop.logs.saving({
                type: COMMON_LOG_TYPES.SEARCH.value,
                value: searchValue,
            });
            
            const header = document.querySelector(".header");
            header.setAttribute("data-searchbar", "closed");
            header.setAttribute("data-dimmed", "closed");

            this.$router.push({ path: "/search", query: { searchValue } });
        },
    },
    mounted() {
        this.getSearches();
    },
    watch: {
        "$store.state.searches"() {
            this.sync();
        },
    },
};
</script>
