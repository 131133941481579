import { addContactItem } from "./addContactItem";

/**
 * 공급받는자 추가담당자 데이터
 * @param {TaxinvoiceAddContactListData} data
 * @returns {TaxinvoiceAddContactListData}
 */
export const initShopTaxinvoiceAddContactList = (data = []) => data.map((item, index) => addContactItem({ serialNum: item.serialNum ?? index + 1, ...item }));

export default initShopTaxinvoiceAddContactList;

/**
 * @typedef {import("./addContactItem").TaxinvoiceAddContactItemData[]} TaxinvoiceAddContactListData 품목상세목록 데이터
 */
