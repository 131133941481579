export function initShopShippingRegion(method = {}) {
    return {
        ...method,

        _seller: method._seller ?? null,
        seller: method.seller ?? null,

        name: method.name ?? null,
        code: method.code ?? null,
        index: method.index ?? null,

        warehouse: initShopShippingRegionWarehouse(method.warehouse),

        isDefault: method.isDefault ?? false,
        isInitial: method.isInitial ?? false,

        // FE temp data
        tempId: method.tempId ?? Math.random(),
        disabled: method.isDefault ?? false,
    };
}

export function initShopShippingRegionWarehouse(warehouse = {}) {
    const { initGeolocation } = require("./common.inits");

    return {
        ...warehouse,

        postcode: warehouse?.postcode ?? null,
        address1: warehouse?.address1 ?? null,
        address2: warehouse?.address2 ?? null,

        geolocation: initGeolocation(warehouse?.geolocation),
    };
}
