/**
 * 수탁자 데이터
 * @param {TaxinvoiceTrusteeData} data
 * @returns {TaxinvoiceTrusteeData}
 */
export const initShopTaxinvoiceTrustee = (data = {}) => ({
    trusteeMgtKey: data.trusteeMgtKey,
    trusteeCorpNum: data.trusteeCorpNum,
    trusteeTaxRegID: data.trusteeTaxRegID,
    trusteeCorpName: data.trusteeCorpName,
    trusteeCEOName: data.trusteeCEOName,
    trusteeAddr: data.trusteeAddr,
    trusteeBizType: data.trusteeBizType,
    trusteeBizClass: data.trusteeBizClass,
    trusteeContactName: data.trusteeContactName,
    trusteeDeptName: data.trusteeDeptName,
    trusteeTEL: data.trusteeTEL,
    trusteeHP: data.trusteeHP,
    trusteeEmail: data.trusteeEmail,
    trusteeSMSSendYN: data.trusteeSMSSendYN,
});

export default initShopTaxinvoiceTrustee;

/**
 * @typedef {object} TaxinvoiceTrusteeData 수탁자 데이터
 *
 * @property {string} [trusteeMgtKey] 48.수탁자 문서번호
 * * 문서 관리를 위해 파트너가 할당하는 고유 식별번호
 * * 영문 대소문자, 숫자, 특수문자('-', '_')만 입력 가능
 * * {issueType}="역발행" 경우 필수
 *
 * @property {string} [trusteeCorpNum] 49.수탁자 사업자번호
 * * {issueType}="위수탁" 경우 필수
 *
 * @property {string} [trusteeTaxRegID] 50.수탁자 종사업장 식별번호
 * * 종사업장 식별번호는 4자리 숫자로만 구성
 *
 * @property {string} [trusteeCorpName] 51.수탁자 상호
 * * {issueType}="위수탁" 경우 필수
 *
 * @property {string} [trusteeCEOName] 52.수탁자 대표자 성명
 * * {issueType}="위수탁" 경우 필수
 *
 * @property {string} [trusteeAddr] 53.수탁자 주소
 *
 * @property {string} [trusteeBizType] 54.수탁자 업태
 *
 * @property {string} [trusteeBizClass] 55.수탁자 종목
 *
 * @property {string} [trusteeContactName] 56.수탁자 담당자 성명
 * 
 * @property {string} [trusteeDeptName] 57.수탁자 담당자 부서명
 *
 * @property {string} [trusteeTEL] 58.수탁자 담당자 연락처
 *
 * @property {string} [trusteeHP] 59.수탁자 담당자 휴대폰
 *
 * @property {string} [trusteeEmail] 60.수탁자 담당자 이메일
 *
 * @property {boolean} [trusteeSMSSendYN] 61.수탁자 알림문자 전송 여부
 * * true: 전송
 * * false: 미전송(기본값)
 * * 수신자 : 공급자 휴대폰번호 {invoicerHP}
 * * 문자 전송 포인트가 차감(단, 전송실패 건은 환불)
 * * {issueType}="역발행" 경우 세금계산서 역발행 요청시점에 알림문자 전송
 */
