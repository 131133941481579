var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "element",
    style: {
      width: _vm.width,
      height: _vm.height
    }
  }, [_vm.ready ? _vm._t("default", null, {
    "on": {
      setSelectedMarker: _vm.setSelectedMarker,
      setClickedOverlay: _vm.setClickedOverlay
    },
    "attrs": {
      selectedMarker: _vm.selectedMarker,
      clickedOverlay: _vm.clickedOverlay
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }