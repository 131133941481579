<template>
    <v-expansion-panels v-model="shows" flat tile class="rank-expansion-panels">
        <v-expansion-panel>
            <v-expansion-panel-header>
                <template v-if="shows !== 0">
                    <contents-card-rank-title v-bind="{ logs }" />
                </template>
                <template v-else>
                    <h3 class="tit tit--xxs font-weight-medium primary--text">실시간 검색어</h3>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
                <search-rank-list v-model="logs" @input="$emit('input')" />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import SearchRankList from "@/components/client/search/search-rank-list.vue";
import ContentsCardRankTitle from "./contents-card-rank-title.vue";

export default {
    components: {
        SearchRankList,
        ContentsCardRankTitle,
    },
    data: () => ({
        shows: false,
        logs: [],
    }),
    methods: {
        emit() {
            const interval = setInterval(() => this.$emit("load"), 1);
            setTimeout(() => clearInterval(interval), 500);
        },
    },
    watch: {
        shows() {
            this.emit();
        },
    },
};
</script>

<style lang="scss" scoped>
.rank-expansion-panels {
    &,
    & * {
        transition-duration: 0s !important;
    }
}
</style>