import contacts from "./contacts";
import faqs from "./faqs";
import notifications from "./notifications";
import questions from "./questions";

export default {
    contacts,
    faqs,
    notifications,
    questions
};
