/**
 * 품목상세항목 데이터
 * @param {TaxinvoiceDetailItemData} data
 * @returns {TaxinvoiceDetailItemData}
 */
export const initShopTaxinvoiceDetailItem = (data = {}) => ({
    serialNum: data.serialNum,
    purchaseDT: data.purchaseDT,
    itemName: data.itemName,
    spec: data.spec,
    qty: data.qty,
    unitCost: data.unitCost,
    supplyCost: data.supplyCost,
    tax: data.tax,
    remark: data.remark,
});

export default initShopTaxinvoiceDetailItem;

/**
 * @typedef {object} TaxinvoiceDetailItemData 품목상세항목 데이터
 *
 * @property {number} serialNum 1.일련번호
 * * 1부터 순차적으로 입력
 * * 최대 : 99
 *
 * @property {string} [purchaseDT] 2.거래일자
 * * 형식 : yyyyMMdd
 *
 * @property {string} [itemName] 3.품명
 *
 * @property {string} [spec] 4.규격
 *
 * @property {string} [qty] 5.수량
 * * -99999999.99 ~ 999999999.99
 * * 소수점 아래 2번째 자리까지 입력 가능
 *
 * @property {string} [unitCost] 6.단가
 * * -99999999999999.99 ~ 999999999999999.99
 * * 소수점 아래 2번째 자리까지 입력 가능
 *
 * @property {string} [supplyCost] 7.공급가액
 * * 정수만 입력 가능
 * * 마이너스 금액 입력 가능, 소수점 자동 절사
 *
 * @property {string} [tax] 8.세액
 * * 정수만 입력 가능
 * * 마이너스 금액 입력 가능, 소수점 자동 절사
 *
 * @property {string} [remark] 9.비고
 */
