<template>
    <v-navigation-drawer :value="$store.state.noti.shows" fixed right touchless class="main-notification-drawer">
        <v-sheet height="100vh" :color="option ? 'grey lighten-5' : 'white'">
            <div class="px-18px px-lg-24px pt-lg-36px pb-lg-18px white">
                <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="auto">
                        <v-btn icon text @click="setNotiShows(!noti.shows)">
                            <v-icon large>mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <span class="tit tit--xs font-weight-regular">알림</span>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn icon text @click="option = !option">
                            <v-icon>mdi-cog-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <main-notification-list v-if="!option" />
            <main-notification-options v-else />
        </v-sheet>
    </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import MainNotificationList from "@/components/client/inc/main-notification-list.vue";
import MainNotificationOptions from "@/components/client/inc/main-notification-options.vue";
import { initNotiOptions } from "./main-notification-options.vue";

export default {
    components: {
        MainNotificationList,
        MainNotificationOptions,
    },
    data: () => ({
        option: false,
    }),
    computed: {
        ...mapState(["noti"]),
        notiOptions(){
            return initNotiOptions(this.$store.state.notiOptions);
        }
    },
    methods: {
        ...mapMutations(["setNotiShows"]),
    },
    watch: {
        "noti.shows"(shows) {
            if (!shows) this.option = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.main-notification-drawer {
    width: 100% !important;
    max-width: 474px;
    z-index: 999;
}
</style>