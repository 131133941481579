<template>
    <v-row class="row--sm">
        <v-col cols="12" lg="5" class="d-none d-lg-block" v-if="banner">
            <contents-card-banner v-for="(value, index) in banner?.slides" :key="index" :value="value" />
        </v-col>

        <v-col cols="12" :lg="banner ? 7 : 12">
            <v-row v-if="!isMobile" class="row--sm" v-masonry>
                <v-col cols="12" lg="6">
                    <contents-card-tags v-on="{ load }"/>
                </v-col>
                <v-col cols="12" lg="6">
                    <contents-card-rank v-on="{ load }"/>
                </v-col>
                <template v-for="value in displays">
                    <v-col cols="12" lg="6" :key="value._id">
                        <contents-card-story v-bind="{ value }" v-on="{ load }" />
                    </v-col>
                </template>
            </v-row>
            <v-row v-else class="row--sm">
                <v-col cols="12" lg="6">
                    <contents-card-tags/>
                </v-col>
                <v-col cols="12" lg="6">
                    <contents-card-rank/>
                </v-col>
                <template v-for="value in displays">
                    <v-col cols="12" lg="6" :key="value._id">
                        <contents-card-story v-bind="{ value }"/>
                    </v-col>
                </template>
            </v-row>
        </v-col>

        <contents-load-banners v-model="banner"/>
        <contents-load-displays v-model="displays" @input="load"/>
    </v-row>
</template>

<script>
import detect from "@/plugins/detect";
import ContentsCardBanner from "./contents/contents-card-banner.vue";
import ContentsCardRank from "./contents/contents-card-rank.vue";
import ContentsCardStory from "./contents/contents-card-story.vue";
import ContentsCardTags from "./contents/contents-card-tags.vue";
import ContentsLoadBanners from "./contents/contents-load-banners.vue";
import ContentsLoadDisplays from "./contents/contents-load-displays.vue";

export default {
    components: {
        ContentsCardBanner,
        ContentsCardRank,
        ContentsCardTags,
        ContentsLoadBanners,
        ContentsLoadDisplays,
        ContentsCardStory,
    },
    data: () => ({
        banner: null,
        displays: [],
    }),
    computed: {
        isMobile(){
            return detect === "mobile";
        }
    },
    methods: {
        load() {
            if(!this.isMobile){
                setTimeout(this.$redrawVueMasonry, 100);
            }
        },
    },
};
</script>

<style>
</style>