import axios from "@/plugins/axios";

let url = "/api/v1/shop/brands";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data.code}`).then((result) => result.data);
    },
    getChars() {
        return axios.get(`${url}/chars`).then((result) => result.data);
    }
};
