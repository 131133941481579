<template>
    <v-row align="end" class="row--sm">
        <v-col cols="12" lg="10">
            <v-text-field v-model="location" hide-details label="도로명, 지역 또는 우편번호" @keydown.enter="search"></v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
            <v-btn class="v-size--xx-large" block v-bind="{ ...btn_primary }" @click="search">검색</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { btn_primary } from "@/assets/variables";

export default {
    data: () => ({
        btn_primary,
        location: ""
    }),
    computed:{
        query(){
            let { ... query } = this.$route.query;
            delete query.page;
            delete query._location;
            return query;
        }
    },
    methods:{
        ...mapActions("location", ["getAddressByKeyword"]),
        ...mapMutations("location", ["setLvl"]),
        search(){
            try{
                const callback = (results) => {
                    this.setLvl({ lvl: 7 });
                    this.$nextTick(() => this.$router.push({
                        query:{ ...this.query, lat: results?.y, lng: results?.x, searchValue: this.location }
                    }));
                };
                this.getAddressByKeyword({ keyword: this.location, callback})
            }catch(error){
                this.$handleError(error);
            }
        }
    }
}
</script>

<style>

</style>