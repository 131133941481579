var render = function render(){
  var _vm$banner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banner ? _c('section', {
    staticClass: "main-visual"
  }, [_c('swiper', {
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l((_vm$banner = _vm.banner) === null || _vm$banner === void 0 ? void 0 : _vm$banner.slides, function (slide, index) {
    var _slide$meta;
    return _c('swiper-slide', {
      key: index
    }, [_c('div', {
      staticClass: "main-visual__inner"
    }, [_c('v-card', {
      staticClass: "main-banner__inner w-100 h-100 d-flex align-center",
      attrs: {
        "tile": "",
        "img": slide === null || slide === void 0 ? void 0 : slide.image
      },
      on: {
        "click": function ($event) {
          return _vm.go(slide);
        }
      }
    }, [_c('v-container', {
      staticClass: "container--lg"
    }, [_c('h2', {
      staticClass: "tit tit--xl font-weight-light white--text",
      staticStyle: {
        "white-space": "pre-line"
      },
      domProps: {
        "innerHTML": _vm._s(slide === null || slide === void 0 ? void 0 : (_slide$meta = slide.meta) === null || _slide$meta === void 0 ? void 0 : _slide$meta.title)
      }
    })])], 1)], 1)]);
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-button-prev"
  }), _c('span', {
    staticClass: "swiper-button-divider"
  }), _c('div', {
    staticClass: "swiper-button-next"
  })])], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }