var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "top-banner-wrapper"
  }, [_c('v-expand-transition', [_c('v-alert', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shows,
      expression: "shows"
    }],
    staticClass: "top-banner",
    attrs: {
      "width": "100%",
      "dismissible": ""
    },
    scopedSlots: _vm._u([{
      key: "close",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "absolute": "",
            "right": "",
            "center": "",
            "fab": "",
            "color": "transparent"
          },
          on: {
            "click": function ($event) {
              return _vm.hideItem(_vm.item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "24"
          }
        }, [_vm._v("mdi-close")])], 1)];
      },
      proxy: true
    }])
  }, [_c(_vm.linkAttrs.is, _vm._b({
    tag: "component"
  }, 'component', _vm.linkAttrs, false), [_c('v-img', _vm._b({
    staticClass: "mx-auto",
    attrs: {
      "contain": "",
      "max-width": "100%",
      "max-height": "160",
      "width": "100%",
      "height": "100%"
    }
  }, 'v-img', {
    src: _vm.src
  }, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }