<template>
    <v-card tile :to="`${path}/${value._id}`">
        <v-row align="center" class="row--xxs">
            <v-col cols="12" md="2">
                <v-card tile flat :img="value.storyThumbSrc" class="border">
                    <v-responsive :aspect-ratio="1 / 1" />
                </v-card>
            </v-col>
            <v-col cols="12" md="10">
                <div class="tit-wrap tit-wrap--xs">
                    <v-card-title class="tit tit--xxs font-weight-medium text-truncate pa-0 d-block">{{ value.subject }}</v-card-title>
                    <v-card-text class="txt text-truncate-3 pa-0">{{ content }}</v-card-text>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { initStoryBoardContentText } from "@/store/story";

export default {
    props: {
        path: { type: String, default: "/story/:code" },
        value: { type: Object, default: () => ({}) }, // boards
    },
    computed: {
        content() {
            return this.value.contentText ?? initStoryBoardContentText(this.value);
        },
    },
};
</script>

<style <style lang="scss" scoped>
@media (min-width: 768px) {
    .v-card {
        padding-bottom: 16px;
        border-bottom: 1px solid var(--border-color);
    }
}
</style>