var render = function render(){
  var _vm$banner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm"
  }, [_vm.banner ? _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, _vm._l((_vm$banner = _vm.banner) === null || _vm$banner === void 0 ? void 0 : _vm$banner.slides, function (value, index) {
    return _c('contents-card-banner', {
      key: index,
      attrs: {
        "value": value
      }
    });
  }), 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": _vm.banner ? 7 : 12
    }
  }, [!_vm.isMobile ? _c('v-row', {
    directives: [{
      name: "masonry",
      rawName: "v-masonry"
    }],
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('contents-card-tags', _vm._g({}, {
    load: _vm.load
  }))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('contents-card-rank', _vm._g({}, {
    load: _vm.load
  }))], 1), _vm._l(_vm.displays, function (value) {
    return [_c('v-col', {
      key: value._id,
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('contents-card-story', _vm._g(_vm._b({}, 'contents-card-story', {
      value
    }, false), {
      load: _vm.load
    }))], 1)];
  })], 2) : _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('contents-card-tags')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('contents-card-rank')], 1), _vm._l(_vm.displays, function (value) {
    return [_c('v-col', {
      key: value._id,
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('contents-card-story', _vm._b({}, 'contents-card-story', {
      value
    }, false))], 1)];
  })], 2)], 1), _c('contents-load-banners', {
    model: {
      value: _vm.banner,
      callback: function ($$v) {
        _vm.banner = $$v;
      },
      expression: "banner"
    }
  }), _c('contents-load-displays', {
    on: {
      "input": _vm.load
    },
    model: {
      value: _vm.displays,
      callback: function ($$v) {
        _vm.displays = $$v;
      },
      expression: "displays"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }