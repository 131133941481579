<template>
    <v-sheet class="px-18px px-lg-24px py-20px py-lg-40px">
        <div class="mb-40px mb-lg-50px">
            <v-row no-gutters justify="center">
                <v-col cols="auto">
                    <!-- <v-tabs :show-arrows="false" grow>
                        <v-tab  v-for="(category, index) in btnItems" :key="index">
                            <v-btn :value="category.value" small class="rounded-pill">
                                {{ category.text }}
                            </v-btn>
                        </v-tab>
                    </v-tabs> -->
                    <swiper :options="swiperOptions">
                        <template v-for="(category, index) in btnItems">
                            <swiper-slide :key="index">
                                <v-btn-toggle v-model="filter" class="notification-btn-toggle">
                                    <v-btn :value="category.value" block small class="rounded-pill">
                                        {{ category.text }}
                                    </v-btn>
                                </v-btn-toggle>
                            </swiper-slide>
                        </template>
                    </swiper>
                    <!-- <v-slide-group v-model="filter" mandatory class="notification-btn-toggle">
                        <v-slide-item v-slot="{ active, toggle }" v-for="(category, index) in btnItems" :key="index" :value="category.value">
                            <v-btn :input-value="active" small class="rounded-pill" @click="toggle">
                                {{ category.text }}
                            </v-btn>
                        </v-slide-item>
                    </v-slide-group> -->
                    <!-- <v-btn-toggle v-model="filter" class="notification-btn-toggle">
                        <v-btn v-for="(category, index) in btnItems" :key="index" :value="category.value" small class="rounded-pill">
                            {{ category.text }}
                        </v-btn>
                    </v-btn-toggle> -->
                </v-col>
            </v-row>
        </div>
        <v-row class="row--xs">
            <v-col cols="12" v-for="(alarm, index) in alarms" :key="index">
                <main-notification-card v-bind="{ value: alarm }" @update="search" />
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import api from "@/api";
import { ALARM_CATEGORIES, ALARM_TYPES } from "@/assets/variables";
import MainNotificationCard from "@/components/client/inc/main-notification-card.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

const alarmCategories = Object.values(ALARM_CATEGORIES);

export default {
    components: {
        MainNotificationCard,
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        summary: {
            totalCount: 0,
        },
        alarms: [],
        filter: null,

        swiperOptions: {
            spaceBetween: 8,
            allowTouchMove: true,
            speed: 500,
            slidesPerView: 3,
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                },
            },
        },
    }),
    computed: {
        ...mapState(["accessToken"]),
        btnItems() {
            return [{ text: "전체", value: null }, ...alarmCategories];
        },
        params() {
            return {
                type: ALARM_TYPES.NOTI.value,
                category: this.filter,
            };
        },
    },
    methods: {
        ...mapMutations(["setNotiCount"]),
        init() {
            if (this.accessToken) this.search();
        },
        async search() {
            try {
                const { summary, alarms } = await api.v1.me.alarms.gets({
                    headers: {},
                    params: this.params,
                });

                this.alarms = alarms;
                this.summary = summary;

                const unreadAlarms = this.alarms.filter((alarm) => alarm.shows);

                this.setNotiCount(unreadAlarms.length);
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
};
</script>

<style lang="scss" scoped>
// .theme--light.v-btn-toggle.notification-btn-toggle {
//     .v-btn {
//         margin: 0 4px;
//         border-left-width: 1px !important;
//         &:first-child {
//             margin-left: 0;
//         }
//         &:last-child {
//             margin-right: 0;
//         }
//         &:not(.v-btn--active) {
//             background-color: #fff !important;
//             border-color: var(--v-primary-base) !important;
//             color: var(--v-primary-base) !important;
//         }
//     }
// }

// .theme--light .notification-btn-toggle .v-slide-item {
//     margin: 0 4px;
// }

// .theme--light .notification-btn-toggle .v-btn {
//     margin: 0 4px;
//     border-left-width: 1px !important;
// }

// .theme--light .notification-btn-toggle .v-slide-item:first-child .v-btn {
//     margin-left: 0;
// }

// .theme--light .notification-btn-toggle .v-slide-item:last-child .v-btn {
//     margin-right: 0;
// }

// .theme--light .notification-btn-toggle .v-btn {
//     border: 1px solid var(--v-primary-base) !important;
//     background-color: #fff !important;
//     color: var(--v-primary-base) !important;
// }

.notification-btn-toggle {
    width: 100%;
}
</style>