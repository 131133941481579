<template>
    <div class="map-sheet-actions">
        <v-btn small fab color="white" elevation="6" class="mr-6 mr-md-10" @click="setCurrent">
            <v-icon>mdi-crosshairs</v-icon>
        </v-btn>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    computed:{
        ...mapState("location", ["lat", "lng"]),
    },
    methods: {
        ...mapActions("location", ["getCurrentLocation"]),
        async setCurrent() {
            try {
                this.getCurrentLocation().then(()=> this.$router.push({ query:{
                    lat: this.lat,
                    lng: this.lng,
                    lvl: 6,
                    category: this.$route.query.category
                } }))
            } catch (error) {
                console.error(error);
                alert("위치 권한을 허용하신 뒤 다시 시도하십시오");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.map-sheet-actions {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
