<template>
    <v-sheet color="transparent" class="px-18px px-lg-24px py-20px py-lg-40px">
        <v-row class="row--xxs">
            <v-col cols="12">
                <v-card elevation="8" class="rounded-sm px-20px px-lg-24px py-18px">
                    <div class="tit-wrap tit-wrap--sm">
                        <v-card-title class="pa-0 txt txt--dark font-weight-bold">알림설정</v-card-title>
                    </div>
                    <!-- <v-card-actions> -->
                    <v-row justify="space-between" class="row--xs">
                        <v-col cols="auto">
                            <label for="badge" class="txt txt--dark txt--sm cursor-pointer">배지 아이콘 표시</label>
                        </v-col>
                        <v-col cols="auto">
                            <v-switch v-model="form.isShow" v-bind="{ ...attrs_switch }" id="badge" @change="update(form)" />
                        </v-col>
                    </v-row>
                    <!-- </v-card-actions> -->
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card elevation="8" class="rounded-sm px-20px px-lg-24px py-18px">
                    <div class="tit-wrap tit-wrap--sm">
                        <v-card-title class="pa-0 txt txt--dark font-weight-bold">알림 종류별 설정</v-card-title>
                    </div>
                    <!-- <v-card-actions> -->
                    <v-row v-for="(value, index) in switchValues" :key="index" justify="space-between" class="row--xs">
                        <v-col cols="auto">
                            <label for="product" class="txt txt--dark txt--sm cursor-pointer">{{ value.text }}</label>
                        </v-col>
                        <v-col cols="auto">
                            <v-switch v-model="value.switch" v-bind="{ ...attrs_switch }" id="product" @change="updateDisabled(switchValues)" />
                        </v-col>
                    </v-row>
                    <!-- </v-card-actions> -->
                </v-card>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { attrs_switch, ALARM_CATEGORIES } from "@/assets/variables";
import { mapActions } from "vuex";

const categories = Object.values(ALARM_CATEGORIES);

export const initNotiOptions = (noti = {}) => ({
    ...noti,

    isShow: noti?.isShow !== undefined ? noti?.isShow : true,
    disabledCategories: noti?.disabledCategories || [],
});

export default {
    data: () => ({
        attrs_switch,
        categories,
        form: initNotiOptions(),
    }),
    computed: {
        notiOptions() {
            return initNotiOptions(this.$store.state?.user?.noti);
        },
        switchValues() {
            return categories.map((category) => {
                return { ...category, switch: !this.notiOptions.disabledCategories.includes(category.value) };
            });
        },
    },
    methods: {
        ...mapActions(["getUser"]),
        async update(form) {
            try {
                await api.v1.me.put({ noti: form});

                await this.getUser();

            } catch (error) {
                this.$handleError(error);
            }
        },
        updateDisabled(switchValues) {
            const disabled = switchValues.filter((item)=> !item.switch).map(({ value })=> value );
            this.form.disabledCategories = [...disabled];

            this.update(this.form);
        },
        sync() {
            this.form = this.notiOptions;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        notiOptions(){
            this.sync();
        }
    }
};
</script>