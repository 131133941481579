var render = function render(){
  var _vm$value, _vm$value2, _vm$value3, _vm$value3$mini, _vm$value4, _vm$value4$mini;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": "",
      "outlined": "",
      "to": {
        path: `/minishop/${_vm.value._id}`
      },
      "align": "center"
    }
  }, [_c('v-sheet', {
    staticClass: "v-card__inner"
  }, [_vm.value.banner ? _c('v-img', {
    staticClass: "v-card__image d-lg-none",
    attrs: {
      "src": _vm.value.banner.url,
      "aspect-ratio": 1 / 1
    }
  }) : _c('v-img', {
    staticClass: "v-card__image d-lg-none",
    attrs: {
      "src": "/images/minishop/skin-default.jpg",
      "aspect-ratio": 1 / 1
    }
  }), _vm.value.banner ? _c('v-img', {
    staticClass: "v-card__image d-none d-lg-block",
    attrs: {
      "src": _vm.value.banner.url,
      "aspect-ratio": 2 / 1
    }
  }) : _c('v-img', {
    staticClass: "v-card__image d-none d-lg-block",
    attrs: {
      "src": "/images/minishop/skin-default.jpg",
      "aspect-ratio": 2 / 1
    }
  }), _c('v-avatar', {
    staticClass: "border",
    attrs: {
      "size": "62"
    }
  }, [_vm._t("avatar")], 2), _c('v-card-title', {
    staticClass: "tit tit--xs justify-center"
  }, [_vm._v(_vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.nickname))]), _c('v-card-text', {
    staticClass: "txt txt--dark"
  }, [_c('p', [_vm._v(_vm._s(_vm.address))]), _c('p', [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.tel))]), _vm._l((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$mini = _vm$value3.mini) === null || _vm$value3$mini === void 0 ? void 0 : _vm$value3$mini.tag, function (tag, index) {
    return _c('span', {
      key: index
    }, [_vm._v("#" + _vm._s(tag) + " ")]);
  }), _c('p', {
    staticClass: "grey--text"
  }, [_vm._v("팔로워 " + _vm._s(((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : (_vm$value4$mini = _vm$value4.mini) === null || _vm$value4$mini === void 0 ? void 0 : _vm$value4$mini.followCount) || 0))])], 2)], 1), _vm.hot ? _c('v-img', {
    staticClass: "ribbon",
    attrs: {
      "src": "/images/sub/store/hot-ribbon.png"
    }
  }) : _vm._e(), _vm.best ? _c('v-img', {
    staticClass: "ribbon",
    attrs: {
      "src": "/images/sub/store/best-ribbon.png"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }