import analytics from "./analytics";
import bandBanners from "./band-banners";
import bannerSlides from "./banner-slides";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import files from "./files";
import forms from "./forms";
import inquiries from "./inquiries";
import levels from "./levels";
import messages from "./messages";
import popups from "./popups";
import setting from "./setting";
import settlements from "./settlements";
import shop from "./shop";
import statistics from "./statistics";
import story from "./story";
import terms from "./terms";
import users from "./users";

export default {
    analytics,
    bandBanners,
    bannerSlides,
    banners,
    boards,
    center,
    files,
    forms,
    inquiries,
    levels,
    messages,
    popups,
    setting,
    settlements,
    shop,
    statistics,
    story,
    terms,
    users,
};
