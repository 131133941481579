export const QUESTION_TYPES = {
    DELIVERY: { value: "DELIVERY", text: "배송지연/불만" },
    RETURNED: { value: "RETURNED", text: "반품문의" },
    ASINQUIRY: { value: "ASINQUIRY", text: "A/S문의" },
    REFUNDED: { value: "REFUNDED", text: "환불문의" },
    PURCHASE: { value: "PURCHASE", text: "주문결제문의" },
    ACCOUNTS: { value: "ACCOUNTS", text: "회원정보문의" },
    CANCELED: { value: "CANCELED", text: "취소문의" },
    EXCAHNGE: { value: "EXCAHNGE", text: "교환문의" },
    PRODINFO: { value: "PRODINFO", text: "상품정보문의" },
    ETC: { value: "ETC", text: "기타문의" },
}

export const INQUIRY_TYPES = {
    SIZE: { value: "SIZE", text: "사이즈 문의" },
    DEFECTIVE: { value: "DEFECTIVE", text: "불량상품 문의" },
    COLOR: { value: "COLOR", text: "색상 문의" },
    STOCK: { value: "STOCK", text: "재고 문의" },
    LAUNDRY: { value: "LAUNDRY", text: "세탁 문의" },
    ETC: { value: "ETC", text: "기타 문의" },
}


export const CENTER_QUESTION_CODES = {
    QUESTIONS: { value: "QUESTIONS", text: "1:1문의" },
    INQUIRIES: { value: "INQUIRIES", text: "상품문의" },
};

// export const CENTER_QUESTION_TYPES = {
//     [CENTER_QUESTION_CODES.QUESTIONS.value]: {
//         DELIVERY: { value: "DELIVERY", text: "배송지연/불만" },
//         RETURNED: { value: "RETURNED", text: "반품문의" },
//         ASINQUIRY: { value: "ASINQUIRY", text: "A/S문의" },
//         REFUNDED: { value: "REFUNDED", text: "환불문의" },
//         PURCHASE: { value: "PURCHASE", text: "주문결제문의" },
//         ACCOUNTS: { value: "ACCOUNTS", text: "회원정보문의" },
//         CANCELED: { value: "CANCELED", text: "취소문의" },
//         EXCAHNGE: { value: "EXCAHNGE", text: "교환문의" },
//         PRODINFO: { value: "PRODINFO", text: "상품정보문의" },
//         ETC: { value: "ETC", text: "기타문의" },
//     },
//     [CENTER_QUESTION_CODES.INQUIRIES.value]: {
//         SIZE: { value: "PRODUCTS", text: "사이즈 문의" },
//         DEFECTIVE: { value: "PRODUCTS", text: "불량상품 문의" },
//         COLOR: { value: "PRODUCTS", text: "색상 문의" },
//         STOCK: { value: "PRODUCTS", text: "재고 문의" },
//         LAUNDRY: { value: "PRODUCTS", text: "세탁 문의" },
//         ETC: { value: "PRODUCTS", text: "기타 문의" },
//     },
// };
