export const ISSUE_TYPES = {
    REQUEST: { value: "REQUEST", text: "다운로드" },
    INSTANT: { value: "INSTANT", text: "즉시발급" },
};

export const ISSUE_EVENT_TYPES = {
    USER_JOIN: { value: "USER_JOIN", text: "회원가입" },
    USER_LEVEL_UP: { value: "USER_LEVEL_UP", text: "회원승급" },
    USER_SPECIAL_DATE: { value: "USER_SPECIAL_DATE", text: "회원기념일" },
    SHOP_SPECIFIC_DAY: { value: "SHOP_SPECIFIC_DAY", text: "발급일지정" },
    PURCHASE_COMPLETE: { value: "PURCHASE_COMPLETE", text: "상품구매확정" },
    REVIEW_TEXT_WRITE: { value: "REVIEW_TEXT_WRITE", text: "일반리뷰작성" },
    REVIEW_IMG_UPLOAD: { value: "REVIEW_IMG_UPLOAD", text: "포토리뷰작성" },
};

export const ISSUE_EVENT_CODES__USER_SPECIAL_DATE = {
    BIRTHDAY_OF_PERSON: { value: "BIRTHDAY_OF_PERSON", text: "생일", fieldName: "birthday" },
    FOUND_DATE_COMPANY: { value: "FOUND_DATE_COMPANY", text: "설립일", fieldName: "foundedAt" },
    JOIN_DAY_OF_MEMBER: { value: "JOIN_DAY_OF_MEMBER", text: "가입일", fieldName: "createdAt" },
};

export const ISSUE_ROUND_TYPES = {
    SINGLE: { value: "SINGLE", text: "일회성" },
    BOUNDED: { value: "BOUNDED", text: "회차성" },
    INFINITE: { value: "INFINITE", text: "무제한" },
};

export const ISSUE_TARGET_TYPES = {
    ALL: { value: "ALL", text: "전체" },
    USERS: { value: "USERS", text: "회원" },
    USER_TYPES: { value: "USER_TYPES", text: "회원유형" },
    USER_LEVELS: { value: "USER_LEVELS", text: "회원등급" },
};
