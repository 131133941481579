<template>
    <span> <slot name="activator" v-bind="{ active, toggle, loading }" /> </span>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import { mapState } from "vuex";

export default {
    props: {
        params: { type: Object, default: () => ({}) },
        // _follower : 프로필 주인
        // followings : 프로필 방문자의 팔로잉 목록
    },
    data: () => ({
        detect,
        loading: false
    }),
    computed: {
        ...mapState(["accessToken"]),
        // 프로필 방문자의 팔로잉 목록에 프로필 주인이 포함되어 있는가
        following(){
            return (this.params.followings || []).find(following => following._following === this.params._follower);
        },
        active() {
            return !!this.following;
        },
        // 프로필을 조회하는 주체
        _me(){
            return this.$store.state.payload._user;
        }
    },
    methods: {
        async toggle() {
            if (this.loading) return;
            else this.loading= true;
            try{
                if (!this.accessToken) {
                    alert("로그인 후 사용하실 수 있습니다");
                    if (this.detect == "mobile") this.$router.push("/login");
                    return;
                }
                if (!!this.following) {
                    await api.v1.me.follows.delete({ _id: this.following._id });
                    this.$emit("input", "unfollow");
                } else {
                    await api.v1.me.follows.post({ _following: this.params._follower });
                    this.$emit("input", "follow");
                }
            } catch(error){
                this.$handleError(error); 
            } finally{
                this.loading= false;
            }
        },
    },
};
</script>
