export const ANALYTICS_PERIOD_FILTER = {
    DAILY: { text: "일간", value: "DAILY" },
    WEEKLY: { text: "주간", value: "WEEKLY" },
    MONTHLY: { text: "월간", value: "MONTHLY" },
};

export const ANALYTICS_DAYS_OF_WEEK = {
    MONDAY: { text: "월", value: "MONDAY" },
    TUESDAY: { text: "화", value: "TUESDAY" },
    WEDNESDAY: { text: "수", value: "WEDNESDAY" },
    THURSDAY: { text: "목", value: "THURSDAY" },
    FRIDAY: { text: "금", value: "FRIDAY" },
    SATURDAY: { text: "토", value: "SATURDAY" },
    SUNDAY: { text: "일", value: "SUNDAY" },
}

export const ANALYTICS_MONTHS_OF_YEAR= {
    JANUARY: { text: "1월", value: "JANUARY" },
    FEBRUARY: { text: "2월", value: "FEBRUARY" },
    MARCH: { text: "3월", value: "MARCH" },
    APRIL: { text: "4월", value: "APRIL" },
    MAY: { text: "5월", value: "MAY" },
    JUNE: { text: "6월", value: "JUNE" },
    JULY: { text: "7월", value: "JULY" },
    AUGUST: { text: "8월", value: "AUGUST" },
    SEPTEMBER: { text: "9월", value: "SEPTEMBER" },
    OCTOBER: { text: "10월", value: "OCTOBER" },
    NOVEMBER: { text: "11월", value: "NOVEMBER" },
    DECEMBER: { text: "12월", value: "DECEMBER" }
}

export const ANALYTICS_VISITS_TYPES = {
    DAILY_WEEKLY : { text: "일간/주간 방문자 통계", value: "DAILY_WEEKLY" },
    MONTHLY_YEARLY : { text: "월간/연간 방문자 통계", value: "MONTHLY_YEARLY" },
}