<template>
    <client-page class="app--main">
        <!-- S: 띠배너 -->
        <template #bandBanner> <main-band-banners /> </template>
        <!-- E: 띠배너 -->

        <!-- S: 메인비주얼, 배너 -->
        <main-visual />
        <!-- E: 메인비주얼, 배너 -->

        <page-section class="main-section" containerSize="container--lg">
            <main-contents />
        </page-section>

        <page-section-wide class="py-0">
            <v-row no-gutters>
                <v-col cols="6" lg="3">
                    <v-card href="/shop/products?category=elctric-bicycle" tile img="/images/main/banner-bicycle.jpg" class="category-banner">
                        <v-responsive :aspect-ratio="480 / 750">
                            <div class="category-banner__caption">
                                <div>
                                    <h3 class="tit font-weight-light mb-8px">전기자전거</h3>
                                    <p class="txt txt--light line-height-17 d-none d-xl-block">전기 모터를 이용해 힘들이지 않고 주행할 수 있습니다. 핸들 스로틀로 속도를 올리는 방식과 모터가 페달을 쉽게 돌리도록 도와주는 PAS방식이 있습니다.</p>
                                </div>
                                <v-row class="row--xxs d-none d-xl-flex" align="center">
                                    <v-col><span class="gradient-line" /></v-col>
                                    <v-col cols="auto" class="font-weight-bold">go</v-col>
                                </v-row>
                            </div>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="6" lg="3">
                    <v-card to="/shop/products?category=elctric-scooter" tile img="/images/main/banner-scooter.jpg" class="category-banner">
                        <v-responsive :aspect-ratio="480 / 750">
                            <div class="category-banner__caption">
                                <div>
                                    <h3 class="tit font-weight-light mb-8px">전동스쿠터</h3>
                                    <p class="txt txt--light line-height-17 d-none d-xl-block">두 개 혹은 세 개의 바퀴를 가지고 전기로 작동하는 스쿠터입니다.</p>
                                </div>
                                <v-row class="row--xxs d-none d-xl-flex" align="center">
                                    <v-col><span class="gradient-line" /></v-col>
                                    <v-col cols="auto" class="font-weight-bold">go</v-col>
                                </v-row>
                            </div>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="6" lg="3">
                    <v-card href="/shop/products?category=electric-kickboard" tile img="/images/main/banner-kickboard.jpg" class="category-banner">
                        <v-responsive :aspect-ratio="480 / 750">
                            <div class="category-banner__caption">
                                <div>
                                    <h3 class="tit font-weight-light mb-8px">전동킥보드</h3>
                                    <p class="txt txt--light line-height-17 d-none d-xl-block">개인형 이동장치의 하나로, 우리가 흔히 말하는 킥보드를 기본 형태로 취하고 전동관련 장치를 달아 전기력으로 달릴 수 있는 1-2인용 운송수단인 퍼스널 모빌리티 입니다.</p>
                                </div>
                                <v-row class="row--xxs d-none d-xl-flex" align="center">
                                    <v-col><span class="gradient-line" /></v-col>
                                    <v-col cols="auto" class="font-weight-bold">go</v-col>
                                </v-row>
                            </div>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="6" lg="3">
                    <v-card href="/shop/products?category=accessory" tile img="/images/main/banner-accessories.jpg" class="category-banner">
                        <v-responsive :aspect-ratio="480 / 750">
                            <div class="category-banner__caption">
                                <div>
                                    <h3 class="tit font-weight-light mb-8px">악세서리</h3>
                                    <p class="txt txt--light line-height-17 d-none d-xl-block">이륜 모빌리티를 사용하는데 필요한 악세서리 및 주요 부품을 판매합니다.</p>
                                </div>
                                <v-row class="row--xxs d-none d-xl-flex" align="center">
                                    <v-col><span class="gradient-line" /></v-col>
                                    <v-col cols="auto" class="font-weight-bold">go</v-col>
                                </v-row>
                            </div>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
        </page-section-wide>

        <page-section class="main-section" containerSize="container--lg">
            <main-recommend-stores />
        </page-section>

        <page-section class="main-section" containerSize="container--lg">
            <main-map />
        </page-section>

        <popup-layer v-for="popup in popups" :key="popup._id" :popup="popup" />
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";

import MainBandBanners from "@/components/client/main/main-band-banners/main-band-banners.vue";
import MainContents from "@/components/client/main/main-contents.vue";
import MainMap from "@/components/client/main/main-map/main-map.vue";
import MainRecommendStores from "@/components/client/main/main-recommend-stores/main-recommend-stores.vue";
import MainVisual from "@/components/client/main/main-visual/main-visual.vue";

import PageSection from "@/components/client/templates/page-section.vue";
import PageSectionWide from "@/components/client/templates/page-section-wide.vue";

export default {
    components: {
        ClientPage,
        PopupLayer,

        MainBandBanners,
        MainContents,
        MainMap,
        MainRecommendStores,
        MainVisual,

        PageSection,
        PageSectionWide,
    },
    data: () => ({
        popups: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getPopups();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getPopups() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/popups");
                    this.popups = res.data.popups;
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.app--main {
    ::v-deep {
        #contents {
            padding: 0;
        }
        .v-input--selection-controls .v-input__slot > .v-label,
        .v-input--selection-controls .v-radio > .v-label {
            display: inline-block;
        }
        .v-main {
            padding: 0 !important;
        }
        .v-application--wrap {
            padding-top: var(--header-body-height);
        }
    }
}
@media (min-width: 1024px) {
    .app--main {
        ::v-deep {
            .v-application--wrap {
                padding-top: 0;
            }
        }
    }
}

// top link
.top-link {
    background-color: #ff40ff;
    &__lists {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            left: 0;
            background-color: #ff40ff;
        }
    }
    &__list {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            display: block;
            width: 1px;
            height: 12px;
            background-color: rgba(255, 255, 255, 0.5);
        }
        &:first-child::before {
            display: none;
        }
    }
    &__list-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        line-height: 1.2;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        padding: 4px 14px;
        transition: all ease-out 0.2s;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .top-link {
        &__lists {
            padding-left: 52px;
            > .top-link__list:first-child {
                margin-left: -20px;
            }
            &::after {
                display: none;
            }
        }
        &__list-link {
            height: 52px;
            padding: 6px 20px;
        }
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 1024px) {
    .top-link {
        .container {
            padding: 0;
        }
    }
}

.product-list {
    margin-top: 20px;
    border-top: 1px solid var(--v-grey-lighten3);
    max-height: 286px;
    overflow-x: hidden;
    overflow-y: auto;
    // &::-webkit-scrollbar {
    //     width: 2px;
    // }
    // &::-webkit-scrollbar-thumb {
    //     background: var(--v-grey-base);
    // }
    // &::-webkit-scrollbar-track {
    //     background: var(--v-grey-lighten3);
    // }
    &__item {
        padding: 14px 0;
        border-bottom: 1px solid var(--v-grey-lighten3);
    }
}

.category-banner {
    color: #fff !important;
    &__caption {
        text-align: center;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%);
        padding: 30px 0;
    }
}
@media (min-width: 1200px) {
    .category-banner {
        &__caption {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            padding: 90px 70px;
            transition: all ease-out 0.2s;
            opacity: 0;
        }
        &:hover {
            .category-banner__caption {
                background: rgba(0, 0, 0, 0.6);
                opacity: 1;
            }
        }
    }

    .gradient-line {
        display: inline-block;
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        margin-bottom: 4px;
    }
}
</style>
