import axios from "@/plugins/axios";
import moneys from "./moneys";

let url = "/api/console/shop/orders";

export const orders = {
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    cancel(data){
        return axios.post(`${url}/${data._id}/cancel`, data).then((result) => result.data);
    },

    moneys,
};

export default orders;
