export * from "./analytics.constants";
export * from "./board.constants";
export * from "./center-questions.constants";
export * from "./common.issue.constants";
export * from "./common.log.constants";
export * from "./common.usage.constants";
export * from "./forms.constants";
export * from "./shop-alarms.constants";
export * from "./shop-bulk-upload.constants";
export * from "./shop-coupons.constants";
export * from "./shop-services.constants";
export * from "./shop-moneys.constants";
export * from "./shop-points.constants";
export * from "./shop-products.constants";
export * from "./shop-purchases.constants";
export * from "./shop-shippings.constants";
export * from "./user-levels.constants";
export * from "./user-money-statistics.constants";
export * from "./user-moneys.constants";
export * from "./user-points.constants";

export const USER_FILE_TYPES = {};

export const USER_TYPES = {
    PERSON: { value: "PERSON", text: "개인회원" },
    COMPANY: { value: "COMPANY", text: "기업회원" },
    OPERATOR: { value: "OPERATOR", text: "관리자" },
};

export const USER_LEVEL_CODES = {
    ROOT: { value: "ROOT", text: "최고관리자", shows: false },
    ADMIN: { value: "ADMIN", text: "관리자", shows: false },
    PERSON: { value: "PERSON", text: "개인회원", shows: true },
    COMPANY: { value: "COMPANY", text: "기업회원", shows: true },
};

export const USER_LEVEL_SCHEDULE_ITEMS = {
    price: [
        { text: "구매없음", value: 0 },
        { text: "5만원이상", value: 50000 },
        { text: "10만원이상", value: 100000 },
        { text: "20만원이상", value: 200000 },
        { text: "30만원이상", value: 300000 },
        { text: "50만원이상", value: 500000 },
        { text: "100만원이상", value: 1000000 },
    ],
    count: [
        { text: "구매없음", value: 0 },
        { text: "구매1회이상", value: 1 },
        { text: "구매2회이상", value: 2 },
        { text: "구매3회이상", value: 3 },
        { text: "구매5회이상", value: 5 },
        { text: "구매10회이상", value: 10 },
        { text: "구매20회이상", value: 20 },
        { text: "구매30회이상", value: 30 },
        { text: "구매50회이상", value: 50 },
    ],
};

export const SHOP_CODE_TYPES = {
    PROMOTER: { value: "PROMOTER", text: "추천인코드" },
};

export const INQUIRY_PROMOTER_TYPES = {
    PROMOTER_APPROVAL: { value: "PROMOTER_APPROVAL", text: "정회원 전환요청" },
};

export const INQUIRY_STATES = {
    AWAITING: { value: "AWAITING", text: "대기" },
    APPROVED: { value: "APPROVED", text: "승인" },
    REJECTED: { value: "REJECTED", text: "반려" },
};

export const ORDER_STATUSES = {
    PURCHASE_INITIATED: { value: "PURCHASE_INITIATED", text: "주문생성완료" },
    PURCHASE_GENERATED: { value: "PURCHASE_GENERATED", text: "구매생성완료" },
    PURCHASE_CONFIRMED: { value: "PURCHASE_CONFIRMED", text: "구매확정완료" },
};

export const GRADE_CRITERIA_TYPES = {
    SCORE__ACCUMULATED: { value: "SCORE__ACCUMULATED", text: "직급포인트 (합산/누적)" },
    PURCHASE_AMOUNT__MONTH: { value: "PURCHASE_AMOUNT__MONTH", text: "구매확정금액 (회원본인)" },
};

export const GRADE_BENEFITS_TYPES = {
    BONUS__GOODS: { value: "BONUS__GOODS", text: "기본 보너스" },
    BONUS__GRADE: { value: "BONUS__GRADE", text: "직급 보너스" },
    BONUS__SALES: { value: "BONUS__SALES", text: "장려 보너스" },
};

export const SCORE_TYPES = {
    PROMOTEE__MANUAL_ALTERATION: { value: "PROMOTEE__MANUAL_ALTERATION", text: "관리자 수정 (추천회원)" },
    PROMOTEE__PURCHASE_COMPLETE: { value: "PROMOTEE__PURCHASE_COMPLETE", text: "추천회원 구매확정" },
    PROMOTER__MANUAL_ALTERATION: { value: "PROMOTER__MANUAL_ALTERATION", text: "관리자 수정 (회원본인)" },
    PROMOTER__PURCHASE_COMPLETE: { value: "PROMOTER__PURCHASE_COMPLETE", text: "회원본인 구매확정" },
    PROMOTER__REVIEW_WRITE_TEXT: { value: "PROMOTER__REVIEW_WRITE_TEXT", text: "회원본인 일반리뷰" },
    PROMOTER__REVIEW_UPLOAD_IMG: { value: "PROMOTER__REVIEW_UPLOAD_IMG", text: "회원본인 포토리뷰" },
};

export const BONUS_TYPES = {
    BONUS__ADMIN: { value: "BONUS__ADMIN", text: "보너스 수정" },
    BONUS__GOODS: { value: "BONUS__GOODS", text: "기본 보너스" },
    BONUS__GRADE: { value: "BONUS__GRADE", text: "직급 보너스" },
    BONUS__SALES: { value: "BONUS__SALES", text: "장려 보너스" },
    BONUS__DEBIT: { value: "BONUS__DEBIT", text: "출금내역" },
};

export const BONUS_TABS = {
    WHOLE: { value: "WHOLE", text: "전체", path: "bonuses" },
    GOODS: { value: "GOODS", text: "기본 보너스", path: "bonuses__goods" },
    GRADE: { value: "GRADE", text: "직급 보너스", path: "bonuses__grade" },
    SALES: { value: "SALES", text: "장려 보너스", path: "bonuses__sales" },
    DEBIT: { value: "DEBIT", text: "출금내역", path: "bonuses__debit" },
};

export const BONUS_WITHDRAW_TABS = {
    CONFIRM_BONUS_AMOUNT: { value: "CONFIRM_BONUS_AMOUNT", text: "금액확인" },
    CONFIRM_BANK_ACCOUNT: { value: "CONFIRM_BANK_ACCOUNT", text: "계좌확인" },
    VERIFY_USER_IDENTITY: { value: "VERIFY_USER_IDENTITY", text: "신원확인" },
    CONFIRM_DEPOSIT_DONE: { value: "CONFIRM_DEPOSIT_DONE", text: "결과확인" },
};

export const BANK_VERIFICATION_FORM_TABS = {
    INPUT: { value: "INPUT", text: "입력" },
    VERIFY: { value: "VERIFY", text: "인증" },
    SUCCESS: { value: "SUCCESS", text: "성공" },
};

export const EXHIBITION_GROUPS = {
    TAGGING_EVENTS: { value: "TAGGING_EVENTS", text: "태그" },
    PLANNED_SALES: { value: "PLANNED_SALES", text: "기획전" },
    POP_UP_EVENTS: { value: "POP_UP_EVENTS", text: "이벤트" },
};

export const EVENT_STATUS = {
    ALL: { value: null, text: "전체" },
    ONGOING: { value: "ONGOING", text: "진행중인" },
    ENDED: { value: "ENDED", text: "종료된" },
};

export const CONCIERGE_SERVICE_CATEGORIES = {
    PRODUCT_QUERY: { value: "PRODUCT_QUERY", text: "제품상담" },
    AFTER_SERVICE: { value: "AFTER_SERVICE", text: "A/S문의" },
    SHOWROOM_BOOK: { value: "SHOWROOM_BOOK", text: "쇼룸방문예약" },
    INSTALLATIONS: { value: "INSTALLATIONS", text: "설치문의" },
};

export const FAQ_TYPES = {
    MEMBERSHIP: { value: "MEMBERSHIP", text: "회원정보관련" },
    PURCHASING: { value: "PURCHASING", text: "주문/배송" },
    // DELIVERIES: { value: "DELIVERIES", text: "배송안내" },
    CANCELREFUND: { value: "CANCELREFUND", text: "주문취소/환불" },
    // CANCELPROD: { value: "CANCELPROD", text: "주문취소" },
    // REFUNDPROD: { value: "REFUNDPROD", text: "환불요청" },
    ABOUTPROD: { value: "ABOUTPROD", text: "상품관련" },
    COMMUNITY: { value: "COMMUNITY", text: "커뮤니티" },
    POINTEVENT: { value: "POINTEVENT", text: "포인트 및 이벤트" },
    AFTERSERVICE: { value: "AFTERSERVICE", text: "A/S 서비스" },
    OTHERQUERY: { value: "OTHERQUERY", text: "기타" },
};

export const BANNERS = {
    MAIN_1: {
        value: "MAIN_1",
        title: "섹션 1 - 슬라이드",
        code__pc: "MAIN_1__PC",
        code__mb: "MAIN_1__MB",
        sublabel__pc: "",
        sublabel__mb: "",
    },
    MAIN_2: {
        value: "MAIN_2",
        title: "섹션 2 - 태그 배너",
        code__pc: "MAIN_2__PC",
        code__mb: "MAIN_2__MB",
        sublabel__pc: "",
        sublabel__mb: "",
    },
};

export const FORM_STATES = {
    ANSWER_AWAITING: { value: "ANSWER_AWAITING", text: "답변대기" },
    ANSWER_COMPLETE: { value: "ANSWER_COMPLETE", text: "답변완료" },
};

export const DISPLAY_CODES = {
    NEW_ARRIVAL: { value: "NEW_ARRIVAL", text: "신상품" },
    BEST_SELLER: { value: "BEST_SELLER", text: "베스트 셀러" },
    POPULAR: { value: "POPULAR", text: "이달의 인기상품" },
    BEST_REVIEW: { value: "BEST_REVIEW", text: "베스트 리뷰", shows: false },
    RECOMMENDATION: { value: "RECOMMENDATION", text: "다른 고객이 같이 구매한 상품" },
};

export const USER_PAYMENT_STATUS = {
    PAY_AWAITING: { text: "결제대기", value: "PAY_AWAITING" },
    PAY_COMPLETED: { text: "결제완료", value: "PAY_COMPLETED" },
    PAY_CANCELED: { text: "결제취소", value: "PAY_CANCELED" },
}

export const NICEPAY_PURCAHSE_TYPES = {
    SHOP_ORDERS : { text: "상품구매", value: "shop-orders" },
    USER_PAYMENTS : { text: "개인결재", value: "user-payments" },
    SHOP_SERVICES : { text: "리퀘스트", value: "shop-service-reservations" },
}

export const MSG_TYPES = {
    SMS: { value: "SMS", text: "SMS" },
    LMS: { value: "LMS", text: "LMS" },
}

export const CATEGORY_DETAILS_TYPES = {
    HEAD: { text: "상단", value: "HEAD" },
    FOOT: { text: "하단", value: "FOOT" },
}