<template>
    <v-card v-if="tags" tile class="px-14px py-16px pa-lg-30px">
        <div class="tit-wrap tit-wrap--sm">
            <h3 class="tit tit--xs font-weight-medium">나의 추천태그</h3>
        </div>
        <v-row class="row--xxs txt txt--dark font-weight-light cursor-pointer">
            <v-col v-for="(tag, index) in tags" :key="index" cols="auto" @click="go(tag?._id)"># {{ tag?._id }}</v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import { COMMON_LOG_TYPES } from "@/assets/variables";
export default {
    data: () => ({
        tags: null,
    }),
    computed:{
        searches() {
            return this.$store.state.searches;
        },   
    },
    methods: {
        ...mapMutations(["setSearches"]),
        async search() {
            try {
                let { logs } = await api.v1.shop.logs.gets({
                    headers: { limit: 8 },
                    params: { type: COMMON_LOG_TYPES.TAG.value },
                });

                this.tags = logs;
                this.$emit("input");
            } finally {
                this.$emit("input");
            }
        },
        async go(tag){
            try {
                await api.v1.shop.logs.saving({
                    type: COMMON_LOG_TYPES.SEARCH.value,
                    value: tag,
                }); 
                if(this.searches.length > 4) this.searches.shift();
                this.setSearches({ searches: [...this.searches, tag ]});

                this.$router.push({ path: "/search", query: { 
                    searchValue: tag,
                }});
            } catch(error){ 
                this.$handleError(error);
            }
        }
    },
    mounted() {
        this.search();
    },
};
</script>

<style>
</style>