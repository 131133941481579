import initShopTaxinvoiceAddContactList from "./addContactList";
import initShopTaxinvoiceAmendment from "./amendment";
import initShopTaxinvoiceBase from "./base";
import initShopTaxinvoiceDetailList from "./detailList";
import initShopTaxinvoiceInformation from "./information";
import initShopTaxinvoiceInvoicee from "./invoicee";
import initShopTaxinvoiceInvoicer from "./invoicer";
import initShopTaxinvoiceTrustee from "./trustee";

/**
 * 공급자 데이터
 * @param {Taxinvoice} data
 * @returns {Taxinvoice}
 */
export const initShopTaxinvoice = (data = {}) => ({
    ...data,

    code: data?.code ?? null,

    _user: data?._user ?? null,

    addContactList: initShopTaxinvoiceAddContactList(data?.addContactList ?? []),

    amendment: initShopTaxinvoiceAmendment(data?.amendment),

    base: initShopTaxinvoiceBase(data?.base),

    detailList: initShopTaxinvoiceDetailList(data?.detailList ?? []),

    information: initShopTaxinvoiceInformation(data?.information),

    invoicee: initShopTaxinvoiceInvoicee(data?.invoicee),

    invoicer: initShopTaxinvoiceInvoicer(data?.invoicer),

    trustee: initShopTaxinvoiceTrustee(data?.trustee),

    isIssued: data?.isIssued ?? false,
});

/**
 * @typedef {object} Taxinvoice 공급자 데이터
 *
 * @property {string} code
 *
 * @property {string} _user
 *
 * @property {import("./addContactList").TaxinvoiceAddContactListData} addContactList
 *
 * @property {import("./amendment").TaxinvoiceAmendmentData} amendment
 *
 * @property {import("./base").TaxinvoiceBaseData} base
 *
 * @property {import("./detailList").TaxinvoiceDetailListData} detailList
 *
 * @property {import("./information").TaxinvoiceInformationData} information
 *
 * @property {import("./invoicee").TaxinvoiceInvoiceeData} invoicee
 *
 * @property {import("./invoicer").TaxinvoiceInvoicerData} invoicer
 *
 * @property {import("./trustee").TaxinvoiceTrusteeData} trustee
 *
 * @property {boolean} isIssued
 */
