export const ALARM_CATEGORIES = {
    PRODUCT: { text: "주문/배송", value: "PRODUCT" },
    COMMUNITY: { text: "커뮤니티", value: "COMMUNITY" },
    EVENT: { text: "이벤트/혜택", value: "EVENT" },
    POINT: { text: "마일리지", value: "POINT" },
    MONEY: { text: "정산", value: "MONEY" },
    ETC: { text: "기타", value: "ETC" },
    // NOTIFICATION: { text: "공지사항", value: "NOTIFICATION" },
};

export const ALARM_TYPES = {
    NOTI: { text: "플랫폼 알림", value: "NOTI" },
    TALK: { text: "알림톡", value: "TALK" },
};

export const ALARM_VARIABLES_USER = [
    { text: "#{user._id}", value: "사용자 식별 ID"},
    { text: "#{user.name}", value: "사용자 이름" },
    { text: "#{user.nickname}", value: "사용자 닉네임" },
    { text: "#{user.username}", value: "사용자 아이디" },
    { text: "#{user.phone}", value: "사용자 전화번호" },
    { text: "#{user.email}", value: "사용자 이메일" },
    { text: "#{user.postcode}", value: "사용자 우편번호" },
    { text: "#{user.address1}", value: "사용자 기본주소" },
    { text: "#{user.address2}", value: "사용자 상세주소" },
    { text: "#{user.point}", value: "사용자 마일리지" },
];

export const ALARM_VARIABLES_COMMUNITY =  [
    ...ALARM_VARIABLES_USER,
    { text: "#{from.name}", value: "피대상자 이름" },
    { text: "#{from.nickname}", value: "피대상자 닉네임" },
    { text: "#{from.username}", value: "피대상자 아이디" },
    { text: "#{from.phone}", value: "피대상자 전화번호" },
    { text: "#{from.email}", value: "피대상자 이메일" },
    { text: "#{from.postcode}", value: "피대상자 우편번호" },
    { text: "#{from.address1}", value: "피대상자 기본주소" },
    { text: "#{from.address2}", value: "피대상자 상세주소" },

    { text: "#{board._id}", value: "게시글 ID" },
    { text: "#{board.subject}", value: "게시글 제목" },
];

export const ALARM_VARIABLES_PRODUCT = [
    ...ALARM_VARIABLES_USER,
    { text: "#{order.purchases.product.name}", value: "주문 첫 상품명" },
    { text: "#{order.purchases.length}", value: "주문 첫 상품 개수" },
    { text: "#{order.purchases.decoded__productOptionName}", value: "첫 상품 옵션" },
    { text: "#{order.purchases.amount}", value: "첫 상품 수량" },
    { text: "#{order.purchases.shippingOption.method.name}", value: "첫 상품 배송방법" },

    { text: "#{order.sender.name}", value: "구매자 성함" },
    { text: "#{order.receiver.address1}", value: "수취인 기본주소" },
    { text: "#{order.receiver.address2}", value: "수취인 상세주소" },
    { text: "#{order.createdAt}", value: "주문일시" },
    
    { text: "#{purchase.product.name}", value: "구매 상품명" },
    { text: "#{purchase.decoded__productOptionName}", value: "구매 상품 옵션" },
    { text: "#{purchase.shippingOption.method.name}", value: "구매 상품 배송밥법" },
    { text: "#{purchase.delivery.name}", value: "구매 상품 택배사" },
    { text: "#{purchase.delivery.number}", value: "구매 상품 운송장번호" },
];

export const ALARM_VARIABLES_POINT = [
    ...ALARM_VARIABLES_USER,
    { text: "#{point.amount}", value: "마일리지 가격" },
];

export const ALARM_VARIABLES_MONEY = [
    ...ALARM_VARIABLES_USER,
    { text: "#{money.amount}", value: "정산액" },

    { text: "#{withdrawal.amount}", value: "예치금 출금액" },
];
export const ALARM_VARIABLES_EVENT = [
    ...ALARM_VARIABLES_USER,

    { text: "#{coupon.name}", value: "쿠폰 제목" },
    { text: "#{coupon.value}", value: "쿠폰 가격" },
    { text: "#{coupon.limit}", value: "쿠폰 최대 할인금액" },
    { text: "#{coupon.usage.target.price}", value: "적용범위" },
    { text: "#{coupon.usage.startsAt}", value: "쿠폰 사용 시작일" },
    { text: "#{coupon.usage.endsAt}", value: "쿠폰 사용 만료일" },
]

export const ALARM_TARGETS = {
    USER: { text: "고객", value: "USER" },
    SELLER: { text: "판매자", value: "SELLER" },
};