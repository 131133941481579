<template>
    <v-menu v-model="showsMenu" top offset-y v-bind="{ nudgeTop, nudgeLeft, rounded }" :close-on-content-click="false" content-class="map-overlay-menu">
        <template #activator="{ attrs, on }">
            <overlay-marker ref="map-overlay" v-bind="{ attrs, on, locationsCount: 1 }" />
        </template>

        <div class="map-overlay-menu__inner">
            <v-card elevation="6" rounded="xs" class="white pa-20px">
                <v-row class="ma-n4px">
                    <v-col cols="auto" class="pa-4px">
                        <v-avatar size="62">
                            <v-img v-if="item.thumb" :src="item.thumb.src"></v-img>
                            <v-img v-else src="/images/minishop/profile-default.png"></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col class="pa-4px txt">
                        <div class="txt--xs">{{ item.companyName }}</div>
                        <div class="txt--sm txt--dark text-truncate-2">{{ item.address1 }} {{ item.address2 }}</div>
                    </v-col>
                    <v-col cols="12" class="pa-4px">
                        <v-btn :to="`/minishop/${item._id}`" block outlined color="grey" class="v-size--xx-small">바로가기</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </v-menu>
</template>

<script>
import overlayMarker from "./overlay-marker.vue";

export default {
    components: { overlayMarker },
    props: {
        item: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            overlay: null,
            showsMenu: false,
        };
    },
    computed: {
        map() {
            return this.$parent.map;
        },
        content() {
            return this.$refs["map-overlay"]?.$el;
        },
        rounded() {
            return "lg";
        },
        nudgeTop() {
            // if (this.locationsCount > 1) return 102;
            // else return 70;
        },
        nudgeLeft() {
            return 86;
        },
        isOn() {
            return this.item?._id == this.$route.query?._location;
        },
    },
    watch: {
        lat() {
            this.init();
        },
        lng() {
            this.init();
        },
        address() {
            this.marking();
        },
        isOn(isOn) {
            if (isOn) this.$nextTick(() => setTimeout(() => (this.showsMenu = true), 500));
        },
    },
    destroyed() {
        this.overlay.setMap(null);
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const { lat, lng } = this.item;
            // 기존 오버레이 제거
            if (this.overlay) {
                this.overlay.setMap(null);
            }

            this.$nextTick(() => {
                const { content } = this;
                // 위도 & 경도로 오버레이 생성
                if (lat && lng) {
                    this.position = new kakao.maps.LatLng(lat, lng);
                    this.overlay = new kakao.maps.CustomOverlay({ position: this.position, content });
                }

                // 주소로 오버레이 생성
                else if (this.address) {
                    this.$parent.geocoder.addressSearch(this.address, (result, status) => {
                        // 정상적으로 검색이 완료됐으면
                        if (status === kakao.maps.services.Status.OK) {
                            this.position = new kakao.maps.LatLng(result[0].y, result[0].x);
                            this.overlay = new kakao.maps.CustomOverlay({ position: this.position, content });
                        }
                    });
                }

                this.overlay.setMap(this.map);
                if (this.isOn) this.$nextTick(() => setTimeout(() => (this.showsMenu = true), 500));
            });
        },
    },
};
</script>

<style scoped>
.map-overlay-menu {
    width: 290px;
    contain: none;
    will-change: auto;
    padding-bottom: 20px;
    overflow: visible;
    box-shadow: none !important;
    background: transparent;
    border: none;
    transform: translate(-35px, -2px);
}
.map-overlay-menu::before {
    content: "";
    position: absolute;
    bottom: 8px;
    left: 50%;
    z-index: 1;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    display: block;
    border-top: 12px solid #fff;
    border-bottom: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}
.map-overlay-menu__inner {
    height: 142px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(17, 41, 81, 0.2) !important;
}
.map-overlay-menu__inner > .v-card,
.map-overlay-menu__inner > .v-list {
    height: 100%;
}
</style>
