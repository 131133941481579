<template>
    <v-col cols="12" lg="6">
        <div id="menu-wrap">
            <v-row justify="space-between">
                <v-col cols="auto">"{{ this.$route.query.searchValue || "내 위치"}}"에서 가까운 매장</v-col>
                <v-col cols="auto">
                    <div class="d-flex ma-n2px">
                        <v-btn :disabled="hasPrev" x-small color="primary" fab rounded-pill class="ma-2px" @click="paging('prev')">
                            <v-icon class="white--text">chevron_left</v-icon>
                        </v-btn>
                        <v-btn :disabled="!hasNext" x-small color="primary" fab rounded-pill class="ma-2px" @click="paging('next')">
                            <v-icon class="white--text">chevron_right</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <ul id="placeList">
                <li v-for="(location, index) in items" :key="index" class="item" @click="toggleItem(location)">
                    <h5 class="tit tit--xxs">{{ location?.nickname }}</h5>
                    <span class="txt txt--xs txt--dark">{{ location?.address1 }} {{ location?.address2 }}</span>
                </li>
            </ul>
        </div>
    </v-col>
</template>

<script>
import api from "@/api";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
    components: {},
    data: () => ({
        page: 1,
        limit: 4,
        totalCount: 0,

        locations: [],
    }),
    computed: {
        ...mapState("location", ["bounds"]),
        ...mapGetters("location", ["geolocation"]),
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        coordinates() {
            const { lat, lng } = this.$route.query;
            return `${lat}${lng}`;
        },
        lat() {
            return this.$route.query.lat;
        },
        lng() {
            return this.$route.query.lng;
        },
        hasPrev() {
            return this.page <= 1;
        },
        hasNext() {
            return this.page < this.pageCount;
        },
        items(){
            return this.locations.slice(this.skip, this.skip+this.limit)
        }
    },
    methods: {
        ...mapMutations("location", ["setCoordinates"]),
        init() {
            this.search();
        },
        async search() {
            try {
                let { geolocation: near, bounds }= this;
                const headers = { };
                const params = { };
                if(!near || !bounds.w || !bounds.s || !bounds.e || !bounds.n){
                    return;
                }
                params.near = near;
                params.bounds = bounds;
                
                let { summary, stores } = await api.v1.shop.stores.gets({ headers, params });
                this.locations = stores;
                this.totalCount = summary.totalCount;
            } catch (error) {
                this.$handleError(error);
            }
        },
        toggleItem(location) {
            const { coordinates } = location?.geolocation;
            this.$router.push({ query: { ...this.$route.query, lat: coordinates[1], lng: coordinates[0], _location: location._id }});
        },
        paging(type) {
            if (type === "prev") {
                this.page--;
                this.skip -= this.limit;
            }
            if (type === "next") {
                this.page++;
                this.skip += this.limit;
            }
        },
    },
    mounted() {
        this.$nextTick(()=>{this.search()});
    },
    watch:{
        coordinates(){
            if(!this.$route.query._location) this.search();
        },
    }
};
</script>

<style lang="scss" scoped>
#placeList {
    .item {
        padding: 20px 0;
        border-bottom: 1px solid var(--border-color);
    }
}
</style>