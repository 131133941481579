<template>
    <div class="pb-32px">
        <v-divider dark class="mb-20px" />
        <v-row no-gutters justify="space-between">
            <v-col cols="auto">
                <div class="txt txt--sm">{{ title }} 검색 결과 <span class="txt--dark font-weight-bold">{{ totalCount }}개</span></div>
            </v-col>
            <v-col cols="auto">
                <div class="txt txt--sm txt--dark font-weight-bold cursor-pointer" @click="go">더보기</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { SEARCH_LIST } from "./search-bar.vue";

export default {
    props: {
        totalCount: { type: Number, default: 0 },
        mode: { type: String, default: SEARCH_LIST.PRODUCT.value },
    },
    computed: {
        title() {
            return SEARCH_LIST[this.mode].text;
        },
    },
    methods: {
        go() {
            this.$router.push({
                query: {
                    mode: this.mode,
                    searchValue: this.$route.query.searchValue,
                },
            });
        },
    },
};
</script>

<style>
</style>