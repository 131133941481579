<template>
    <v-card tile flat @click="go()">
        <v-img eager :src="value?.thumbSrc" @load="$emit('load')" />
        <v-card tile class="px-14px py-16px pa-lg-30px">
            <div class="tit-wrap tit-wrap--sm">
                <h3 class="tit tit--xs font-weight-medium">{{ value.subject }}</h3>
            </div>
            <p class="txt txt--dark font-weight-light">{{ value.content }}</p>

            <ul class="product-list">
                <template v-for="product in value?.products || []">
                    <li class="product-list__item" :key="product._id" @click.prevent.stop="go(product)">
                        <!-- <router-link :to="`/shop/products/${product._id}`"> -->
                            <v-row class="row--xxs">
                                <v-col cols="auto">
                                    <v-img :src="product?.thumb?.src" width="90" :aspect-ratio="90 / 60" @load="$emit('load')">
                                        <template #placeholder>
                                            <v-overlay absolute>
                                                <v-icon> mdi-image-broken-variant </v-icon>
                                            </v-overlay>
                                        </template>
                                    </v-img>
                                </v-col>
                                <v-col class="overflow-hidden">
                                    <div class="h-100 d-flex flex-column justify-space-between py-4px font-size-14">
                                        <h4 class="font-weight-medium text-truncate">
                                            <span> {{ product?.name }} </span>
                                        </h4>
                                        <p class="accent1--text font-weight-bold">
                                            <span> {{ product?.salePrice?.format() }}원 </span>
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                        <!-- </router-link> -->
                    </li>
                </template>
            </ul>
        </v-card>
    </v-card>
</template>

<script>
import api from "@/api";
import { initStoryBoardDisplay } from "@/store/story";

export default {
    props: {
        value: { type: Object, default: initStoryBoardDisplay },
    },
    methods: {
        async go(product) {
            try {
                await api.v1.bannerLogs.post({ _storyCard: this.value._id });

                if(product) {
                    this.$router.push(`/shop/products/${product._id}`);
                } else {
                    this.$router.push(`./story/${this.value?.board?.code}/${this.value?.board?._id}`);
                };
            } catch(error) {
                this.$handleError(error);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.product-list {
    border-top: 1px solid var(--border-color);
    margin-top: 10px;
    &__item {
        border-bottom: 1px solid var(--border-color);
        padding: 14px 0;
    }
    max-height: 268px;
    overflow-x: hidden;
    overflow-y: auto;   

    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #696969;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(0,0,0,0.1);
    }
}
</style>