import axios from "@/plugins/axios";

let url = "/api/console/banner-slides";

export default {
    post(data) {
        return axios.post(`${url}`, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
    images: {
        post(_slide, index, file) {
            var formData = new FormData();
            formData.append("image", file);
            var headers = { "Content-Type": "multipart/formdata" };
            return axios
                .post(`${url}/${_slide}/images/${index}`, formData, { headers })
                .then((result) => result.data);
        },
        delete(_banner, index) {
            return axios
                .delete(`${url}/${_slide}/images/${index}`)
                .then((result) => result.data);
        },
    },
};
