import { PRODUCT_TYPES, PRODUCT_OPTION_CRITERIA_TYPES, PRODUCT_OPTION_SORTS } from "../constants";
import { initShopProductRelated } from "./shop-product-relateds.inits";

export const initProduct = (product = {}) => {
    if (product.optionsConf?.enabled) {
        product.stock = (product.options || []).reduce((stock, option) => stock + (option?.stock || 0), 0);
    }

    return {
        ...product,

        _brand: product._brand || null,
        _category: product._category || null,

        _thumb: product._thumb || null,
        _images: [...(product._images || [])],
        images: [...(product.images || [])],

        _options: [...(product._options || [])],
        _supplies: [...(product._supplies || [])],
        _relateds: [...(product._relateds || [])],
        _bundleds: [...(product._bundleds || [])],

        type: product.type ?? PRODUCT_TYPES.NORMAL_PRODUCT.value,
        code: product.code || null,
        name: product.name || null,
        content: product.content || null,

        // memo: product.memo ?? null,
        // sellerMemo: product.sellerMemo ?? null,
        cost: product.cost ?? 0,
        price: product.price ?? 0,
        stock: product.stock ?? 0,

        size: product.size || null,
        origin: product.origin || null,
        manufacturer: product.manufacturer || null,

        shows: product.shows ?? true,
        sells: product.sells ?? true,

        index: product.index || null,
        sequence: product.sequence || 0,

        link: {
            ...(product.link || {}),
            url: product.link?.url || null,
            name: product.link?.name || null,
            enabled: product.link?.enabled || false,
            isOuter: product.link?.isOuter || false,
        },

        flags: [...(product.flags || [])],
        groups: [...(product.groups || [])],
        hashtags: [...(product.hashtags || [])],
        relatedGroupCode: product.relatedGroupCode || null,

        options: (product.options || []).map(initProductOption),
        optionsConf: initProductOptionsConf(product.optionsConf),

        choicees: (product?.choicees || []).map(initShopProductRelated),
        choiceesConf: {
            ...(product.choiceesConf || {}),
            enabled: product.choiceesConf?.enabled || false,
        },

        relateds: (product?.relateds || []).map(initShopProductRelated),
        relatedsConf: {
            ...(product.relatedsConf || {}),
            enabled: product.relatedsConf?.enabled || false,
        },

        variation: {
            ...(product?.variation || {}),
            code: product.variation?.code || null,
            list: [...(product.variation?.list || [])],
            spec: [...(product.variation?.spec || [""])],

            enabled: product.variation?.enabled || false,
        },

        bundleds: (product?.bundleds || []).map(initShopProductRelated),

        seo: {
            ...(product?.seo || {}),

            title: product?.seo?.title || null,
            meta: [
                ...(product?.seo?.meta || [
                    { name: "author", content: null },
                    { name: "description", content: null },
                    { name: "keywords", content: null },
                ]),
            ],
        },
    };
};

export const initProductOption = (option = {}) => ({
    ...option,
    hexa: option.hexa || null,
    name: option.name || null,
    value: option.value || null,
    price: option.price || 0,
    stock: option.stock || 0,
    enabled: option.enabled,

    tempId: option.tempId || Math.random(),
    tempId__color: option.tempId__color || null,
});

export const initProductOptionsConf = (conf = {}) => ({
    ...(conf || {}),
    enabled: conf?.enabled || false,
    sort: conf?.sort || PRODUCT_OPTION_SORTS.LATEST.value,
    criteria: (conf?.criteria || []).map(initProductOptionCriterion),
});

export const initProductOptionCriterion = (criterion = {}) => {
    return {
        ...criterion,

        type: criterion.type || PRODUCT_OPTION_CRITERIA_TYPES.OTHER.value,
        name: criterion.name || null,
        values: (criterion.values || []).map(initProductOptionCriterionValue),

        tempId: criterion.tempId || Math.random(),
    };
};

export const initProductOptionCriterionValue = (value = {}) => ({
    ...value,
    img: value.img || null,
    name: value.name || null,
    hexa: value.hexa || null,

    tempId: value.tempId || Math.random(),
});
