import Vue, { nextTick } from "vue";
import Vuex from "vuex";

import api from "@/api";
import axios from "@/plugins/axios";
import dayjs from "@/plugins/vue-dayjs";
import CookieStorage from "@/plugins/cookie-storage";

import { DISPLAY_CODES } from "@/assets/variables/constants";
import { initSetting, initSettingShopDisplayCode } from "@/assets/variables/inits";

import bandBanners from "./band-banners";
// import enlipleTracker from "./enliple-tracker";
import location from "./location";
// import metaPixel from "./meta-pixel";
import naverWcs from "./naver-wcs";
import story from "./story";
import ui from "./ui";

Vue.use(Vuex);

const cookieStorage = new CookieStorage();

var refreshToken = cookieStorage.getItem("refreshToken");
var accessToken = cookieStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
var scope = payload?.scope || [];

var carts = JSON.parse(cookieStorage.getItem("carts") || "[]");
var pickup = JSON.parse(cookieStorage.getItem("pickup") || "[]");
var watches = JSON.parse(cookieStorage.getItem("watches") || "[]");

var agreements = JSON.parse(cookieStorage.getItem("agreements") || "[]");

export default new Vuex.Store({
    state: {
        // user
        refreshToken,
        accessToken,
        payload,
        scope,
        user: undefined,
        // followings: [],
        notiCount: 0,

        // common
        setting: initSetting(),
        likes: undefined,
        searches: [],

        // shop
        carts,
        pickup,
        watches,
        islands: [],
        categories: [],
        areCategoriesLoaded: false,

        // join
        agreements,

        // ui
        main: { alert: true },
        noti: { shows: false },
    },
    mutations: {
        // user
        login(state, { refreshToken, accessToken }) {
            cookieStorage.setItem("refreshToken", refreshToken);
            cookieStorage.setItem("accessToken", accessToken);

            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

            nextTick(() => {
                state.refreshToken = refreshToken;
                state.accessToken = accessToken;

                state.payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
                state.scope = state.payload?.scope || [];
                state.likes = [];
            });
        },
        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];
            state.user = undefined;
            state.notiCount = 0;
            state.noti.shows = false;
            state.likes = undefined;
            state.carts = [];
            state.pickup = [];
            state.watches = [];
            state.agreements = [];

            nextTick(() => {
                axios.defaults.headers.common["Authorization"] = "";

                cookieStorage.removeItem("refreshToken");
                cookieStorage.removeItem("accessToken");
                cookieStorage.removeItem("carts");
                cookieStorage.removeItem("pickup");
                cookieStorage.removeItem("watches");
                cookieStorage.removeItem("agreements");
            });
        },
        setUser(state, { user }) {
            state.user = user;
        },
        setNotiCount(state, count) {
            state.notiCount = count;
        },

        // setFollowings(state, { follows }) {
        //     state.followings = follows;
        // },

        // common
        setSetting(state, { setting }) {
            state.setting = initSetting(setting);
        },
        setLikes(state, { likes }) {
            state.likes = likes;
        },
        setSearches(state, { searches }) {
            state.searches = searches;
            window.$cookies.set("searches", JSON.stringify(state.searches), 60 * 60 * 24);
        },

        // shop
        setCarts(state, { carts }) {
            state.carts = carts;
            cookieStorage.setItem("carts", JSON.stringify(state.carts));
        },
        addToCart(state, carts) {
            state.carts = [].concat(state.carts, carts);
            cookieStorage.setItem("carts", JSON.stringify(state.carts));
        },
        pickup(state, _carts) {
            state.pickup = _carts;
            cookieStorage.setItem("pickup", JSON.stringify(_carts));
        },
        setWatches(state, { watches }) {
            state.watches = watches;
            cookieStorage.setItem("watches", JSON.stringify(state.watches));
        },
        addToWatch(state, watches) {
            for (const watch of watches) {
                const index = state.watches.findIndex(({ _id }) => _id == watch._id);
                if (-1 < index) state.watches[index] = watch;
                else state.watches = [watch, ...state.watches];
                [].sort;
                state.watches = state.watches.sort((a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 0));
            }
            cookieStorage.setItem("watches", JSON.stringify(state.watches));
        },
        setIslands(state, { islands }) {
            state.islands = islands;
        },
        setCategories(state, { categories }) {
            state.categories = categories.reduce(
                function reduceCategory({ visual, categories = [] }, category) {
                    categories.push({
                        ...category,
                        visual: category.visual || visual,
                        children: category.children.reduce(reduceCategory, { visual: category.visual || visual, categories: [] }).categories,
                    });
                    return { visual, categories };
                },
                { visual: null, categories: [] }
            ).categories;
            state.areCategoriesLoaded = true;
        },

        //join
        agreements(state, agreements) {
            state.agreements = agreements;
            cookieStorage.setItem("agreements", JSON.stringify(agreements));
        },

        // ui
        hideMainAlert(state) {
            state.main.alert = false;
        },
        setNotiShows(state, shows) {
            state.noti.shows = shows;
        },
    },
    actions: {
        // user
        async login({ dispatch, commit, state }, { username, password, accessToken }) {
            if (accessToken) commit("login", { accessToken });
            else {
                const { refreshToken } = await api.auth.getRefreshToken({ username, password });
                const { accessToken } = await api.auth.getAccessToken(refreshToken);
                commit("login", { refreshToken, accessToken });
            }
            setTimeout(async () => {
                await Promise.all(state.carts.map(async (cart) => await api.v1.me.carts.put(cart)));
                await Promise.all(state.watches.map(async (cart) => await api.v1.me.watches.put(cart)));
            }, 100);

            dispatch("getUser");
            dispatch("getCarts");
            dispatch("getWatches");
        },
        async refresh({ commit }, refreshToken) {
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });

            return accessToken;
        },
        logout({ commit }) {
            commit("logout");
        },
        async getUser({ commit }) {
            const { user } = await api.v1.me.get();
            commit("setUser", { user });
        },

        // common
        async getSetting__v1({ commit }) {
            const { setting } = await api.v1.setting.get();
            commit("setSetting", { setting });
        },
        async getSetting__console({ commit }) {
            const { setting } = await api.console.setting.get();
            commit("setSetting", { setting });
        },
        async getLikes({ commit }) {
            const { likes } = await api.v1.me.likes.gets();
            commit("setLikes", { likes });
        },
        async getSearches({ commit }) {
            const searches = window.$cookies.get("searches") || [];
            commit("setSearches", { searches });
        },

        // shop
        addToCart({ commit }, carts) {
            commit("addToCart", carts);
        },
        pickup({ commit }, _carts) {
            commit("pickup", _carts);
        },
        addToWatch({ commit }, watches) {
            commit("addToWatch", watches);
        },
        async getCarts({ commit }) {
            const { carts } = await api.v1.me.carts.gets();
            commit("setCarts", { carts });
        },
        async getWatches({ commit }) {
            const { watches } = await api.v1.me.watches.gets();
            commit("setWatches", { watches });
        },
        async getIslands({ commit }) {
            const { islands } = await api.v1.shop.islands.gets();
            commit("setIslands", { islands });
        },
        async getCategories({ commit }) {
            const { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
            commit("setCategories", { categories });
        },

        //join
        agreements({ commit }, _terms) {
            commit("agreements", _terms);
        },
    },
    getters: {
        displayCodes(state) {
            const items = state.setting.shop.displayCodes.map(initSettingShopDisplayCode);

            // items.push(...[...Object.values(DISPLAY_CODES).filter(({ value }) => !items.some((item) => item.value == value))].map(initSettingShopDisplayCode));
            items.push(...[...[DISPLAY_CODES.RECOMMENDATION].filter(({ value }) => !items.some((item) => item.value == value))].map(initSettingShopDisplayCode));

            return items;
        },

        userLevel(state) {
            return state.user?.level;
        },

        hasCompanyScope: (state) => (state.scope || []).includes("company"),

        hasConsoleScope: (state) => (state.scope || []).includes("console"),

        isUserUXI: (state) => state.user?.username == "uxidev",
    },
    modules: {
        bandBanners,
        // enlipleTracker,
        location,
        // metaPixel,
        naverWcs,
        story,
        ui,
    },
});
