var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    attrs: {
      "id": "menu-wrap"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("\"" + _vm._s(this.$route.query.searchValue || "내 위치") + "\"에서 가까운 매장")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex ma-n2px"
  }, [_c('v-btn', {
    staticClass: "ma-2px",
    attrs: {
      "disabled": _vm.hasPrev,
      "x-small": "",
      "color": "primary",
      "fab": "",
      "rounded-pill": ""
    },
    on: {
      "click": function ($event) {
        return _vm.paging('prev');
      }
    }
  }, [_c('v-icon', {
    staticClass: "white--text"
  }, [_vm._v("chevron_left")])], 1), _c('v-btn', {
    staticClass: "ma-2px",
    attrs: {
      "disabled": !_vm.hasNext,
      "x-small": "",
      "color": "primary",
      "fab": "",
      "rounded-pill": ""
    },
    on: {
      "click": function ($event) {
        return _vm.paging('next');
      }
    }
  }, [_c('v-icon', {
    staticClass: "white--text"
  }, [_vm._v("chevron_right")])], 1)], 1)])], 1), _c('ul', {
    attrs: {
      "id": "placeList"
    }
  }, _vm._l(_vm.items, function (location, index) {
    return _c('li', {
      key: index,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.toggleItem(location);
        }
      }
    }, [_c('h5', {
      staticClass: "tit tit--xxs"
    }, [_vm._v(_vm._s(location === null || location === void 0 ? void 0 : location.nickname))]), _c('span', {
      staticClass: "txt txt--xs txt--dark"
    }, [_vm._v(_vm._s(location === null || location === void 0 ? void 0 : location.address1) + " " + _vm._s(location === null || location === void 0 ? void 0 : location.address2))])]);
  }), 0)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }