var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pt-18px pt-lg-46px",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('h3', {
    staticClass: "tit font-weight-light"
  }, [_vm._v("검색어와 일치하는 내용이 없습니다.")])]), _c('div', {
    staticClass: "txt txt--dark font-weight-medium mb-16px"
  }, [_vm._v("검색 TIP")]), _c('div', {
    staticClass: "txt"
  }, [_c('p', {
    staticClass: "dot"
  }, [_vm._v("단어의 철자가 정확한지 확인해주세요.")]), _c('p', {
    staticClass: "dot"
  }, [_vm._v("검색어의 단어수를 줄이거나 다른 검색어로 검색해보세요.")]), _c('p', {
    staticClass: "dot"
  }, [_vm._v("보다 일반적인 단어로 다시 검색해 보세요.")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }