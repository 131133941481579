<template>
    <div class="pb-32px">
        <search-list-template v-if="!mode" v-bind="{ totalCount: summary.totalCount, mode: SEARCH_LIST.USER.value }"/>
        <v-row class="row--sm">
            <v-col v-for="(user, index) in users" :key="index" cols="12" md="12">
                <search-user-follow v-bind="{ item: user }"/>
            </v-col>
        </v-row>
        <div v-if="mode" class="pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { SEARCH_LIST } from '../search-bar.vue';
import SearchListTemplate from "../search-list-template.vue";
import SearchUserFollow from './search-user-follow.vue';
export default {
    components:{
        SearchListTemplate,
        SearchUserFollow
    },
    data: () => ({
        SEARCH_LIST,
        users: [],
        summary: {
            totalCount: 0
        },
        loading: false,
    }),
    computed: {
        items() {
            return [...this.users];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
        mode() {
            return this.$route.query.mode;
        },
        limit() {
            if(this.mode) return 15;
            else return 5;
        }
    },
    methods: {
        async search() {
            if(this.loading) return;
            else this.loading = true;
            try{
                let { skip, limit }= this;
                let { searchValue } = this.params;
                let { summary, users } = await api.v1.users.gets({
                    headers: { skip, limit },
                    params: { searchKey: "nickname", searchValue }
                });
                this.summary = summary;
                this.users = users;
                this.$emit("input", { type: "usersCount", totalCount: summary.totalCount });
                this.loading = false;
            } catch(error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        }
    },
    mounted(){
        this.search();
    },
    watch: {
        "$route.query"(){
            this.search();
        }
    }

}
</script>

<style>

</style>