var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticClass: "main-notification-drawer",
    attrs: {
      "value": _vm.$store.state.noti.shows,
      "fixed": "",
      "right": "",
      "touchless": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "height": "100vh",
      "color": _vm.option ? 'grey lighten-5' : 'white'
    }
  }, [_c('div', {
    staticClass: "px-18px px-lg-24px pt-lg-36px pb-lg-18px white"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.setNotiShows(!_vm.noti.shows);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-chevron-left")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "tit tit--xs font-weight-regular"
  }, [_vm._v("알림")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.option = !_vm.option;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-cog-outline")])], 1)], 1)], 1)], 1), !_vm.option ? _c('main-notification-list') : _c('main-notification-options')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }