var render = function render(){
  var _vm$value, _vm$value2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": "",
      "flat": ""
    },
    on: {
      "click": function ($event) {
        return _vm.go();
      }
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "src": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.thumbSrc
    },
    on: {
      "load": function ($event) {
        return _vm.$emit('load');
      }
    }
  }), _c('v-card', {
    staticClass: "px-14px py-16px pa-lg-30px",
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs font-weight-medium"
  }, [_vm._v(_vm._s(_vm.value.subject))])]), _c('p', {
    staticClass: "txt txt--dark font-weight-light"
  }, [_vm._v(_vm._s(_vm.value.content))]), _c('ul', {
    staticClass: "product-list"
  }, [_vm._l(((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.products) || [], function (product) {
    var _product$thumb, _product$salePrice;
    return [_c('li', {
      key: product._id,
      staticClass: "product-list__item",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.go(product);
        }
      }
    }, [_c('v-row', {
      staticClass: "row--xxs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "src": product === null || product === void 0 ? void 0 : (_product$thumb = product.thumb) === null || _product$thumb === void 0 ? void 0 : _product$thumb.src,
        "width": "90",
        "aspect-ratio": 90 / 60
      },
      on: {
        "load": function ($event) {
          return _vm.$emit('load');
        }
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": ""
            }
          }, [_c('v-icon', [_vm._v(" mdi-image-broken-variant ")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1), _c('v-col', {
      staticClass: "overflow-hidden"
    }, [_c('div', {
      staticClass: "h-100 d-flex flex-column justify-space-between py-4px font-size-14"
    }, [_c('h4', {
      staticClass: "font-weight-medium text-truncate"
    }, [_c('span', [_vm._v(" " + _vm._s(product === null || product === void 0 ? void 0 : product.name) + " ")])]), _c('p', {
      staticClass: "accent1--text font-weight-bold"
    }, [_c('span', [_vm._v(" " + _vm._s(product === null || product === void 0 ? void 0 : (_product$salePrice = product.salePrice) === null || _product$salePrice === void 0 ? void 0 : _product$salePrice.format()) + "원 ")])])])])], 1)], 1)];
  })], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }