var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "border-b pb-24px pb-md-32px",
    attrs: {
      "tile": "",
      "to": `./minishop/${_vm.item._id}`
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    staticClass: "border"
  }, [_vm.item.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.item.thumb.url
    }
  }) : _c('v-img', {
    attrs: {
      "src": "/images/minishop/profile-default.png"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "txt txt--dark font-weight-medium",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.nickname) + " ")]), _c('v-spacer', {
    staticClass: "d-lg-none"
  }), _c('v-col', {
    attrs: {
      "cols": "auto pl-lg-60px"
    }
  }, [_vm.item._id !== _vm._user ? _c('follow-btn', {
    attrs: {
      "params": {
        _follower: _vm.item._id,
        followings: _vm.follows
      }
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var active = _ref.active,
          toggle = _ref.toggle,
          loading = _ref.loading;
        return [!active ? _c('v-btn', _vm._b({
          staticClass: "product-item__favorite v-btn--width-fixed",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return toggle.apply(null, arguments);
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_quaternary, {
          loading
        }), false), [_vm._v("팔로우")]) : _c('v-btn', _vm._b({
          staticClass: "v-btn--width-fixed",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return toggle.apply(null, arguments);
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary, {
          loading
        }), false), [_vm._v(" 팔로우 취소 ")])];
      }
    }], null, false, 3640369978)
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }