import axios from "@/plugins/axios";

let url = (data) => "/api/v1/boards/${_board}/files".interpolate(data);

export default {
    // gets(data) {
    //     return axios.get(url.interpolate(data), data).then((result) => result.data);
    // },
    // get(data) {
    //     return axios.get(`${url.interpolate(data)}/${data._id}`).then((result) => result.data);
    // },
    post({ _board, index }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        if (_board) formData.append("_board", _board);
        if (index !== undefined) formData.append("index", index);
        formData.append("file", file);
        return axios.post(`${url({ _board })}`, formData, { headers }).then((result) => result.data);
    },
    // put(data) {
    //     return axios.put(`${url.interpolate(data)}/${data._id}`, data).then((result) => result.data);
    // },
    delete(data) {
        return axios.delete(`${url(data)}/${data._id}`).then((result) => result.data);
    },
};
