import axios from "@/plugins/axios";

let url = "/api/v1/me/follows";

export default{
    getsByType(data){
        return axios.get(`${url}/type`, data).then(result => result.data);
    },
    
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}