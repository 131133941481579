var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm._t("bandBanner"), _c('main-header', _vm._b({}, 'main-header', {
    disableScrollTop: _vm.disableScrollTop
  }, false)), _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_vm._t("subHead"), _vm._t("contentsImmersive"), _c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_vm._t("default")], 2)], 2), _c('aside-button-group'), _c('aside-button-product'), _c('aside-button-top'), _c('main-footer'), _c('main-notification-drawer')], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }