export function initShopShippingMethod(method = {}) {
    return {
        ...method,

        _seller: method._seller ?? null,
        seller: method.seller ?? null,

        name: method.name ?? null,
        code: method.code ?? null,
        index: method.index ?? null,

        isDefault: method.isDefault ?? false,
        isInitial: method.isInitial ?? false,

        // FE temp data
        tempId: method.tempId ?? Math.random(),
        disabled: method.isInitial ?? false,
    };
}
