import { SHIPPING_OPTION_BUNDLE_TYPES, SHIPPING_OPTION_CHARGE_TYPES } from "../constants";

export function initShopShippingOption(option = {}) {
    return {
        ...option,

        _seller: option._seller ?? null,
        seller: option.seller ?? null,

        _region: option._region ?? null,
        region: option.region ?? null,

        _method: option._method ?? null,
        method: option.method ?? null,

        name: option.name ?? null,

        bundle: {
            ...(option.bundle || {}),

            type: option.bundle?.type || SHIPPING_OPTION_BUNDLE_TYPES.APIECE.value,
        },

        charge: {
            ...(option.charge || {}),

            type: option.charge?.type || SHIPPING_OPTION_CHARGE_TYPES.BY_FIXED_AMOUNT.value,

            fixedAmount: option.charge?.fixedAmount ?? 0,

            pricesRange: [...(option.charge?.pricesRange || [])].map(initShopShippingOption__pricesRangeItem),

            travelRange: [...(option.charge?.travelRange || [])].map(initShopShippingOption__travelRangeItem),
        },

        island: {
            ...(option.island || {}),

            charge: {
                ...(option.island?.charge || {}),
                amount__base: option.island?.charge?.amount__base ?? 0,
                amount__jeju: option.island?.charge?.amount__jeju ?? 0,
            },

            isActive: option.island?.isActive ?? false,
        },

        service: {
            ...(option.service || {}),

            charge: {
                ...(option.service?.charge || {}),
                amount: option.service?.charge?.amount ?? 0,
            },

            isActive: option.service?.isActive ?? false,
        },

        areas__available: [...(option.areas__available || [])],

        // FE temp data
        tempId: option.tempId ?? Math.random(),
    };
}

export function initShopShippingOption__pricesRangeItem(item = {}) {
    return {
        ...item,
        price: item.price ?? 0,
        amount: item.amount ?? 0,

        // FE temp data
        tempId: item.tempId ?? Math.random(),
    };
}

export function initShopShippingOption__travelRangeItem(item = {}) {
    return {
        ...item,
        distance: item.distance ?? 0,
        amount: item.amount ?? 0,

        // FE temp data
        tempId: item.tempId ?? Math.random(),
    };
}
