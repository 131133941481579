<template>
    <div class="pb-32px">
        <search-list-template v-if="!mode" v-bind="{ totalCount: summary.totalCount, mode: SEARCH_LIST.PRODUCT.value }" />
        <shop-product-items>
            <shop-product-item v-for="(product, index) in products" :key="index" :value="product" sm="6" md="6" lg="3" />
        </shop-product-items>

        <div v-if="mode" class="pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { SEARCH_LIST } from "../search-bar.vue";
import SearchListTemplate from "../search-list-template.vue";
import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
export default {
    components: {
        SearchListTemplate,
        ShopProductItems,
        ShopProductItem,
    },
    data: () => ({
        SEARCH_LIST,
        products: [],
        summary: {
            totalCount: 0,
        },
        loading: false,
    }),
    computed: {
        items() {
            return [...this.products];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
        mode() {
            return this.$route.query.mode;
        },
        limit() {
            if (this.mode) return 12;
            else return 4;
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { skip, limit } = this;
                let { searchValue } = this.params;
                let { summary, products } = await api.v1.shop.products.gets({
                    headers: { skip, limit },
                    params: { searchValue },
                });
                // let { summary, products } = await api.v1.shop.products.search({
                //     headers: { skip, limit },
                //     params: { searchValue },
                // });
                this.summary = summary;
                this.products = products;
                this.$emit("input", { type: "productsCount", totalCount: summary.totalCount });
                this.loading = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        "$route.query"() {
            this.search();
        },
    },
};
</script>

<style>
</style>