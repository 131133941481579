import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import axios from "@/plugins/axios";
import CookieStorage from "@/plugins/cookie-storage";

import Main from "@/pages/client/Main.vue";
import { BoardRoutes } from "./board.router";
import { EVENT_STATUS } from "@/assets/variables";

Vue.use(VueRouter);

const cookieStorage = new CookieStorage();

/**
 * @type {RouteConfig[]}
 */
const routes = [
    ////////////////////////////////////////////////////////////////////////////
    // 공통 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // 가이드
        path: "/guide",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/Guide.vue"),
        props: true,
    },
    {
        // 테스트
        path: "/test",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/TestPage.vue"),
        props: true,
    },
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        // Terms 페이지
        path: "/terms/:code",
        component: () => import(/* webpackChunkName: "terms" */ "../pages/client/terms/TermsView.vue"),
        props: true,
    },
    {
        // VR Showroom
        path: "/VR-showroom",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/sub/VRShowroomPage.vue"),
        props: true,
    },
    {
        // 3D Interir
        path: "/3d-interir",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/sub/3DInterirPage.vue"),
        props: true,
    },
    {
        // 문의 및 제휴
        path: "/form/inquiry-partner",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartnerPage.vue"),
        props: true,
    },
    {
        // 검색 페이지
        path: "/search",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/search/SearchListPage.vue"),
        props: true,
    },
    {
        // 쇼핑몰 메인 & 상품 리스트
        path: "/shop",
        redirect: { path: "/shop/products" },
        props: true,
    },
    {
        // 쇼핑몰 메인 & 상품 리스트
        path: "/shop/products",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/products/ProductListPage.vue"),
        props: true,
    },
    {
        // 상품 뷰
        path: "/shop/products/:_product",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/products/ProductViewPage.vue"),
        props: true,
    },
    {
        // 베스트셀러
        path: "/shop/best",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/best/BestSellerPage.vue"),
        props: true,
    },
    {
        // 브랜드 목록
        path: "/shop/brands",
        component: () => import(/* webpackChunkName: "shop-brands" */ "../pages/client/shop/brands/BrandListPage.vue"),
        props: true,
    },
    {
        // 브랜드 상세
        path: "/shop/brands/:code",
        component: () => import(/* webpackChunkName: "shop-brands" */ "../pages/client/shop/brands/BrandViewPage.vue"),
        props: true,
    },
    {
        // 브랜드 임시 상세
        path: "/shop/brands/view",
        component: () => import(/* webpackChunkName: "shop-brands" */ "../pages/client/shop/brands/BrandViewPage.vue"),
        props: true,
    },
    {
        // 온라인 쇼룸 목록
        path: "/shop/showrooms",
        component: () => import(/* webpackChunkName: "shop-showrooms" */ "../pages/client/shop/showrooms/ShowroomListPage.vue"),
        props: true,
    },
    {
        // 온라인 쇼룸 목록
        path: "/shop/showrooms/:code",
        component: () => import(/* webpackChunkName: "shop-showrooms" */ "../pages/client/shop/showrooms/ShowroomListPage.vue"),
        props: true,
    },
    {
        // 주문
        path: "/shop/order",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/order.vue"),
        props: true,
    },
    {
        // 주문완료
        path: "/shop/result",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/ResultForm.vue"),
        // component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/result.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/FindPasswordPage.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password/:_user",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/FindPasswordPage.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/FindIdPage.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id/:_user",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/FindIdPage.vue"),
        props: true,
    },
    {
        // SNS 로그인 검증
        path: "/login/sns-validate",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/sns-validate.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/join.vue"),
        props: true,
    },
    {
        // 회원가입 - 본인인증
        path: "/join/certify",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/JoinCertifyPage.vue"),
        props: true,
    },
    {
        // 회원가입 - 약관동의
        path: "/join/agreement",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/JoinAgreePage.vue"),
        props: true,
    },
    {
        // 회원가입 - 회원정보 입력
        path: "/join/form",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/JoinFormPage.vue"),
        props: true,
    },
    {
        // 회원가입 - 완료
        path: "/join/done",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/JoinDonePage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 대시보드
        path: "/mypage",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDashboardPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 대시보드
        path: "/mypage/dashboard",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDashboardPage.vue"),
        children: [...BoardRoutes],
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 주문/배송
        path: "/mypage/orders",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/orders/MypageOrdersPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 주문/배송 상세
        path: "/mypage/orders-detail",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/orders/MypageOrdersDetailPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 결제취소/환불 신청
        path: "/mypage/orders/cancel-application",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/orders/MypageOrdersCancelApplicationPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 반품 신청
        path: "/mypage/orders/return-application",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/orders/MypageOrdersReturnApplicationPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 교환 신청
        path: "/mypage/orders/exchange-application",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/orders/MypageOrdersExchangeApplicationPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 반품/교환
        path: "/mypage/orders/return",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/orders/MypageOrdersReturnPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 반품/교환 상세
        path: "/mypage/orders/return-detail",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/orders/MypageOrdersReturnDetailPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 취소/환불
        path: "/mypage/orders/cancel",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/orders/MypageOrdersCancelPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 취소/환불 상세
        path: "/mypage/orders/cancel-detail",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/orders/MypageOrdersCancelDetailPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 관심상품
        path: "/mypage/likes",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageLikesPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 최근본상품
        path: "/mypage/recent",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageRecentPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 배송 주소록
        path: "/mypage/destinations",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDestinationsPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 세금계산서
        path: "/mypage/tax-bill",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageTaxBillPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 개인결제
        path: "/mypage/personal-payment",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypagePersonalPaymentPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 마일리지
        path: "/mypage/mileage",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageMileagePage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 쿠폰
        path: "/mypage/coupons",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCouponsPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 쿠폰
        path: "/mypage/coupons/:tab",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCouponsPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 1:1 문의
        path: "/mypage/questions/create",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/question/MypageQuestionCreatePage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 1:1 문의
        path: "/mypage/questions/create/:_question",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/question/MypageQuestionCreatePage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 1:1 문의
        path: "/mypage/questions/:_question",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/question/MypageQuestionsPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 1:1 문의
        path: "/mypage/questions",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/question/MypageQuestionsPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 나의 상품문의
        path: "/mypage/inquiries/create/:_inquiry",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/inquire/MyPageInquireCreatePage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 나의 상품문의 뷰
        path: "/mypage/inquiries",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/inquire/MypageInquirePage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 나의 상품문의 작성
        path: "/mypage/inquiries/:_inquiry",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/inquire/MypageInquirePage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 나의 상품후기
        path: "/mypage/reviews",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/review/MypageReviewList.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 나의 상품후기 작성
        path: "/mypage/reviews-input",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/review/MypageReviewInput.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 나의 상품후기 작성
        path: "/mypage/reviews-input/:_review",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/review/MypageReviewInput.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 나의 단골
        path: "/mypage/regular",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageRegularList.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 회원 정보 수정
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageMyinfoPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 회원 탈퇴
        path: "/mypage/leave",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
        props: true,
        hasAccessToken: true,
    },
    ////////////////////////////////////////////////////////////////////////////
    {
        // 장바구니
        path: "/cart",
        component: () => import(/* webpackChunkName: "cart" */ "../pages/client/cart/CartPage.vue"),
        props: () => ({ type: "cart" }),
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 장바구니
        path: "/mypage/cart",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCartPage.vue"),
        props: () => ({ type: "cart" }),
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 장바구니
        path: "/mypage/cart/regular",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCartPage.vue"),
        props: () => ({ type: "regular" }),
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 영수증
        path: "/mypage/receipt",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageReceiptPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 적립금 내역
        path: "/mypage/points",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypagePointsPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 상품 문의
        path: "/mypage/inquiry",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/inquiry.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 환불계좌 관리
        path: "/mypage/refund-account",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageRefundAccountPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 준회원페이지 - 대량구매요청
        path: "/mypage/bulk-purchase",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageBulkPurchasePage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 고객센터 - 대량구매요청
        path: "/mypage/bulk-purchase/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/mypage/BulkPurchaseForm.vue"),
        props: true,
        hasAccessToken: true,
    },
    // {
    //     // 정회원페이지 - 대시보드
    //     path: "/promoter",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterDashboardPage.vue"),
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 대시보드
    //     path: "/promoter/dashboard",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterDashboardPage.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 보너스 내역
    //     path: "/promoter/bonuses",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBonusesPage.vue"),
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 보너스 내역
    //     path: "/promoter/bonuses__:tab",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBonusesPage.vue"),
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 보너스 출금
    //     path: "/promoter/bonuses-withdraw",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBonusesWithdraw.vue"),
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 추천회원 구매확정 내역
    //     path: "/promoter/promotee-purchases",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterPromoteePurchases.vue"),
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 나의 구매확정 내역
    //     path: "/promoter/promoter-purchases",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterPromoterPurchases.vue"),
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 나의 구매확정 내역
    //     path: "/promoter/scores",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterScoresPage.vue"),
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 나의 구매확정 내역
    //     path: "/promoter/bank",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBankPage.vue"),
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 나의 추천회원
    //     path: "/promoter/promotees",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterPromoteesPage.vue"),
    //     props: true,
    // },
    // {
    //     // 정회원페이지 - 1:1 문의 내역
    //     path: "/promoter/questions",
    //     component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterQuestionsPage.vue"),
    //     props: true,
    // },
    {
        // 이메일무단수집거부
        path: "/email-policy",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/email-policy.vue"),
        props: true,
    },
    {
        // 고객센터 - 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterNoticePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterNoticePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 자주묻는 질문
        path: "/center/faq",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterFaqPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 문의하기
        path: "/center/questions/create",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInquiryCreatePage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 고객센터 - 문의하기
        path: "/center/questions/create/:_question",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInquiryCreatePage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 고객센터 - 문의하기
        path: "/center/questions",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInquiryPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 고객센터 - 문의하기
        path: "/center/questions/:_question",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInquiryPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    // {
    //     // 고객센터 - 개인회원서비스
    //     path: "/center/individual-service",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterIndividualServicePage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 개인회원 서비스 문의
    //     path: "/center/individual-service/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserServiceForm.vue"),
    //     props: { category: USER_TYPES.PERSON.value },
    // },
    // {
    //     // 고객센터 - 기업회원 서비스
    //     path: "/center/corporate-service",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterCorporateServicePage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 기업회원 서비스 문의
    //     path: "/center/corporate-service/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserServiceForm.vue"),
    //     props: { category: USER_TYPES.COMPANY.value },
    // },
    // {
    //     // 고객센터 - 첫 구매 가이드 문의
    //     path: "/center/first-buying-guide/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserGuideForm.vue"),
    //     props: { category: BOARD_CATEGORIES__JFL_GUIDES.FIRST_TIMERS.value },
    // },
    // {
    //     // 고객센터 - 첫 구매 가이드
    //     path: "/center/first-buying-guide",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterFirstBuyingGuidePage.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 고객센터 - 필독 가이드 문의
    //     path: "/center/must-read-guide/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserGuideForm.vue"),
    //     props: { category: BOARD_CATEGORIES__JFL_GUIDES.MUST_NOTICES.value },
    // },
    // {
    //     // 고객센터 - 필독 가이드
    //     path: "/center/must-read-guide",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterMustReadGuidePage.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 고객센터 - 설치 서비스 문의
    //     path: "/center/installation-service/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserGuideForm.vue"),
    //     props: { category: BOARD_CATEGORIES__JFL_GUIDES.INSTALLATION.value },
    // },
    // {
    //     // 고객센터 - 설치 서비스
    //     path: "/center/installation-service",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInstallationServicePage.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // 고객센터 - 출장 컨설팅
    //     path: "/center/business-trip-consulting",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterBusinessTripConsultingPage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 출장 컨설팅 문의
    //     path: "/center/business-trip-consulting/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
    //     props: { category: FORM_CATEGORIES__JFL_CARES.BUSINESS_TRIPS.value },
    // },
    // {
    //     // 고객센터 - 온라인 컨설팅
    //     path: "/center/online-consulting",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterOnlineConsultingPage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 온라인 컨설팅 문의
    //     path: "/center/online-consulting/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
    //     props: { category: FORM_CATEGORIES__JFL_CARES.ONLINE_CONSULT.value },
    // },
    // {
    //     // 고객센터 - 딜리버리 설치 서비스
    //     path: "/center/delivery-installation-service",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterDeliveryInstallationServicePage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 딜리버리 설치 서비스 문의
    //     path: "/center/delivery-installation-service/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
    //     props: { category: FORM_CATEGORIES__JFL_CARES.DELIVERY_QUERY.value },
    // },
    // {
    //     // 고객센터 - 전담 수리 서비스
    //     path: "/center/dedicated-repair-service",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterDedicatedRepairServicePage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 전담 수리 서비스 문의
    //     path: "/center/dedicated-repair-service/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
    //     props: { category: FORM_CATEGORIES__JFL_CARES.AFTER_SERVICES.value },
    // },
    // {
    //     // 고객센터 - 안심 상담
    //     path: "/center/consultation",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterConsultationPage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 안심 상담 문의
    //     path: "/center/consultation/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
    //     props: { category: FORM_CATEGORIES__JFL_CARES.PRIVATES_QUERY.value },
    // },
    // {
    //     // 고객센터 - 대량구매요청
    //     path: "/center/bulk-purchase",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterBulkPurchasePage.vue"),
    //     props: true,
    // },
    // {
    //     // 고객센터 - 대량구매요청
    //     path: "/center/bulk-purchase/inquire",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/BulkPurchaseForm.vue"),
    //     props: true,
    // },
    // {
    //     // 커뮤니티
    //     path: "/community",
    //     redirect: { path: "/community/news" },
    //     props: true,
    // },
    // {
    //     // 컨시어지 서비스
    //     path: "/concierge-service",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/concierge/ConciergeServicePage.vue"),
    //     props: true,
    // },
    // {
    //     // 커뮤니티 - 공지사항
    //     path: "/community/notices",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/community/CommunityNoticePage.vue"),
    //     props: true,
    // },
    // {
    //     // 커뮤니티 - 공지사항
    //     path: "/center/notices/:_notification",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/community/CommunityNoticePage.vue"),
    //     props: true,
    // },
    // {
    //     // 커뮤니티 - 게시판
    //     path: "/community/:category",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/community/CommunityBoardPage.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },

    {
        // 비즈니스 - 회사소개
        path: "/sub/business/about",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/business/AboutPage.vue"),
        props: true,
    },
    {
        // 비즈니스 - Contact Us
        path: "/sub/business/contact",
        component: () => import(/* webpackChunkName: "business" */ "../pages/client/sub/business/ContactPage.vue"),
        props: true,
    },
    {
        // 비즈니스 - 이용약관
        path: "/sub/business/terms",
        component: () => import(/* webpackChunkName: "business" */ "../pages/client/sub/business/TermsPage.vue"),
        props: true,
    },
    {
        // 비즈니스 - 개인정보처리방침
        path: "/sub/business/privacy-policy",
        component: () => import(/* webpackChunkName: "business" */ "../pages/client/sub/business/PrivacyPolicyPage.vue"),
        props: true,
    },
    {
        // 비즈니스 - 이용안내
        path: "/sub/business/site-guide",
        component: () => import(/* webpackChunkName: "business" */ "../pages/client/sub/business/SiteGuidePage.vue"),
        props: true,
    },

    {
        // 이벤트 - 진행중인 이벤트
        path: "/sub/event",
        redirect: "/sub/event/ongoing",
    },
    {
        // 이벤트 - 진행중인 이벤트
        path: "/sub/event/ongoing",
        component: () => import(/* webpackChunkName: "event" */ "../pages/client/sub/event/EventPage.vue"),
        props: { status: EVENT_STATUS.ONGOING.value },
    },
    {
        // 이벤트 - 진행중인 이벤트
        path: "/sub/event/ongoing/:_event",
        component: () => import(/* webpackChunkName: "event" */ "../pages/client/sub/event/EventPage.vue"),
        props: true,
        props: { status: EVENT_STATUS.ONGOING.value },
    },
    {
        // 이벤트 - 종료된 이벤트
        path: "/sub/event/ended",
        component: () => import(/* webpackChunkName: "event" */ "../pages/client/sub/event/EventPage.vue"),
        props: { status: EVENT_STATUS.ENDED.value },
    },
    {
        // 이벤트 - 종료된 이벤트
        path: "/sub/event/ended/:_event",
        component: () => import(/* webpackChunkName: "event" */ "../pages/client/sub/event/EventPage.vue"),
        props: true,
        props: { status: EVENT_STATUS.ENDED.value },
    },
    {
        // 이벤트 - 태그 이벤트
        path: "/sub/event/tag",
        component: () => import(/* webpackChunkName: "event" */ "../pages/client/sub/event/TagEventPage.vue"),
        props: true,
    },
    {
        // 이벤트 - 기획전
        path: "/sub/event/exhibition",
        component: () => import(/* webpackChunkName: "event" */ "../pages/client/sub/event/ExhibitionPage.vue"),
        props: true,
    },
    {
        // 이벤트 - 기획전
        path: "/sub/event/exhibition/:_exhibition",
        component: () => import(/* webpackChunkName: "event" */ "../pages/client/sub/event/ExhibitionPage.vue"),
        props: true,
    },
    {
        // 이벤트 - 쿠폰
        path: "/sub/event/coupon",
        component: () => import(/* webpackChunkName: "event" */ "../pages/client/sub/event/CouponEventPage.vue"),
        props: true,
    },

    {
        // 스토리 - 메인
        path: "/story",
        redirect: "/story/article",
    },
    {
        // 스토리 - 게시글 목록 (메인)
        path: "/story/:code",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/story/StoryMainPage.vue"),
        props: true,
    },
    {
        // 스토리 - 게시글 작성
        path: "/story/:code/write",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/story/StoryFormPage.vue"),
        props: true,
        hasAccessToken: true
    },
    {
        // 스토리 - 게시글 상세
        path: "/story/:code/:_board",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/story/StoryViewPage.vue"),
        props: true,
    },
    {
        // 스토리 - 게시글 상세
        path: "/story/:code/:_board/modify",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/story/StoryFormPage.vue"),
        props: true,
        hasAccessToken: true
    },

    {
        // 스토리 - 전기자전거 - 전체보기
        path: "/sub/story/bicycle/index",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/bicycle/IndexPage.vue"),
        props: true,
    },
    {
        // 스토리 - 전기자전거 - 라이딩
        path: "/sub/story/bicycle/riding",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/bicycle/riding/ListPage.vue"),
        props: true,
    },
    {
        // 스토리 - 전기자전거 - 라이딩 상세
        // path: "/sub/story/bicycle/riding:_riding",
        path: "/sub/story/bicycle/riding/view",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/bicycle/riding/ViewPage.vue"),
        props: true,
    },
    {
        // 스토리 - 라이더이야기 게시판 리스트
        path: "/sub/story/riders",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/riders/ListPage.vue"),
        props: true,
    },
    {
        // 스토리 - 라이더이야기 게시판 상세
        // path: "/sub/story/riders:_riders",
        path: "/sub/story/riders/view",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/riders/ViewPage.vue"),
        props: true,
    },
    {
        // 스토리 - 라이더이야기 게시판 글작성
        path: "/sub/story/riders/input",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/riders/InputPage.vue"),
        props: true,
    },
    {
        // 스토리 - 사장님이야기 게시판 리스트
        path: "/sub/story/seller",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/seller/ListPage.vue"),
        props: true,
    },
    {
        // 스토리 - 사장님이야기 게시판 상세
        // path: "/sub/story/seller:_seller",
        path: "/sub/story/seller/view",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/seller/ViewPage.vue"),
        props: true,
    },
    {
        // 스토리 - 사장님이야기 게시판 글작성
        path: "/sub/story/seller/input",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/seller/InputPage.vue"),
        props: true,
    },
    {
        // 스토리 - 소식/정보
        path: "/sub/story/notice",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/story/notice/StoryNoticePage.vue"),
        props: true,
    },
    {
        // 스토어
        path: "/sub/store",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/store/StoreMainPage.vue"),
        props: true,
    },
    {
        // 스토어 - 전기자전거
        path: "/sub/store/bicycle",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/store/StoreBicyclePage.vue"),
        props: true,
    },
    {
        // 스토어 - 전동스쿠터
        path: "/sub/store/scooter",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/store/StoreScooterPage.vue"),
        props: true,
    },
    {
        // 스토어 - 전동킥보드
        path: "/sub/store/kickboard",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/store/StoreKickboardPage.vue"),
        props: true,
    },
    {
        // 스토어 - 내 근처 미니샵 보기
        path: "/sub/store/map",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/store/StoreMapPage.vue"),
        props: true,
    },
    {
        // 미니샵 - 메인
        path: "/minishop/:_user",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/MiniShopPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Best
        path: "/minishop/:_user/best",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/best/BestListPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Category
        path: "/minishop/:_user/category",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/category/CategoryListPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Coupon
        path: "/minishop/:_user/coupons",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/coupons/CouponListPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story",
        redirect: { path: "/minishop/:_user/story/daily" },
        props: true,
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/daily",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/GalleryListPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: null,
        }),
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/owner",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultListPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: "owner",
        }),
        scope: ["company"],
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/rider",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultListPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: "rider",
        }),
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/:path",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/GalleryListPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/daily/:_board",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultViewPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: null,
        }),
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/daily/:_board/modify",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultFormPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: null,
        }),
        hasAccessToken: true
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/owner/write",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultFormPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: "owner",
        }),
        scope: ["company"],
        hasAccessToken: true
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/owner/:_board",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultViewPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: "owner",
        }),
        scope: ["company"],
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/owner/:_board/modify",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultFormPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: "owner",
        }),
        scope: ["company"],
        hasAccessToken: true
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/rider/write",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultFormPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: "rider",
        }),
        hasAccessToken: true
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/rider/:_board",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultViewPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: "rider",
        }),
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/rider/:_board/modify",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultFormPage.vue"),
        props: ({ params }) => ({
            ...params,
            code: "rider",
        }),
        hasAccessToken: true
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/:path/:_board",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultViewPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Story
        path: "/minishop/:_user/story/:path/:_board/modify",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/story/DefaultFormPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Wishlist
        path: "/minishop/:_user/likes",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/likes/LikesListPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Follow
        path: "/minishop/:_user/follow",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/follow/FollowListPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Chat
        path: "/minishop/:_user/chat",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/chat/UserChatList.vue"),
        props: true,
    },
    {
        // 미니샵 - Request
        path: "/minishop/:_user/request",
        redirect: { path: "/minishop/:_user/request/service" },
        props: true,
    },
    {
        // 미니샵 - Request - Service
        path: "/minishop/:_user/request/service",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/request/ServiceListPage.vue"),
        props: true,
    },
    {
        // 미니샵 - Request - Service
        path: "/minishop/:_user/request/service/:_service",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/request/ServiceFormPage.vue"),
        props: true,
        hasAccessToken: true,
    },
    {
        // 미니샵 - Request - Schedule
        path: "/minishop/:_user/request/schedule",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/miniShop/request/ScheduleDatePage.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 플러그인 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        //나이스페이
        path: "/plugins/nice/pay/result",
        component: () => import("../pages/plugins/nice/NICEPayResult.vue"),
        props: true,
    },
    {
        // 나이스페이먼츠(비대면) 호출
        path: "/plugins/nicepayments/request",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/plugins/nicepayments/request.vue"),
        props: true,
    },
    {
        // 네이스페이먼츠(비대면) 결과
        path: "/plugins/nicepayments/response",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/plugins/nicepayments/response.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
    },
    {
        // 관리자 - 대시보드
        path: "/console/dashboard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Dashboard.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/board-comments",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardCommentList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 회원직급
        path: "/console/user-grades",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-grades/UserGradeList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원 - 회원등급 - 목록
        path: "/console/user-level",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-levels/UserLevelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 회원등급 - 상세
        path: "/console/user-level/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-levels/UserLevelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 탈퇴회원
        path: "/console/withdrawn-users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/withdrawn-users/WithdrawnUserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 차단회원
        path: "/console/blocked-users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/blocked-users/BlockedUserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 회원목록
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 회원상세
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 발급쿠폰
        path: "/console/user-coupons",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-coupons/UserCouponList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/inquiries",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/inquiries/InquiryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사이트 - 기본정보
        path: "/console/site/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/site/SiteSettingPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사이트 - 이용약관
        path: "/console/site/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/site/SiteTermList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품전시
        path: "/console/main/link",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainLinkSetting.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 대량등록
        path: "/console/shop/bulk-upload",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/bulk-upload/ShopBulkUpload.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 배송정보
        path: "/console/shop/shippings",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/shippings/ShopShippingList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품 - 목록
        path: "/console/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductList.vue"),
        props: true,
        // scope: ["console"],
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품 - 신규
        path: "/console/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        // scope: ["console"],
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품 - 상세
        path: "/console/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        // scope: ["console"],
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품 - 상세
        path: "/console/shop/category-details",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/category-details/CategoryDetailList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 서비스 - 목록
        path: "/console/shop/services",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/services/ServiceList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품 - 신규
        path: "/console/shop/services/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/services/ServiceView.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품 - 상세
        path: "/console/shop/services/:_service",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/services/ServiceView.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 카테고리
        path: "/console/shop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/categories/CategoryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 브랜드
        path: "/console/shop/brands",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/brands/BrandList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 쇼룸
        path: "/console/shop/showrooms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/showrooms/ShowroomListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 쇼룸
        path: "/console/shop/showrooms/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/showrooms/ShowroomListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 이벤트
        path: "/console/shop/events",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/events/ShopEventList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품전시
        path: "/console/shop/tags",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/tags/TagList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 기획전
        path: "/console/shop/exhibitions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/exhibitions/ShopExhibitionList.vue"),
        props: true,
        scope: ["console"],
    },
    // {
    //     // 관리자 - 쇼핑몰 - 상품전시
    //     path: "/console/shop/display",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayPage.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 쇼핑몰 - 상품전시
    //     path: "/console/shop/display/:code",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayPage.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    {
        // 관리자 - 쇼핑몰 - 상품문의
        path: "/console/shop/inquires",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/inquires/InquireList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 구매후기
        path: "/console/shop/reviews",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/reviews/ReviewList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 주문 - 통계
        path: "/console/statistics",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/statistics/StatisticsView.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 주문 - 주문 관리
        path: "/console/shop/purchases/all",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PurchaseList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 주문 - 미결제 관리
        path: "/console/shop/purchases/nonpayment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/NonpaymentList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 주문 - 발주/발송 관리
        path: "/console/shop/purchases/payment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PaymentList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 주문 - 배송 관리
        path: "/console/shop/purchases/delivery",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/DeliveryList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 주문 - 취소 관리
        path: "/console/shop/purchases/cancel",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CancelList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 주문 - 반품 관리
        path: "/console/shop/purchases/exchange",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ExchangeList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 주문 - 교환 관리
        path: "/console/shop/purchases/return",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ReturnList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 주문 - 구매확정 관리
        path: "/console/shop/purchases/complete",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CompleteList.vue"),
        props: true,
        scope: ["console", "company"],
    },

    {
        // 관리자 - 정산 - 수수료 정책
        path: "/console/shop/moneys",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/moneys/ShopMoneyList.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - 정산 - 세금계산서
        path: "/console/shop/taxinvoices",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/taxinvoices/ShopTaxinvoiceList.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - 정산 - 예치금
        path: "/console/user-moneys",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-moneys/UserMoneyList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 정산 - 예치금
        path: "/console/user-money-statistics",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-money-statistics/UserMoneyStatistics.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 정산 - 예치금 출금
        path: "/console/user-money-withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-money-withdrawals/MoneyWithdrawalList.vue"),
        props: true,
        scope: ["console", "company"],
    },

    {
        // 관리자 - 정산 - 개인결제 관리
        path: "/console/user-payments",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-payments/UserPaymentList.vue"),
        props: true,
        scope: ["console", "company"],
    },

    {
        // 관리자 - 문의 - Contact Us
        path: "/console/center/contacts",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/ContactList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 문의 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/questions/QuestionList.vue"),
        props: true,
        scope: ["console"],
    },
    // {
    //     // 관리자 - 문의 - 제휴문의
    //     path: "/console/center/inquiry-partners",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/InquiryPartnerList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 문의 - 컨시어지
    //     path: "/console/center/concierge-forms",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/ConciergeFormList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 문의 - 대량구매요청
    //     path: "/console/center/bulk-purchase-forms",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/BulkPurchaseList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 문의 - 고객센터 개인 & 기업회원 서비스
    //     path: "/console/center/user-service-forms",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/UserServiceList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 문의 - 고객센터 JFL 가이드
    //     path: "/console/center/user-guide-forms",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/UserGuideList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    // {
    //     // 관리자 - 문의 - 고객센터 JFL 케어
    //     path: "/console/center/user-consulting-forms",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/UserConsultingList.vue"),
    //     props: true,
    //     scope: ["console"],
    // },

    {
        // 관리자 - 통계 - 방문 통계
        path: "/console/analytics/visits",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/analytics/VisitsView.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 통계 - 상품별 판매 통계
        path: "/console/analytics/sales",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/analytics/SalesView.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 통계 - 태그 통계
        path: "/console/analytics/tags",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/analytics/TagsView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 통계 - 배너 통계
        path: "/console/analytics/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/analytics/BannersView.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - 메인페이지 - 배너 관리
        path: "/console/main/banner/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainBannerPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인페이지 - 상품전시 관리
        path: "/console/main/display/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인페이지 - 리뷰전시 관리
        path: "/console/main/review/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayReview.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인페이지 - 보드전시 관리
        path: "/console/main/story/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainStoryPage.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - 스토리 - 카테고리
        path: "/console/story/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/story/categories/CategoryList.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - 정산관리
        path: "/console/user-settlements",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 정산상세
        path: "/console/user-settlements/:_settlement",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 적립금 관리
        path: "/console/user-points",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-points/UserPointList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 직급포인트 관리
        path: "/console/user-scores",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-scores/UserScoreList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 보너스 관리
        path: "/console/user-bonuses",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-bonuses/UserBonusList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 코드관리
        path: "/console/shop/codes",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/codes/CodeList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 쿠폰정책
        path: "/console/shop/coupons",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/coupons/ShopCouponList.vue"),
        props: true,
        scope: ["console", "company"],
    },
    {
        // 관리자 - 쇼핑몰 - 적립정책
        path: "/console/shop/points",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/points/ShopPointList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/notifications/NotificationList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/faqs/FaqList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리 - 목록
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리 - 생성
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리 - 상세
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - SMS / LMS 웹 발송
        path: "/console/messages",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/messages/MessageView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 알람 발송 목록
        path: "/console/alarms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/alarms/AlarmList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 띠배너관리
        path: "/console/band-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/band-banners/BandBannerListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 팝업관리
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popups/PopupList.vue"),
        props: true,
        scope: ["console"],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: (to, from, savedPosition) => {
        return new Promise((resolve) =>
            setTimeout(() => {
                if (to.hash) resolve({ selector: to.hash, behavior: "instant" });

                if (savedPosition) {
                    resolve({ ...savedPosition, behavior: "instant" });
                }

                if (to.path.includes("/shop/showrooms")) resolve();

                if (to?.path == from?.path) resolve();

                resolve({ x: 0, y: 0, behavior: "instant" });
            }, 0)
        );
    },
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = cookieStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";

        const payload = accessToken ? JSON.parse(atob(accessToken.split(".")[1])) || {} : {};
        const route = routes.find((route) => [to?.path, to?.matched?.[0]?.path].includes(route.path));
        if (route?.scope) {
            if (!accessToken) throw new Error("로그인 후 이용 가능합니다", { cause: "/login" });

            const { scope = [] } = payload;
            if (!scope.some((scope) => route.scope.includes(scope))) throw new Error("접근 권한이 없습니다");

            next();
        } else if (route?.userTypes) {
            if (!accessToken) throw new Error("로그인 후 이용 가능합니다", { cause: "/login" });

            const { userType } = payload;
            if (!route.userTypes.includes(userType)) throw new Error("접근 권한이 없습니다");

            next();
        } else if (route?.hasAccessToken) {
            if (!accessToken) throw new Error("로그인 후 이용 가능합니다", { cause: "/login" });
            next();
        } else next();
    } catch (error) {
        alert(error.message);

        let path;
        if (error.cause) {
            console.log(error.cause);
            path = ["/promoter", "/console"].find((path) => to.path.includes(path)) || "/";

            if (path == "/") path = error.cause;
        } else if (from.path != "/") path = routes.find((route) => route.path == from.path)?.path;
        else {
            path = ["/promoter", "/console"].find((path) => to.path.includes(path)) || "/";
        }

        next({ path });
    }
});
export default router;
