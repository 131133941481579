/**
 * 수정세금계산서 데이터
 * @param {TaxinvoiceAmendmentData} data
 * @returns {TaxinvoiceAmendmentData}
 */
export const initShopTaxinvoiceAmendment = (data = {}) => ({
    modifyCode: data.modifyCode,
    orgNTSConfirmNum: data.orgNTSConfirmNum,
});

export default initShopTaxinvoiceAmendment;

/**
 * @typedef {object} TaxinvoiceAmendmentData 수정세금계산서 데이터
 * * 참고: https://developers.popbill.com/guide/taxinvoice/node/introduction/modified-taxinvoice
 *
 * @property {1|2|3|4|5|6} [modifyCode] 62.수정 사유코드
 * * 수정세금계산서 작성시 필수
 * * 1: 기재사항 착오정정
 * * 2: 공급가액 변동
 * * 3: 환입
 * * 4: 계약의 해제
 * * 5: 내국신용장 사후개설
 * * 6: 착오에 의한 이중발급
 *
 * @property {string} [orgNTSConfirmNum] 63.당초승인번호
 * * 수정세금계산서 작성시 필수
 */
