Number.prototype.format = function(n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

if (!Number.prototype.hasOwnProperty("toAgo")) {
    Number.prototype.toAgo = function () {
        const timeValue = new Date(Number(this));
        const today = new Date();

        const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
        if (betweenTime < 1) return "방금 전";
        if (betweenTime < 60) return `${betweenTime}분 전`;

        const betweenTimeHour = Math.floor(betweenTime / 60);
        if (betweenTimeHour < 24) return `${betweenTimeHour}시간 전`;

        const betweenTimeDay = Math.floor(betweenTimeHour / 24);
        if (betweenTimeDay < 7) return `${betweenTimeDay}일 전`;

        const betweenTimeWeek = Math.floor(betweenTimeDay / 7);
        if (betweenTimeDay < 366) return `${betweenTimeWeek}주 전`;

        const betweenTimeYear = Math.floor(betweenTimeDay / 365);
        return `${betweenTimeYear}년 전`;
    };
}

if (!Number.prototype.hasOwnProperty("toTil")) {
    Number.prototype.toTil = function (suffix = "남음") {
        const timeValue = new Date(Number(this));
        const today = new Date();

        const betweenTime = Math.floor((timeValue.getTime() - today.getTime()) / 1000);
        if (betweenTime < 1) return "종료";
        if (betweenTime < 60) return `${betweenTime}초 ${suffix}`;

        const betweenTimeMinute = Math.floor(betweenTime / 60);
        if (betweenTimeMinute < 60) return `${betweenTimeMinute}분 ${suffix}`;

        const betweenTimeHour = Math.floor(betweenTimeMinute / 60);
        if (betweenTimeHour < 24) return `${betweenTimeHour}시간 ${suffix}`;

        const betweenTimeDay = Math.floor(betweenTimeHour / 24);
        if (betweenTimeDay < 366) return `${betweenTimeDay}일 ${suffix}`;

        // const betweenTimeWeek = Math.floor(betweenTimeDay / 7);
        // if (betweenTimeDay < 366) return `${betweenTimeWeek}주 ${suffix}`;

        const betweenTimeYear = Math.floor(betweenTimeDay / 365);
        return `${betweenTimeYear}년 ${suffix}`;
    };
}

Number.prototype.toDate = function() {
    var date = new Date(this);
    return [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, 0), date.getDate().toString().padStart(2, 0)].join("-");
};

Number.prototype.toDateTime = function() {
    var date = new Date(this);
    return this.toDate(this)+" "+[date.getHours().toString().padStart(2, 0), date.getMinutes().toString().padStart(2, 0), date.getSeconds().toString().padStart(2,0)].join(":");
};

String.prototype.phoneNumberFormat = function(){
    var phone = this ? this.replace(/-/g, '') : null;
    return phone ? [phone.substr(0, 3), phone.substr(3, 4), phone.substr(7, 4)].join('-') : this;
};

if (!String.prototype.hasOwnProperty("toAgo")) {
    String.prototype.toAgo = function () {
        const timestamp = this.length == 13 && !this.match(/[^0-9]/) ? parseInt(this.toString()) : undefined;
        const timeValue = new Date(timestamp || this.toString());

        const today = new Date();

        const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
        if (betweenTime < 1) return "방금 전";
        if (betweenTime < 60) return `${betweenTime}분 전`;

        const betweenTimeHour = Math.floor(betweenTime / 60);
        if (betweenTimeHour < 24) return `${betweenTimeHour}시간 전`;

        const betweenTimeDay = Math.floor(betweenTimeHour / 24);
        if (betweenTimeDay < 7) return `${betweenTimeDay}일 전`;

        const betweenTimeWeek = Math.floor(betweenTimeDay / 7);
        if (betweenTimeDay < 366) return `${betweenTimeWeek}주 전`;

        const betweenTimeYear = Math.floor(betweenTimeDay / 365);
        return `${betweenTimeYear}년 전`;
    };
}

if (!String.prototype.hasOwnProperty("toTil")) {
    String.prototype.toTil = function (suffix = "남음") {
        const timestamp = this.length == 13 && !this.match(/[^0-9]/) ? parseInt(this.toString()) : undefined;
        const timeValue = new Date(timestamp || this.toString());
        const today = new Date();

        const betweenTime = Math.floor((timeValue.getTime() - today.getTime()) / 1000);
        if (betweenTime < 1) return "종료";
        if (betweenTime < 60) return `${betweenTime}초 ${suffix}`;

        const betweenTimeMinute = Math.floor(betweenTime / 60);
        if (betweenTimeMinute < 60) return `${betweenTimeMinute}분 ${suffix}`;

        const betweenTimeHour = Math.floor(betweenTimeMinute / 60);
        if (betweenTimeHour < 24) return `${betweenTimeHour}시간 ${suffix}`;

        const betweenTimeDay = Math.floor(betweenTimeHour / 24);
        if (betweenTimeDay < 366) return `${betweenTimeDay}일 ${suffix}`;

        // const betweenTimeWeek = Math.floor(betweenTimeDay / 7);
        // if (betweenTimeDay < 366) return `${betweenTimeWeek}주 ${suffix}`;

        const betweenTimeYear = Math.floor(betweenTimeDay / 365);
        return `${betweenTimeYear}년 ${suffix}`;
    };
}

String.prototype.toDate = function() {
    var date = new Date(
        (this.length == 13 && !this.match(/[^0-9]/)) ? parseInt(this) : this
    );
    return [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, 0), date.getDate().toString().padStart(2, 0)].join("-");
};

String.prototype.toDateTime = function() {
    var date = new Date(
        (this.length == 13 && !this.match(/[^0-9]/)) ? parseInt(this) : this
    );
    return this.toDate(this)+" "+[date.getHours().toString().padStart(2, 0), date.getMinutes().toString().padStart(2, 0), date.getSeconds().toString().padStart(2,0)].join(":");
};

String.prototype.interpolate = function(params) {
    const names = Object.keys(params);
    const vals = Object.values(params);
    return new Function(...names, `return \`${this}\`;`)(...vals);
}

Date.prototype.addYear = function(year = 0){
    return this.setYear(this.getFullYear() + year);
}

Date.prototype.addMonth = function(month = 0){
    return this.setMonth(this.getMonth() + month);
}

Date.prototype.addDate = function(days = 0){
    return this.setDate(this.getDate() + days);
}

Date.prototype.addHours = function(hour = 0){
    return this.setHours(this.getHours() + hour);
}

Date.prototype.addMinutes = function(minute = 0){
    return this.setMinutes(this.getMinutes() + minute);
}

Date.prototype.addSeconds = function(second = 0){
    return this.setSeconds(this.getSeconds() + second);
}

Object.assignDefined = function(target, ...sources) {
    for (const source of sources) {
        for (const key of this.keys(source)) {
            const val = source[key];
            if (val !== undefined) {
                target[key] = val;
            }
        }
    }
    return target;
};

Array.shuffle = function(array){
    for(var i = array.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * ( i + 1 ));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
