<template>
    <v-card tile outlined :to="{ path: `/minishop/${value._id}` }" align="center">
        <v-sheet class="v-card__inner">
            <!-- S: 모바일 이미지 -->
            <v-img v-if="value.banner" :src="value.banner.url" class="v-card__image d-lg-none" :aspect-ratio="1 / 1" />
            <v-img v-else src="/images/minishop/skin-default.jpg" class="v-card__image d-lg-none" :aspect-ratio="1 / 1" />
            <!-- E: 모바일 이미지 -->

            <!-- S: PC 이미지 -->
            <v-img v-if="value.banner" :src="value.banner.url" class="v-card__image d-none d-lg-block" :aspect-ratio="2 / 1" />
            <v-img v-else src="/images/minishop/skin-default.jpg" class="v-card__image d-none d-lg-block" :aspect-ratio="2 / 1" />
            <!-- E: PC 이미지 -->

            <v-avatar size="62" class="border">
                <slot name="avatar" />
            </v-avatar>
            <v-card-title class="tit tit--xs justify-center">{{ value?.nickname }}</v-card-title>
            <v-card-text class="txt txt--dark">
                <p>{{ address }}</p>
                <p>{{ value?.tel }}</p>
                <span v-for="(tag, index) in value?.mini?.tag" :key="index">#{{ tag }} </span>
                <p class="grey--text">팔로워 {{ value?.mini?.followCount || 0 }}</p>
            </v-card-text>
        </v-sheet>
        <v-img v-if="hot" class="ribbon" src="/images/sub/store/hot-ribbon.png" />
        <v-img v-if="best" class="ribbon" src="/images/sub/store/best-ribbon.png" />
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => {} },
        hot: { type: Boolean, default: false },
        best: { type: Boolean, default: false },
    },
    computed: {
        address() {
            return this.value.address1 || this.value.address2 ? `${this.value.address1} ${this.value.address2}` : "";
        },
    },
};
</script>

<style lang="scss" scoped>
.v-card {
    height: 100%;
    position: relative;
    &__image {
        margin-bottom: -32px;
    }
    &__title {
        padding: var(--grid-gutter-xs);
    }
    &__text {
        p {
            margin-top: 0;
        }
    }
    .ribbon {
        position: absolute;
        top: 0;
        left: 22px;
    }
}
// @media (min-width: 1200px) {
//     .v-card {
//         &::after {
//             content: "";
//             display: block;
//             width: 100%;
//             padding-bottom: 100%;
//         }
//         &__inner {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//         }
//     }
// }
</style>
