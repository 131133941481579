import Vue from "vue";

import { getDistanceBetweenLocations } from "./vue-methods-geolocation";

import { PURCHASE_PURCHASE_STATUSES, PURCHASE_CLAIM_STATUSES } from "@/assets/variables/";
import { SHIPPING_OPTION_BUNDLE_TYPES, SHIPPING_OPTION_CHARGE_TYPES, SHOP_COUPON_UNITS, USAGE_TARGET_PRICES, USER_LEVEL_DISCOUNT_TYPES, USER_LEVEL_DISCOUNT_UNITS } from "@/assets/variables/constants";

export const getCartProductGroups = function (carts = [], coupons = []) {
    return carts.reduce((productGroups, cart) => {
        const { seller, product } = cart;

        let productGroup = productGroups.find(({ product: { _id } }) => _id?.toString?.() == product?._id?.toString?.());

        if (!productGroup) {
            let shippingOption = cart?.shippingOption?.toObject?.() || cart?.shippingOption;
            let shippingMethod = cart?.shippingOption?.method?.toObject?.() || cart?.shippingOption?.method;
            let shippingRegion = cart?.shippingOption?.region?.toObject?.() || cart?.shippingOption?.region;

            let groupCoupons = coupons.filter((coupon) => {
                // const sameSeller = coupon._seller?.toString?.() == product?._seller?.toString?.();
                const sameProduct = coupon._product?.toString?.() == product?._id?.toString?.();
                const isTargetting = coupon.usage.target.price == USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value;

                // return sameSeller && sameProduct && isTargetting;
                return sameProduct && isTargetting;
            });
            let _groupCoupons = groupCoupons.map(({ _id }) => _id);

            productGroup = {
                _seller: cart?._seller,
                seller,

                _product: cart?._product,
                product,

                _shipping: cart?._shipping,
                _shippingOption: cart?._shippingOption,

                shippingMethod,
                shippingOption,
                shippingRegion,

                shippingBundleType: cart.shippingBundleType,
                shippingChargeType: shippingOption?.charge?.type,
                shippingMethodCode: cart.shippingMethodCode,
                shippingRegionCode: cart.shippingRegionCode,

                carts: [],
                _carts: [],

                coupons: [...groupCoupons],
                _coupons: [..._groupCoupons],

                coupons__product: [...groupCoupons],
                _coupons__product: [..._groupCoupons],
            };
            productGroups.push(productGroup);
        }

        productGroup.carts.push(cart);
        productGroup._carts.push(cart._id);

        return productGroups;
    }, []);
};

export const getCartShippingGroups = function (carts = [], coupons = []) {
    return getCartProductGroups(carts, coupons).reduce((shippingGroups, productGroup) => {
        const { _seller, seller, _product, product, shippingMethod, shippingRegion, shippingBundleType, shippingMethodCode, shippingRegionCode } = productGroup;

        let shippingGroup;

        let needsBundling = shippingBundleType == SHIPPING_OPTION_BUNDLE_TYPES.BUNDLE.value;
        if (needsBundling) {
            shippingGroup = shippingGroups.find((group) => {
                const sameSeller = group._seller?.toString?.() == _seller?.toString?.();
                const sameMethodCode = group.shippingMethodCode == shippingMethodCode;
                const sameRegionCode = group.shippingRegionCode == shippingRegionCode;
                const needsBundling = group.shippingBundleType == SHIPPING_OPTION_BUNDLE_TYPES.BUNDLE.value;
                return sameSeller && sameMethodCode && sameRegionCode && needsBundling;
            });
        }
        if (!shippingGroup) {
            let groupCoupons = coupons.filter((coupon) => {
                const sameSeller = _seller?.toString?.() == coupon?._seller?.toString?.();
                const sameProduct = _product?.toString?.() == coupon?._product?.toString?.();

                const sameShippingBundleType = coupon.shippingBundleType == shippingBundleType;
                const sameShippingMethodCode = coupon.shippingMethodCode == shippingMethodCode;
                const sameShippingRegionCode = coupon.shippingRegionCode == shippingRegionCode;

                return sameSeller && sameProduct && sameShippingBundleType && sameShippingMethodCode && sameShippingRegionCode;
            });
            let _groupCoupons = groupCoupons.map(({ _id }) => _id);
            shippingGroup = {
                _seller,
                seller,

                _product: needsBundling ? null : _product,
                product: needsBundling ? null : product,

                shippingMethod,
                shippingRegion,

                shippingBundleType,
                shippingMethodCode,
                shippingRegionCode,

                productGroups: [],

                carts: [],
                _carts: [],

                coupons: [...groupCoupons],
                _coupons: [..._groupCoupons],

                coupons__shipping: [...groupCoupons],
                _coupons__shipping: [..._groupCoupons],
            };
            shippingGroups.push(shippingGroup);
        }

        shippingGroup.productGroups.push(productGroup);

        shippingGroup.carts.push(...productGroup.carts);
        shippingGroup._carts.push(...productGroup._carts);

        shippingGroup.coupons.push(...productGroup.coupons);
        shippingGroup._coupons.push(...productGroup._coupons);

        return shippingGroups;
    }, []);
};

export const getCartSellerGroups = function (carts = [], coupons = []) {
    return getCartShippingGroups(carts, coupons).reduce((sellerGroups, shippingGroup) => {
        const { _seller, seller } = shippingGroup;
        let sellerGroup = sellerGroups.find((group) => group?._seller?.toString?.() == _seller?.toString?.());
        if (!sellerGroup) {
            let groupCoupons = coupons.filter((coupon) => {
                const sameSeller = _seller?.toString?.() == coupon?._seller?.toString?.();
                return sameSeller;
            });
            let _groupCoupons = groupCoupons.map(({ _id }) => _id);

            sellerGroup = {
                _seller,
                seller,

                shippingGroups: [],

                carts: [],
                _carts: [],

                coupons: [...groupCoupons],
                _coupons: [..._groupCoupons],
            };
            sellerGroups.push(sellerGroup);
        }
        sellerGroup.shippingGroups.push(shippingGroup);

        sellerGroup.carts.push(...shippingGroup.carts);
        sellerGroup._carts.push(...shippingGroup._carts);

        return sellerGroups;
    }, []);
};

export const getProductPrice = function (carts = []) {
    return carts.reduce((sum, { salePrice, discountPrice, amount }) => sum + (salePrice + discountPrice) * amount, 0);
};

export const getProductSalePrice = function (carts = [], userLevel) {
    // 등급별 할인 선언
    let levelDiscount__productPurchaseAmount = (userLevel?.discounts || []).find(({ type, isActive }) => isActive && type == USER_LEVEL_DISCOUNT_TYPES.PRODUCT_PURCHASE_AMOUNT.value);

    return carts.reduce((sum, { salePrice, amount }) => {
        let price = salePrice;
        if (levelDiscount__productPurchaseAmount) {
            if (levelDiscount__productPurchaseAmount.unit == USER_LEVEL_DISCOUNT_UNITS.PERCENT.value) {
                price -= Math.ceil((price * levelDiscount__productPurchaseAmount.value) / 100);
            }
        }
        price *= amount;
        sum += price;
        return sum;
    }, 0);
};

export const getLevelDiscountPrice = function (carts = [], userLevel) {
    // 등급별 할인 선언
    let levelDiscount__productPurchaseAmount = (userLevel?.discounts || []).find(({ type, isActive }) => isActive && type == USER_LEVEL_DISCOUNT_TYPES.PRODUCT_PURCHASE_AMOUNT.value);

    return carts.reduce((sum, { salePrice, amount }) => {
        let price = 0;
        if (levelDiscount__productPurchaseAmount) {
            if (levelDiscount__productPurchaseAmount.unit == USER_LEVEL_DISCOUNT_UNITS.PERCENT.value) {
                price = Math.ceil((salePrice * levelDiscount__productPurchaseAmount.value) / 100);
            }
        }
        price *= amount;
        sum += price;
        return sum;
    }, 0);
};

export const getServicePrice = function (carts = []) {
    return carts.reduce((sum, { shippingOption, amount }) => {
        if (!shippingOption?.service?.isActive) return sum;
        else return sum + (shippingOption?.service?.charge?.amount || 0) * amount;
    }, 0);
};

export const getDiscountPrice = function (carts = []) {
    return carts.reduce((sum, { discountPrice, amount }) => sum + discountPrice * amount, 0);
};

export const getDeliveryPrice = function (carts = [], receiverLocation, userLevel) {
    return getCartSellerGroups(carts).reduce((totalDeliveryPrice, sellerGroup) => {
        for (const shippingGroup of sellerGroup.shippingGroups) {
            const { shippingBundleType, productGroups, shippingRegion } = shippingGroup;
            const needsBundling = shippingBundleType == SHIPPING_OPTION_BUNDLE_TYPES.BUNDLE.value;

            const { warehouse: { geolocation: warehouseLocation } = {} } = shippingRegion || {};

            let travelDistance = 0;
            if (receiverLocation?.coordinates?.[0] && warehouseLocation?.coordinates?.[0]) {
                travelDistance = getDistanceBetweenLocations(receiverLocation, warehouseLocation);
            }

            const shippingGroupDeliveryPrice = productGroups.reduce((shippingGroupDeliveryPrice, productGroup) => {
                const { shippingOption } = productGroup;

                let productPrice;
                let productGroupDeliveryPrice;
                switch (shippingOption?.charge?.type) {
                    ////////////////////////////////////////////////////////
                    // 착불
                    ////////////////////////////////////////////////////////
                    case SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value: {
                        productGroupDeliveryPrice = 0;
                        break;
                    }

                    ////////////////////////////////////////////////////////
                    // 고정
                    ////////////////////////////////////////////////////////
                    case SHIPPING_OPTION_CHARGE_TYPES.BY_FIXED_AMOUNT.value: {
                        productGroupDeliveryPrice = shippingOption?.charge?.fixedAmount;
                        break;
                    }

                    ////////////////////////////////////////////////////////
                    // 구간별
                    ////////////////////////////////////////////////////////
                    case SHIPPING_OPTION_CHARGE_TYPES.BY_PRICES_RANGE.value: {
                        productPrice = getProductSalePrice(productGroup.carts, userLevel);

                        productGroupDeliveryPrice = [...(shippingOption?.charge?.pricesRange || [])].sort((a, b) => b.price - a.price).find((item) => item.price <= productPrice)?.amount || 0;
                        break;
                    }

                    ////////////////////////////////////////////////////////
                    // 거리별
                    ////////////////////////////////////////////////////////
                    case SHIPPING_OPTION_CHARGE_TYPES.BY_TRAVEL_RANGE.value: {
                        productGroupDeliveryPrice = [...(shippingOption?.charge?.travelRange || [])].sort((a, b) => b.distance - a.distance).find((item) => item.distance <= travelDistance)?.amount || 0;
                        break;
                    }

                    ////////////////////////////////////////////////////////
                    // 기본값
                    ////////////////////////////////////////////////////////
                    default: {
                        productGroupDeliveryPrice = 0;
                        break;
                    }
                }

                ////////////////////////////////////////////////////////////
                // 묶음배송
                ////////////////////////////////////////////////////////////
                if (needsBundling) {
                    const isPreviousLarger = shippingGroupDeliveryPrice > productGroupDeliveryPrice;
                    if (isPreviousLarger) return shippingGroupDeliveryPrice;
                    else return productGroupDeliveryPrice;
                }
                ////////////////////////////////////////////////////////////
                // 개별배송
                ////////////////////////////////////////////////////////////
                else {
                    return shippingGroupDeliveryPrice + productGroupDeliveryPrice;
                }
            }, 0);

            totalDeliveryPrice += shippingGroupDeliveryPrice;
        }
        return totalDeliveryPrice;
    }, 0);
};

/**
 * @typedef {object} CouponPriceSummary
 * @property {number} couponPrice
 * @property {number} couponPrice__totalOrderAmount
 * @property {number} couponPrice__shopProductPrice
 * @property {number} couponPrice__shopDeliveryFare
 */

/**
 * @function GetCouponPrice
 * @param {[]} carts
 * @param {[]} coupons
 * @param {object} receiverLocation
 * @param {object} userLevel
 * @param {boolean} returnsSummary
 * @returns { number | CouponPriceSummary }
 */
export const getCouponPrice = function (carts = [], coupons = [], receiverLocation, userLevel, returnsSummary = false) {
    ////////////////////////////////////////////////////////////////////////////
    // 쿠폰순서 정렬
    ////////////////////////////////////////////////////////////////////////////
    coupons = coupons.reduce((o, coupon) => ({ ...o, [coupon?.usage?.target?.price]: [...(o[coupon?.usage?.target?.price] || [])].concat(coupon) }), {});

    coupons = [...(coupons[USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value] || []), ...(coupons[USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value] || []), ...(coupons[USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value] || [])].map((item) => item);

    const summary = coupons.reduce(
        (
            {
                couponPrice,

                couponPrice__totalOrderAmount,
                couponPrice__shopProductPrice,
                couponPrice__shopDeliveryFare,

                targetPriceMap,
                couponPriceMap,
            },
            coupon
        ) => {
            ////////////////////////////////////////////////////////////////////
            // 쿠폰대상가격 계산
            ////////////////////////////////////////////////////////////////////
            let targetPrice = undefined;
            let targetPriceKey = undefined;
            switch (coupon.usage.target.price) {
                // 전체주문금액 쿠폰
                case USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value: {
                    targetPriceKey = USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value;
                    targetPrice = targetPriceMap.get(targetPriceKey);
                    if (targetPrice === undefined) {
                        // 등급할인 전 금액 기준
                        targetPrice = getProductSalePrice(carts);
                        // targetPrice = getProductSalePrice(carts, userLevel);

                        // 상품가격 쿠폰 할인 금액 제외
                        // if (coupon.usage.combinable) targetPrice -= couponPriceMap.get(USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value) || 0;
                    }
                    break;
                }

                // 상품가격 쿠폰
                case USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value: {
                    targetPriceKey = `${coupon._seller}${coupon._product}`;
                    targetPrice = targetPriceMap.get(targetPriceKey);
                    if (targetPrice === undefined) {
                        // 등급할인 전 금액 기준
                        targetPrice = getProductSalePrice(
                            carts.filter((cart) => {
                                // const sameSeller = cart._seller?.toString?.() == coupon._seller?.toString?.();
                                const sameProduct = cart._product?.toString?.() == coupon._product?.toString?.();

                                // return sameSeller && sameProduct;
                                return sameProduct;
                            })
                            // ,userLevel
                        );
                    }
                    break;
                }

                // 배송비 쿠폰
                case USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value: {
                    targetPriceKey = `${coupon._seller || ""}${coupon._product || ""}${coupon.shippingBundleType || ""}${coupon.shippingMethodCode || ""}${coupon.shippingRegionCode || ""}`;
                    targetPrice = targetPriceMap.get(targetPriceKey);

                    if (targetPrice === undefined) {
                        targetPrice = getDeliveryPrice(
                            carts.filter((cart) => {
                                const sameSeller = cart._seller?.toString?.() == coupon._seller?.toString?.();
                                const sameProduct = cart._product?.toString?.() == coupon._product?.toString?.();
                                const sameBundleType = cart.shippingBundleType == coupon.shippingBundleType;
                                const sameMethodCode = cart.shippingMethodCode == coupon.shippingMethodCode;
                                const sameRegionCode = cart.shippingRegionCode == coupon.shippingRegionCode;

                                return sameSeller && sameProduct && sameBundleType && sameMethodCode && sameRegionCode;
                            }),
                            receiverLocation,
                            userLevel
                        );
                    }
                    break;
                }
            }

            ////////////////////////////////////////////////////////////////////
            // 쿠폰적용가격 계산
            ////////////////////////////////////////////////////////////////////
            let __couponPrice = 0;
            switch (coupon.unit) {
                case SHOP_COUPON_UNITS.AMOUNT.value: {
                    __couponPrice = coupon.value;
                    break;
                }
                case SHOP_COUPON_UNITS.PERCENT.value: {
                    __couponPrice = Math.ceil((targetPrice * coupon.value) / 100);
                    if (!!coupon.limit && coupon.limit < __couponPrice) __couponPrice = coupon.limit;
                    break;
                }
            }
            if (targetPrice < __couponPrice) __couponPrice = targetPrice;

            ////////////////////////////////////////////////////////////////////
            // 결과 데이터바인딩
            ////////////////////////////////////////////////////////////////////
            couponPrice += __couponPrice;

            switch (coupon.usage.target.price) {
                case USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value: {
                    couponPrice__totalOrderAmount += __couponPrice;
                    break;
                }
                case USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value: {
                    couponPrice__shopProductPrice += __couponPrice;
                    break;
                }
                case USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value: {
                    couponPrice__shopDeliveryFare += __couponPrice;
                    break;
                }
            }

            targetPriceMap.set(targetPriceKey, targetPrice - __couponPrice);

            const couponPriceKey = coupon.usage.target.price;
            __couponPrice += couponPriceMap.get(couponPriceKey) || 0;
            couponPriceMap.set(couponPriceKey, __couponPrice);

            return {
                couponPrice,

                couponPrice__totalOrderAmount,
                couponPrice__shopProductPrice,
                couponPrice__shopDeliveryFare,

                targetPriceMap,
                couponPriceMap,
            };
        },
        {
            couponPrice: 0,

            couponPrice__totalOrderAmount: 0,
            couponPrice__shopProductPrice: 0,
            couponPrice__shopDeliveryFare: 0,

            targetPriceMap: new Map(),
            couponPriceMap: new Map(),
        }
    );
    if (returnsSummary) return summary;
    else return summary.couponPrice;
};

export const getIslandPrice = function (carts = [], islands = [], postcode) {
    if (!postcode) return 0;

    const island = islands.find((island) => island.areas.find((area) => area.postcode == postcode));
    if (!island) return 0;

    return getCartSellerGroups(carts).reduce((totalIslandPrice, sellerGroup) => {
        for (const shippingGroup of sellerGroup.shippingGroups) {
            const { shippingBundleType, productGroups } = shippingGroup;
            const needsBundling = shippingBundleType == SHIPPING_OPTION_BUNDLE_TYPES.BUNDLE.value;

            const shippingGroupIslandPrice = productGroups.reduce((shippingGroupIslandPrice, productGroup) => {
                const { shippingOption } = productGroup;

                let productGroupIslandPrice = 0;
                if (shippingOption.island.isActive) {
                    switch (island.code) {
                        case "normal": {
                            productGroupIslandPrice = shippingOption.island.charge.amount__base;
                            break;
                        }
                        case "jeju": {
                            productGroupIslandPrice = shippingOption.island.charge.amount__jeju;
                            break;
                        }
                    }
                }

                ////////////////////////////////////////////////////////////
                // 묶음배송
                ////////////////////////////////////////////////////////////
                if (needsBundling) {
                    const isPreviousLarger = shippingGroupIslandPrice > productGroupIslandPrice;
                    if (isPreviousLarger) return shippingGroupIslandPrice;
                    else return productGroupIslandPrice;
                }
                ////////////////////////////////////////////////////////////
                // 개별배송
                ////////////////////////////////////////////////////////////
                else {
                    return shippingGroupIslandPrice + productGroupIslandPrice;
                }
            }, 0);

            totalIslandPrice += shippingGroupIslandPrice;
        }

        return totalIslandPrice;
    }, 0);
};

export const getIsShippingAvailable = function (carts = [], receiver) {
    if (!receiver.address1) return true;

    return !getCartSellerGroups(carts).some((sellerGroup) => {
        for (const shippingGroup of sellerGroup.shippingGroups) {
            for (const productGroup of shippingGroup.productGroups) {
                const { areas__available = [] } = productGroup.shippingOption || {};
                if (areas__available.length < 1) continue;

                let isShippingDisabled = !areas__available.some((sido) => receiver?.address1?.indexOf?.(sido) == 0);
                if (isShippingDisabled) return true;
            }
        }

        return false;
    });
};

export const getStatusText = function ({ purchaseStatus, claimStatus, orderStatusMessage, claimStatusMessage } = {}) {
    if (purchaseStatus == PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value) {
        return "구매확정";
    }

    switch (claimStatus) {
        case PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value:
        case PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value:
        case PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value:
        case PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_SHIPPING.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_DELAYED.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_PENDING.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value: {
            return claimStatusMessage;
        }
        case PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value:
        case PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value:
        case PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value: {
            return (() => {
                let text = "";
                if (orderStatusMessage) text += orderStatusMessage;
                if (claimStatusMessage) text += text ? ` / ${claimStatusMessage}` : claimStatusMessage;
                return text;
            })();
        }
    }
    return orderStatusMessage;
};

export const decode__productOptionName = (name = "") => [...name.split(" / ")].map((item) => item.split(": ").map(decodeURIComponent).join(": ")).join(" / ");

const ShopMethods = {
    install: function (Vue) {
        Vue.prototype.$getCartProductGroups = getCartProductGroups;

        Vue.prototype.$getCartShippingGroups = getCartShippingGroups;

        Vue.prototype.$getCartSellerGroups = getCartSellerGroups;

        Vue.prototype.$getProductPrice = getProductPrice;

        Vue.prototype.$getProductSalePrice = getProductSalePrice;

        Vue.prototype.$getLevelDiscountPrice = getLevelDiscountPrice;

        Vue.prototype.$getServicePrice = getServicePrice;

        Vue.prototype.$getDiscountPrice = getDiscountPrice;

        Vue.prototype.$getCouponPrice = getCouponPrice;

        Vue.prototype.$getDeliveryPrice = getDeliveryPrice;

        Vue.prototype.$getIslandPrice = getIslandPrice;

        Vue.prototype.$getIsShippingAvailable = getIsShippingAvailable;

        Vue.prototype.$getStatusText = getStatusText;

        Vue.prototype.$decode__productOptionName = decode__productOptionName;
    },
};

Vue.use(ShopMethods);
