var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": "",
      "to": `${_vm.path}/${_vm.value._id}`
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('v-card', {
    staticClass: "border",
    attrs: {
      "tile": "",
      "flat": "",
      "img": _vm.value.storyThumbSrc
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xxs font-weight-medium text-truncate pa-0 d-block"
  }, [_vm._v(_vm._s(_vm.value.subject))]), _c('v-card-text', {
    staticClass: "txt text-truncate-3 pa-0"
  }, [_vm._v(_vm._s(_vm.content))])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }