<template>
    <v-row justify="center" class="pt-18px pt-lg-46px">
        <v-col cols="auto">
            <div class="tit-wrap tit-wrap--lg">
                <h3 class="tit font-weight-light">검색어와 일치하는 내용이 없습니다.</h3>
            </div>
            <div class="txt txt--dark font-weight-medium mb-16px">검색 TIP</div>
            <div class="txt">
                <p class="dot">단어의 철자가 정확한지 확인해주세요.</p>
                <p class="dot">검색어의 단어수를 줄이거나 다른 검색어로 검색해보세요.</p>
                <p class="dot">보다 일반적인 단어로 다시 검색해 보세요.</p>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {};
</script>

<style>
</style>