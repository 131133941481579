import axios from "@/plugins/axios";

let url = "/api/v1/files";

export default {
    // gets(data){
    //     return axios.get(url, data).then(result => result.data);
    // },
    // get(data){
    //     return axios.get(`${url}/${data._id}`).then(result => result.data);
    // },
    post({ userId, path, _index, index }, file) {
        var headers = { "Content-Type": "multipart/form-data" };

        var formData = new FormData();

        if (userId) formData.append("userId", userId);
        if (path) formData.append("path", path);
        if (_index) formData.append("_index", _index);
        if (index !== undefined) formData.append("index", index);

        formData.append("file", file);

        return axios.post(`${url}`, formData, { headers }).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
