var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', {
    staticClass: "rank-expansion-panels",
    attrs: {
      "flat": "",
      "tile": ""
    },
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm.shows !== 0 ? [_c('contents-card-rank-title', _vm._b({}, 'contents-card-rank-title', {
    logs: _vm.logs
  }, false))] : [_c('h3', {
    staticClass: "tit tit--xxs font-weight-medium primary--text"
  }, [_vm._v("실시간 검색어")])]], 2), _c('v-expansion-panel-content', {
    attrs: {
      "eager": ""
    }
  }, [_c('search-rank-list', {
    on: {
      "input": function ($event) {
        return _vm.$emit('input');
      }
    },
    model: {
      value: _vm.logs,
      callback: function ($$v) {
        _vm.logs = $$v;
      },
      expression: "logs"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }