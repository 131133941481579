var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-32px"
  }, [!_vm.mode ? _c('search-list-template', _vm._b({}, 'search-list-template', {
    totalCount: _vm.summary.totalCount,
    mode: _vm.SEARCH_LIST.PRODUCT.value
  }, false)) : _vm._e(), _c('shop-product-items', _vm._l(_vm.products, function (product, index) {
    return _c('shop-product-item', {
      key: index,
      attrs: {
        "value": product,
        "sm": "6",
        "md": "6",
        "lg": "3"
      }
    });
  }), 1), _vm.mode ? _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }