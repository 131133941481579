import axios from "@/plugins/axios";

let url = "/api/v1/me/coupons";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    putProduct(data) {
        return axios.put(`${url}/${data._id}/product`, data).then((result) => result.data);
    },
    putShipping(data) {
        return axios.put(`${url}/${data._id}/shipping`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
