export const USAGE_TARGET_TYPES = {
    ALLITEMS: { value: "ALLITEMS", text: "전체" },
    PRODUCTS: { value: "PRODUCTS", text: "상품" },
    EXHIBITS: { value: "EXHIBITS", text: "기획전" },
    DISPLAYS: { value: "DISPLAYS", text: "상품전시" },
    CATEGORY: { value: "CATEGORY", text: "카테고리" },
};

export const USAGE_TARGET_PRICES = {
    TOTAL_ORDER_AMOUNT: { value: "TOTAL_ORDER_AMOUNT", text: "전체주문금액" },
    SHOP_PRODUCT_PRICE: { value: "SHOP_PRODUCT_PRICE", text: "상품가격" },
    SHOP_DELIVERY_FARE: { value: "SHOP_DELIVERY_FARE", text: "배송비" },
};
