var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._t("activator", null, null, {
    active: _vm.active,
    toggle: _vm.toggle,
    loading: _vm.loading
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }