<template>
    <v-row>
        <!-- <map-main-sheet v-bind="{ lat, lng, lvl, maxLevel: 7, locations: clusters }" @search="search"/> -->
        <map-main-sheet v-bind="{ lat, lng, lvl, maxLevel: 8, locations }" @search="search"/>
        <map-main-list/>
    </v-row>
</template>

<script>
import api from "@/api";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import MapMainSheet from './map-main-sheet.vue';
import MapMainList from './map-main-list.vue';
export default {
    components:{
        MapMainSheet,
        MapMainList
    },
    data: ()=> ({
        // clusters: [],
        locations: [],
        showsSearch: false,
    }),
    computed: {
        ...mapState("location", ["lat", "lng", "lvl","bounds"]),
        ...mapGetters("location", ["geolocation", "geometry"]),
        queryLat() {
            return +this.$route.query.lat || null;
        },
        queryLng() {
            return +this.$route.query.lng || null;
        },
        queryLvl() {
            return +this.$route.query.lvl || null;
        },
        searchValue() {
            return this.$route.query.searchValue || null;
        },
        coordinates() {
            const { lat, lng } = this.$route.query;
            return `${lat}${lng}`;
        },
    },
    methods: {
        ...mapMutations("location", ["setCoordinates", "setLvl", "setBounds"]),
        ...mapActions("location", ["getCurrentLocation", "getSidoGugun", "getAddressLocation"]),
        async search({ withoutRouting = false } = {}){
            try{
                const { lat, lng, lvl } = this;
                if (!withoutRouting) this.$router.push({ query: { ...this.$route.query, lat, lng, lvl } });

                const { bounds, geolocation: near } = this;

                const { locations } = await api.v1.map.getMapClusters({ params: {bounds, near}});
                this.locations = locations;
                // const { clusters } = await api.v1.map.getMapClusters({ params: {bounds, near}});
                // this.clusters = clusters;

                this.$nextTick(() => {
                this.showsSearch = true;
            });

            } catch(error){
                this.$handleError(error);
            }
        }
    },
    mounted(){
        if (!!this.queryLat && !!this.queryLng) {
            const lat = this.queryLat;
            const lng = this.queryLng;
            const lvl = this.queryLvl || 7;

            this.setLvl({ lvl });
            this.setCoordinates({ lat, lng });
        }
        else this.getCurrentLocation();
    },
    watch:{
        coordinates(){
            if(!this.$route.query._location) this.search({ withoutRouting: true });
        }
    },
    // destroyed(){
    //     this.setBounds();
    // },
}
</script>

<style>
</style>