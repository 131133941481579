/** @type {import("vuex").Module} */
export const ui = {
    namespaced: true,
    getters: {
        /** @returns {GnbItem[]} */
        gnbs(state, geters, rootState) {
            const {
                categories,
                payload,
                scope,
                story: { categories: categories__story },
            } = rootState;

            return [
                {
                    text: "Category",
                    value: { path: "/shop/products?category=elctric-bicycle" },
                    children: categories.map(formatShopCategory),
                    showsChildrenOnPC: true,
                },
                {
                    text: "Best",
                    value: {
                        path: "/shop/best",
                    },
                    children: [
                        {
                            text: "전체보기",
                            value: {
                                path: "/shop/best",
                            },
                        },
                        {
                            text: "전기자전거",
                            value: {
                                path: "/shop/best",
                                query: {
                                    category: "elctric-bicycle",
                                },
                            },
                        },
                        {
                            text: "전동스쿠터",
                            value: {
                                path: "/shop/best",
                                query: {
                                    category: "elctric-scooter",
                                },
                            },
                        },
                        {
                            text: "전동킥보드",
                            value: {
                                path: "/shop/best",
                                query: {
                                    category: "electric-kickboard",
                                },
                            },
                        },
                        {
                            text: "악세서리",
                            value: {
                                path: "/shop/best",
                                query: {
                                    category: "accessory",
                                },
                            },
                        },
                    ],
                },
                {
                    text: "Brand",
                    value: { path: "/shop/brands" },
                },
                {
                    text: "Event",
                    value: { path: "/sub/event" },
                    children: [
                        {
                            text: "진행중인 이벤트",
                            value: { path: "/sub/event/ongoing" },
                        },
                        {
                            text: "종료된 이벤트",
                            value: { path: "/sub/event/ended" },
                        },
                        {
                            text: "태그 이벤트",
                            value: { path: "/sub/event/tag" },
                        },
                        {
                            text: "기획전",
                            value: { path: "/sub/event/exhibition" },
                        },
                        {
                            text: "쿠폰",
                            value: { path: "/sub/event/coupon" },
                        },
                    ],
                },
                {
                    text: "Story",
                    value: { path: "/story" },
                    children: categories__story.reduce(formatStoryCategory, []),
                },
                {
                    text: "Service",
                    value: { path: "/sub/store" },
                    children: [
                        {
                            text: "전체보기",
                            value: { path: "/sub/store" },
                        },
                        {
                            text: "전기자전거",
                            value: { path: "/sub/store/bicycle" },
                        },
                        {
                            text: "전동스쿠터",
                            value: { path: "/sub/store/scooter" },
                        },
                        {
                            text: "전동킥보드",
                            value: { path: "/sub/store/kickboard" },
                        },
                    ],
                },
                {
                    text: "Mini home",
                    value: { path: `/minishop/${payload?._user}` },
                    hidesMenuOnPC: true,
                    hasAccessToken: true,
                },
                {
                    text: "Business",
                    value: { path: "/sub/business/about" },
                    hidesMenuOnPC: true,
                    children: [
                        {
                            text: "회사소개",
                            value: { path: "/sub/business/about" },
                        },
                        {
                            text: "Contact Us",
                            value: { path: "/sub/business/contact" },
                        },
                        {
                            text: "이용약관",
                            value: { path: "/sub/business/terms" },
                        },
                        {
                            text: "개인정보처리방침",
                            value: { path: "/sub/business/privacy-policy" },
                        },
                        {
                            text: "이용안내",
                            value: { path: "/sub/business/site-guide" },
                        },
                    ],
                },
                {
                    text: "CScenter",
                    value: { path: "/center/notice" },
                    hidesMenu: true,
                    children: [
                        {
                            text: "공지사항",
                            value: { path: "/center/notice" },
                        },
                        {
                            text: "자주 묻는 질문",
                            value: { path: "/center/faq" },
                        },
                        {
                            text: "1:1 문의",
                            value: { path: "/center/questions" },
                        },
                    ],
                },
            ];

            /** @returns {GnbItem} */
            function formatShopCategory(category) {
                let item = {
                    text: category.name,
                    value: { path: "/shop/products", query: { category: category.code } },
                    children: (category?.children || []).map(formatShopCategory),
                };

                // if (item.children.length < 1) delete item.children;
                if (!item.children) delete item.children;

                return item;
            }

            /** @returns {GnbItem} */
            function formatStoryCategory(categories, category) {
                let item = {
                    text: category.name,
                    value: { path: `/story/${category.code}` },
                    children: (category?.children || []).reduce(formatStoryCategory, []),
                };

                if (item.children.length < 1) delete item.children;

                if (category.scope__main.length < 1) categories.push(item);
                else if (category.scope__main.some((item) => scope.includes(item))) {
                    categories.push(item);
                }

                return categories;
            }

            /**
             * @typedef GnbItem
             * @property {string} text
             * @property {object} value
             * @property {string} value.path
             * @property {object} value.query
             * @property {GnbItem[]} children
             * @property {boolean} showsChildrenOnPC
             */
        },
    },
};

export default ui;
