export const initUserMoneyWithdrawal = (withdrawal = {}) => ({
    ...withdrawal,

    _user: withdrawal._user ?? null,
    _money: withdrawal._money ?? null,

    code: withdrawal.code ?? null,
    memo: withdrawal.memo ?? null,

    amount: withdrawal.amount ?? 0,
    remark: withdrawal.remark ?? null,

    isCanceled: withdrawal.isCanceled ?? false,
    isWithdrawn: withdrawal.isWithdrawn ?? false,
});
