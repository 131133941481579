var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.mobile ? _c('v-list', {
    staticClass: "mobile-sidebar__list"
  }, [_vm._l(_vm.items, function (item, index) {
    return [!item.hidesMenu ? [item.children ? _c('v-list-group', {
      key: index,
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-content', [_c('v-list-item-title', {
            staticClass: "tit tit--xl font-weight-semibold"
          }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)];
        },
        proxy: true
      }], null, true)
    }, [_vm._l(item.children, function (child, index) {
      return [child.children ? _c('v-list-group', {
        key: index,
        attrs: {
          "sub-group": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function () {
            return [_c('v-list-item-title', [_vm._v(" " + _vm._s(child.text) + " ")])];
          },
          proxy: true
        }], null, true)
      }, [_c('v-list-item', {
        attrs: {
          "to": child.value
        }
      }, [_c('v-list-item-title', [_vm._v("전체보기")])], 1), _vm._l(child.children, function (grandChild, index) {
        return [_c('v-list-item', {
          key: index,
          attrs: {
            "to": grandChild.value
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(grandChild.text) + " ")])], 1)], 1)];
      })], 2) : _c('v-list-item', {
        key: child.text,
        attrs: {
          "to": child.value
        }
      }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(child.text) + " ")])], 1)], 1)];
    })], 2) : [_c('v-list-item', {
      key: item.text,
      on: {
        "click": function ($event) {
          return _vm.go(item);
        }
      }
    }, [_c('v-list-item-title', {
      staticClass: "tit tit--xl font-weight-semibold"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)]] : _vm._e()];
  })], 2) : _c('div', {
    staticClass: "header-menu"
  }, [_c('ul', {
    staticClass: "header-menu__lists"
  }, [_vm._l(_vm.items, function (item, index) {
    return [!item.hidesMenuOnPC && !item.hidesMenu ? _c('li', {
      key: index,
      staticClass: "headder-menu__list"
    }, [_c('div', {
      staticClass: "header-category"
    }, [_c('router-link', {
      staticClass: "header-menu__list-link",
      attrs: {
        "to": item.value
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.showsChildrenOnPC ? [_c('div', {
      staticClass: "header-category__full"
    }, [_c('main-shop-categories', {
      attrs: {
        "pc": ""
      }
    })], 1)] : _vm._e()], 2)]) : _vm._e()];
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }