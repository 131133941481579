var render = function render(){
  var _vm$value, _vm$value2, _vm$value2$alarm, _vm$value3, _vm$value4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rounded-sm px-20px px-lg-24px py-18px",
    attrs: {
      "elevation": "8"
    },
    on: {
      "click": _vm.go
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('v-row', {
    staticClass: "ma-n4px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-badge', {
    attrs: {
      "value": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.shows,
      "dot": "",
      "color": "accent2"
    }
  }, [_c('v-card-title', {
    staticClass: "pa-0 txt txt--dark font-weight-medium"
  }, [_vm._v(_vm._s(((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$alarm = _vm$value2.alarm) === null || _vm$value2$alarm === void 0 ? void 0 : _vm$value2$alarm.name) || ((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.title)))])], 1)], 1), _c('v-spacer'), _c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pa-0 txt txt--sm"
  }, [_vm._v(_vm._s(_vm.value.createdAt.toDate()))])]), _c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card-actions', [_c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-small",
          attrs: {
            "icon": "",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1)];
      }
    }])
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "color": "#fff"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("삭제")])], 1)], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "txt pa-0"
  }, [_c('p', {
    staticClass: "grey--text text-truncate-2"
  }, [_vm._v(_vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.content))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }