import dayjs from "@/plugins/vue-dayjs";

/**
 * 기본 데이터
 * @param {TaxinvoiceBaseData} data
 * @returns {TaxinvoiceBaseData}
 */
export const initShopTaxinvoiceBase = (data = {}) => ({
    issueType: data.issueType || "정발행",
    taxType: data.taxType || "과세",
    chargeDirection: data.chargeDirection || "정과금",
    writeDate: data.writeDate || dayjs().format("YYYYMMDD"),
    purposeType: data.purposeType || "없음",
});

export default initShopTaxinvoiceBase;

/**
 * @typedef {object} TaxinvoiceBaseData 기본 데이터
 *
 * @property {'정발행' | '역발행' | '위수탁'} issueType 1.발행형태 (택 1)
 *
 * @property {'과세' | '영세' | '면세'} taxType 2.과세형태 (택 1)
 *
 * @property {'정과금' | '역과금'} chargeDirection 3.과금방향 (택 1)
 * * 정과금: 공급자 과금
 * * 역과금: 공급받는자 과금
 * * {issueType}="정발행", "역발행", "위수탁" 경우 정과금 가능
 * * {issueType}="역발행 경우 역과금 가능
 *
 * @property {string} writeDate 7.작성일자
 * * 형식: yyyyMMdd
 *
 * @property {'영수' | '청구' | '없음'} purposeType 8.결제대금 수취여부 (택 1)
 */
