<template>
    <v-btn @click="$vuetify.goTo(target, options)" v-scroll="onScroll" fixed icon class="button-top">
        <v-icon large>arrow_drop_up</v-icon>
        <span class="font-size-10 font-weight-semibold font-secondary">TOP</span>
    </v-btn>
</template>

<script>
export default {
    data: () => ({
        type: "number",
        number: 0,
        duration: 50,
        offset: 0,
        easing: "easeInOutCubic",
    }),
    methods: {
        onScroll: function () {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const asideButtonGroup = document.querySelector(".button-top");

            if (asideButtonGroup !== null) {
                if (scrollTop > window.innerHeight / 4) {
                    asideButtonGroup.classList.add("active");
                } else {
                    asideButtonGroup.classList.remove("active");
                }
            }
        },
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.button-top {
    display: none;
}
@media (min-width: 1024px) {
    .button-top {
        display: flex;
        color: var(--v-primary-base) !important;
        background-color: #fff !important;
        right: 12px;
        top: calc(50% + 234px);
        min-width: 62px !important;
        height: 62px !important;
        z-index: 100;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
        border: 1px solid var(--border-color);
        ::v-deep() {
            .v-btn__content {
                flex-direction: column;
            }
        }

        opacity: 0;
        visibility: hidden;
        transform: translateY(40px);
        transition: all ease-out 0.2s;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
@media (min-width: 1800px) {
    .button-top {
        right: 120px;
    }
}
</style>