<template>
    <v-sheet>
        <band-banner v-for="item in items" :key="item._id" v-bind="{ item, isPc }" />
    </v-sheet>
</template>

<script>
import api from "@/api";
import { mapMutations, mapState } from "vuex";
import BandBanner from "./band-banner.vue";
export default {
    components: { BandBanner },
    computed: {
        ...mapState({ items: (state) => state.bandBanners.items }),
    },
    data() {
        return {
            isPc: window.innerWidth > 1024,
            resizeListener: null,
        };
    },
    methods: {
        ...mapMutations("bandBanners", ["setItems"]),
        async init() {
            try {
                const { bandBanners } = await api.v1.bandBanners.gets({ params: { code: "main" } });
                this.setItems({ bandBanners });
            } catch (error) {}
        },
        setResizeListener() {
            const resizeListener = () => (this.isPc = window.innerWidth > 1024);
            window.addEventListener("resize", resizeListener);
            this.resizeListener = resizeListener;
        },
    },
    mounted() {
        this.init();
        this.setResizeListener();
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeListener);
    },
};
</script>
