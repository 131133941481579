<template>
    <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" class="product-item">
        <slot name="tag" />
        <div class="product-item__inner">
            <v-spacer v-if="crown" class="crown-space" />
            <router-link :to="`/shop/products/${value?._id}`">
                <!-- <div class="product-item__img" :style="`background-image:url(${value?.thumb?.url});`"> -->
                <v-card class="product-item__img" :img="value?.thumb?.url">
                    <div v-if="!value?.sells || value?.stock == 0" class="product-item__badge">Sold Out</div>
                    <!-- 개발확인 -->
                    <!-- <div class="product-item__color-option">
                        3개 미만
                        <div>
                            <v-row align="center" class="row--xs">
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #9c6767" />
                                </v-col>
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #b1a321" />
                                </v-col>
                            </v-row>
                        </div>
                        3개 이상
                        <div>
                            <v-row align="center" class="row--xs">
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #d1d1d8" />
                                </v-col>
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #b1aca6" />
                                </v-col>
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #c2c0b5" />
                                </v-col>
                                <v-col cols="auto">
                                    <div class="txt txt--xs white--text">+13</div>
                                </v-col>
                            </v-row>
                        </div>
                    </div> -->
                </v-card>
                <div class="product-item__con">
                    <div class="txt txt--sm mb-4px">{{ value?.seller?.nickname }}</div>
                    <!-- <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="value?.flags?.length">
                        {{ value?.flags?.map((text) => `[${text}]`).join(" ") }}
                    </div> -->
                    <div class="product-item__tit">
                        <span>{{ value?.name }} {{ value?.code || "" }}</span>
                    </div>
                    <!-- <div class="mt-10px mt-md-14px">
                        <div class="d-flex align-center">
                            <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="+(value?.meta?.reviews?.star__average || 0).toFixed(1)" />
                            <span class="material-icons-outlined primary--text">star</span>
                            <span class="font-size-14 font-weight-medium grey--text">{{ (value?.meta?.reviews?.star__average || 0).toFixed(1) }}</span>
                            <span class="font-size-14 grey--text text--lighten-1 pl-4px">({{ value?.meta?.reviews?.count__total?.format?.() || 0 }})</span>
                        </div>
                    </div> -->
                    <div class="my-6px my-md-8px">
                        <em v-if="!value?.sells || value?.stock == 0" class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
                        <div v-else class="d-flex flex-wrap align-center txt font-weight-bold">
                            <span class="d-flex flex-wrap align-center accent1--text">{{ value?.salePrice.format() }}원</span>
                            <span v-if="value.discountPrice" class="font-size-14 grey--text text--lighten-1 font-weight-regular text-decoration-line-through mx-4px">{{ value?.price.format() }}</span>
                            <span v-if="value.discountRate" class="accent2--text">{{ value?.discountRate }}%</span>
                        </div>
                    </div>
                    <like-btn :params="{ _product: value?._id }" @input="setLikeCount">
                        <template #activator="{ active, toggle }">
                            <v-row align="center" class="row--xxs">
                                <v-col v-if="wishlist">
                                    <v-btn v-bind="{ ...btn_primary }" block>장바구니 담기</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_txt }" x-small class="product-item__favorite" @click.stop.prevent="toggle">
                                        <v-icon v-if="active" class="material-icons-outlined font-size-14 font-size-lg-16">favorite</v-icon>
                                        <v-icon v-else class="material-icons-outlined font-size-14 font-size-lg-16">favorite_border</v-icon>
                                        <span v-if="!wishlist" class="ml-2px">{{ likeCount }}</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </like-btn>
                    <!-- <div class="mt-4px mt-md-8px">
                        <v-row class="row--xxs">
                            <v-col v-if="value.NEW_ARRIVAL" cols="auto"><v-chip small color="grey lighten-5" text-color="primary">신상품</v-chip></v-col>
                            <v-col v-if="value.POPULAR" cols="auto"><v-chip small color="grey lighten-5" text-color="secondary">POPULAR</v-chip></v-col>
                            <v-col cols="auto"><v-chip small color="grey lighten-5" text-color="grey darken-4">당일배송</v-chip></v-col>
                        </v-row>
                    </div> -->
                </div>

                <v-card v-if="rank" color="primary" :rounded="crown ? 'pill' : '0'" :class="crown ? 'product-rank--crown' : ''" class="product-rank">
                    <v-responsive :aspect-ratio="1 / 1" class="align-center">
                        <p class="tit tit--xxs white--text font-secondary text-center">{{ rank }}</p>
                    </v-responsive>
                </v-card>
            </router-link>

            <v-card v-if="crown" class="product-crown">
                <v-img max-width="36" src="/images/icon/icon-crown.svg"></v-img>
                <span class="txt txt--sm txt--dark font-weight-medium">BEST</span>
            </v-card>
            <slot name="actions" />
        </div>
    </v-col>
</template>

<script>
import LikeBtn from "../like/like-btn.vue";
import { btn_primary, btn_txt } from "@/assets/variables";

export default {
    components: {
        LikeBtn,
    },
    props: {
        value: { type: Object, default: null },

        cols: { type: String, default: "6" },
        sm: { type: String, default: "4" },
        md: { type: String, default: "4" },
        lg: { type: String, default: "4" },

        rank: { type: Number, default: null },

        crown: { type: Boolean, default: false },
        wishlist: { type: Boolean, default: false },
    },
    data: () => ({
        btn_primary,
        btn_txt,
        likeCount: 0
    }),
    methods: {
        sync(){
            this.likeCount = this.value.meta.likeCount;
        },
        setLikeCount(count){
            this.likeCount = this.likeCount + count;
        }
    },
    mounted(){
        this.sync();
    },
};
</script>

<style lang="scss" scoped>
.crown-space {
    height: 70px;
}
.product-item__inner {
    position: relative;
    .product-crown {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    > a {
        position: relative;
        .product-rank {
            position: absolute;
            top: 0;
            left: 0;
            width: 32px;
            &--crown {
                width: 36px;
                transform: translate(-4px, -4px);
            }
        }
    }
}
@media (min-width: 1024px) {
    .product-item__inner {
        > a {
            .product-rank {
                width: 32px;
                &--crown {
                    width: 40px;
                    transform: translate(-10px, -10px);
                }
            }
        }
    }
}
</style>