import alarms from "./alarms";
import brands from "./brands";
import categories from "./categories";
import categoryDetails from "./category-details";
import codes from "./codes";
import coupons from "./coupons";
import deliveries from "./deliveries";
import displays from "./displays";
import events from "./events";
import exhibitions from "./exhibitions";
import inquires from "./inquires";
import invoices from "./invoices";
import islands from "./islands";
import moneys from "./moneys";
import orders from "./orders";
import points from "./points";
import products from "./products";
import purchases from "./purchases";
import reviews from "./reviews";
import schedules from "./schedules";
import services from "./services";
import shippings from "./shippings";
import showrooms from "./showrooms";
import tags from "./tags";
import taxinvoices from "./taxinvoices";

export default {
    alarms,
    brands,
    categories,
    categoryDetails,
    codes,
    coupons,
    deliveries,
    displays,
    events,
    exhibitions,
    inquires,
    invoices,
    islands,
    moneys,
    orders,
    points,
    products,
    purchases,
    reviews,
    schedules,
    services,
    shippings,
    showrooms,
    tags,
    taxinvoices,
};
