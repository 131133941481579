export const RESERVATION_STATUS = {
    RESERVATION_AWAITING : { value : "RESERVATION_AWAITING", text : "예약대기" },
    RESERVATION_COMPLETED : { value : "RESERVATION_COMPLETED", text : "예약확정" },
    RESERVATION_CANCELED : { value : "RESERVATION_CANCELED", text : "예약취소" },
};

export const RESERVATION_PAYMENT_STATUS = {
    PAYMENT_AWAITING : { value : "PAYMENT_AWAITING", text : "결제대기" },
    PAYMENT_COMPLETED : { value : "PAYMENT_COMPLETED", text : "결제완료" },
    PAYMENT_CANCELED : { value : "PAYMENT_CANCELED", text : "결제취소" },
};

export const COMPANY_CATEGORY_TYPES = {
    ELC_BICYCLE: { value: "ELC_BICYCLE", text: "전기자전거" },
    ELC_SCOOTER: { value: "ELC_SCOOTER", text: "전동스쿠터" },
    ELC_KICKBOARD: { value: "ELC_KICKBOARD", text: "전동킥보드" },
};