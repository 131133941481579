export const initShopReview = (review = {}) => ({
    ...review,

    _seller: review?._seller || null,
    _product: review?._product || null,
    _purchase: review?._purchase || null,

    subject: review?.subject || null,
    content: review?.content || null,
    reply: review?.reply || null,
    star: review?.star || null,
    images: review?.images || [],
    product: review?.product || null,
});
