import bests from "./bests";
import brands from "./brands";
import categories from "./categories";
import categoryDetails from "./category-details";
import coupons from "./coupons";
import displays from "./displays";
import events from "./events";
import exhibitions from "./exhibitions";
import inquires from "./inquires";
import islands from "./islands";
import logs from "./logs";
import orders from "./orders";
import products from "./products";
import points from "./points";
import reservations from "./reservations";
import reviewImages from "./review-images";
import reviews from "./reviews";
import schedules from "./schedules";
import services from "./services";
import shippings from "./shippings";
import showrooms from "./showrooms";
import stores from "./stores";
import tagEvents from "./tag-events";
import tagLogs from "./tag-logs";

export default {
    bests,
    brands,
    categories,
    categoryDetails,
    coupons,
    displays,
    events,
    exhibitions,
    inquires,
    islands,
    logs,
    orders,
    products,
    points,
    reservations,
    reviewImages,
    reviews,
    schedules,
    services,
    shippings,
    showrooms,
    stores,
    tagEvents,
    tagLogs
};
