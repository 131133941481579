export const initCenterContacts = (form = {}) => ({
    ...form,

    company: form.company || null,
    homepage: form.homepage || null,
    content: form.content || null,

    writer: {
        ...(form.writer || {}),
        name: form.writer?.name || null,
        email: form.writer?.email || null,
        tel: form.writer?.tel || null,
        phone: form.writer?.phone || null,
    },
});
