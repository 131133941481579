import axios from "@/plugins/axios";

let url = "/api/console/analytics";

export default {
    getSales(data){
        return axios.get(`${url}/sales`, data).then(result => result.data);
    },
    getReommendationTags(data){
        return axios.get(`${url}/tags/recommendations`, data).then(result => result.data);
    },
    getProductsTags(data){
        return axios.get(`${url}/tags/products`, data).then(result => result.data);
    },
    getBanners(data){
        return axios.get(`${url}/banners`, data).then(result => result.data);
    },
    getVisits(data){
        return axios.get(`${url}/visits`, data).then(result => result.data);
    },
    // get(data){
    //     return axios.get(`${url}/${data._id}`).then(result => result.data);
    // },
    // post(data){
    //     return axios.post(url, data).then(result => result.data);
    // },
    // put(data){
    //     return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    // },
    // delete(data){
    //     return axios.delete(`${url}/${data._id}`).then(result => result.data);
    // }
}