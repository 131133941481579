var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-searchbar d-lg-none"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('button', {
    staticClass: "mobile-searchbar__close",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("모바일 검색창 닫기")])])]), _c('v-col', [_c('search-bar', {
    staticClass: "pr-12px"
  })], 1)], 1), _c('div', {
    staticClass: "px-18px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('search-recent')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("실시간 검색어")])]), _c('search-rank-list')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }