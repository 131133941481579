var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banner ? _c('swiper', {
    staticClass: "main-header-slide my-30px",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, _vm._l(_vm.banner.slides, function (slide, index) {
    var _slide$meta;
    return _c('swiper-slide', {
      key: index
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "color": "transparent"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 294 / 160
      }
    }, [_c('div', {
      staticClass: "swiper-slide__bg",
      style: 'background-image: url(' + slide.image + ')'
    }), _c('div', {
      staticClass: "swiper-slide__txt",
      staticStyle: {
        "white-space": "pre-line"
      },
      domProps: {
        "innerHTML": _vm._s(slide === null || slide === void 0 ? void 0 : (_slide$meta = slide.meta) === null || _slide$meta === void 0 ? void 0 : _slide$meta.title)
      }
    })])], 1)], 1);
  }), 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }