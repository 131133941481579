var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-bottom-navigation', {
    staticClass: "button-navigation button-navigation--bottom"
  }, [_c('v-btn', {
    attrs: {
      "color": "#fff"
    },
    on: {
      "click": function ($event) {
        return _vm.access('mypage');
      }
    }
  }, [_c('span', [_vm._v("Mypage")]), _c('div', {
    staticClass: "navigation-icon navigation-icon--mypage"
  })]), _c('v-btn', {
    attrs: {
      "color": "#fff"
    },
    on: {
      "click": function ($event) {
        return _vm.access('mini');
      }
    }
  }, [_c('span', [_vm._v("Mini Home")]), _c('div', {
    staticClass: "navigation-icon navigation-icon--minihome"
  })]), _c('v-btn', {
    attrs: {
      "color": "#fff",
      "to": "/center/notice"
    }
  }, [_c('span', [_vm._v("CScenter")]), _c('div', {
    staticClass: "navigation-icon navigation-icon--cscenter"
  })]), _c('v-btn', {
    attrs: {
      "color": "#fff"
    },
    on: {
      "click": function ($event) {
        return _vm.access('cart');
      }
    }
  }, [_c('span', [_vm._v("Cart")]), _c('div', {
    staticClass: "navigation-icon navigation-icon--cart"
  })]), _c('v-btn', {
    attrs: {
      "color": "#fff",
      "to": "/story/bicycle"
    }
  }, [_c('span', [_vm._v("Story")]), _c('div', {
    staticClass: "navigation-icon navigation-icon--story"
  })])], 1), _c('v-card', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "button-navigation button-navigation--aside"
  }, [_c('v-btn', {
    attrs: {
      "color": "#fff"
    },
    on: {
      "click": function ($event) {
        return _vm.access('mypage');
      }
    }
  }, [_c('div', {
    staticClass: "navigation-icon navigation-icon--mypage"
  }), _c('span', [_vm._v("Mypage")])]), _c('v-btn', {
    attrs: {
      "color": "#fff"
    },
    on: {
      "click": function ($event) {
        return _vm.access('mini');
      }
    }
  }, [_c('div', {
    staticClass: "navigation-icon navigation-icon--minihome"
  }), _c('span', [_vm._v("Mini Home")])]), _c('v-btn', {
    attrs: {
      "color": "#fff",
      "to": "/center/notice"
    }
  }, [_c('div', {
    staticClass: "navigation-icon navigation-icon--cscenter"
  }), _c('span', [_vm._v("CScenter")])]), _c('v-btn', {
    attrs: {
      "color": "#fff"
    },
    on: {
      "click": function ($event) {
        return _vm.access('cart');
      }
    }
  }, [_c('div', {
    staticClass: "navigation-icon navigation-icon--cart"
  }), _c('span', [_vm._v("Cart")])]), _c('v-btn', {
    attrs: {
      "color": "#fff",
      "to": "/story/bicycle"
    }
  }, [_c('div', {
    staticClass: "navigation-icon navigation-icon--story"
  }), _c('span', [_vm._v("Story")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }