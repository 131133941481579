/** @type {import("vuex").Module} */
export const bandBanners = {
    namespaced: true,
    state: {
        items: [],
        disableds: [],
    },
    mutations: {
        setItems(state, { bandBanners }) {
            state.items = bandBanners;
        },
        hideItem(state, { _id }) {
            console.log("hidItem");
            state.disableds.push(_id);
        },
    },
};

export default bandBanners;
