<template>
    <v-chip-group class="txt txt--xs font-weight-light">
        <v-chip v-for="(tag, index) in tags" :key="index" v-bind="{ ...chip_secondary }" @click.prevent.stop="search(tag)"># {{ tag }}</v-chip>
    </v-chip-group>
</template>

<script>
import api from "@/api";
import { chip_secondary } from "@/assets/variables";
import { COMMON_LOG_TYPES } from "@/assets/variables";
import { mapMutations } from 'vuex';
export default {
    props: {
        tags: { type: Array, default: () => ([]) },
    },
    data: () => ({
        chip_secondary,
    }),
    computed:{
        searches() {
            return this.$store.state.searches;
        },   
    },
    methods:{
        ...mapMutations(["setSearches"]),
        async search(tag){
            try {
                await api.v1.shop.logs.saving({
                    type: COMMON_LOG_TYPES.SEARCH.value,
                    value: tag,
                }); 
                if(this.searches.length > 4) this.searches.shift();
                this.setSearches({ searches: [...this.searches, tag ]});

                this.$router.push({ path: "/search", query: { 
                    searchValue: tag,
                }});
            } catch(error){ 
                this.$handleError(error);
            }
        }
    }
}
</script>

<style>

</style>