var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper', {
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.logs, function (log, index) {
    return [_c('swiper-slide', {
      key: index
    }, [_c('v-row', {
      staticClass: "row--xxs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "text-center",
      attrs: {
        "width": "24"
      }
    }, [_c('span', {
      staticClass: "tit tit--xxs font-weight-medium"
    }, [_vm._v(_vm._s(index + 1))])])], 1), _c('v-col', {
      staticClass: "d-flex align-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "text-center",
      attrs: {
        "width": "24"
      }
    }, [!log.diff && log.diff !== 0 ? _c('div', {
      staticClass: "accent2--text font-size-10"
    }, [_vm._v("NEW")]) : _vm._e(), log.diff > 0 ? _c('v-icon', {
      staticClass: "accent2--text"
    }, [_vm._v("arrow_drop_up")]) : _vm._e(), log.diff < 0 ? _c('v-icon', {
      staticClass: "secondary--text text--lighten-2"
    }, [_vm._v("arrow_drop_down")]) : _vm._e()], 1)], 1), _c('v-col', [_c('v-sheet', {
      staticClass: "rank-title-id"
    }, [_c('div', {
      staticClass: "txt cursor-pointer white text-truncate",
      on: {
        "click": function ($event) {
          return _vm.go(log._id);
        }
      }
    }, [_vm._v(_vm._s(log._id))])])], 1)], 1)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }