<template>
    <v-card tile class="border-b pb-24px pb-md-32px" :to="`./minishop/${item._id}`">
        <v-row align="center" class="row--xxs">
            <v-col cols="auto">
                <v-avatar class="border">
                    <v-img v-if="item.thumb" :src="item.thumb.url"></v-img>
                    <v-img v-else src="/images/minishop/profile-default.png"></v-img>
                </v-avatar>
            </v-col>
            <v-col cols="auto" class="txt txt--dark font-weight-medium"> {{ item.nickname }} </v-col>
            <v-spacer class="d-lg-none" />
            <v-col cols="auto pl-lg-60px">
                <follow-btn v-if="item._id !== _user" :params="{ _follower: item._id, followings: follows }" @input="emit">
                    <template #activator="{ active, toggle, loading }">
                        <!-- <v-btn v-if="!active" v-bind="{ ...btn_quaternary, loading }" small class="product-item__favorite v-btn--width-fixed" @click.stop.capture="toggle">팔로우</v-btn> -->
                        <v-btn v-if="!active" v-bind="{ ...btn_quaternary, loading }" small class="product-item__favorite v-btn--width-fixed" @click.prevent="toggle">팔로우</v-btn>
                        <v-btn v-else v-bind="{ ...btn_tertiary, loading }" small class="v-btn--width-fixed" @click.prevent="toggle"> 팔로우 취소 </v-btn>
                    </template>
                </follow-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from "@/api";
import { btn_tertiary, btn_quaternary } from "@/assets/variables";
import FollowBtn from "@/components/client/follow/follow-btn.vue";
export default {
    components: {
        FollowBtn,
    },
    props: {
        item: { type: Object, default: () => ({}) },
    },
    data: () => ({
        btn_tertiary,
        btn_quaternary,
        loading: false,
        summary: {
            totalCount: 0,
        },
        follows: [],
    }),
    computed: {
        _user() {
            return this.$store.state.payload._user;
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { summary, follows } = await api.v1.me.follows.gets({
                    params: { _user: this._user },
                });
                this.follows = follows;
                this.summary = summary;
                this.loading = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        emit() {
            this.search();
            // this.$emit("input");
        },
    },
    mounted() {
        if (this._user) this.search();
    },
};
</script>

<style lang="scss" scoped>
</style>