var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('map-main-search-bar'), _c('map-main-search-content')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("가까운 AS 협력점 찾기")]), _c('p', {
    staticClass: "txt txt--dark"
  }, [_vm._v("위치(예 : 우편번호, 주소, 도시 등)를 입력하면 가까운 위치의 AS 협럭점이 표시됩니다.")])]);

}]

export { render, staticRenderFns }