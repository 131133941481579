<template>
    <swiper :options="swiperOptions">
        <template v-for="(log, index) in logs">
            <swiper-slide :key="index">
                <v-row class="row--xxs">
                    <v-col cols="auto">
                        <v-card width="24" class="text-center">
                            <span class="tit tit--xxs font-weight-medium">{{ index + 1 }}</span>
                        </v-card>
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center">
                        <v-card width="24" class="text-center">
                            <div v-if="!log.diff && log.diff !== 0" class="accent2--text font-size-10">NEW</div>
                            <v-icon v-if="log.diff > 0" class="accent2--text">arrow_drop_up</v-icon>
                            <v-icon v-if="log.diff < 0" class="secondary--text text--lighten-2">arrow_drop_down</v-icon>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-sheet class="rank-title-id">
                            <div class="txt cursor-pointer white text-truncate" @click="go(log._id)">{{ log._id }}</div>
                        </v-sheet>
                    </v-col>
                </v-row>
            </swiper-slide>
        </template>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    props: {
        logs: { type: Array, default: () => [] },
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        swiperOptions: {
            // direction: "vertical",
            effect: "fade",
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
        },
    }),
};
</script>