import Vue from "vue";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";

/**
 * @param {Vue} Vue
 * @param {*} options
 */
const install = function (Vue, options) {
    Vue.prototype.$dayjs = dayjs;
    Vue.prototype.$dayjs.locale("ko");
    Vue.prototype.$dayjs.extend(weekOfYear);
};

Vue.use({ install });

export default dayjs;
