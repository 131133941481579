var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "px-18px px-lg-24px py-20px py-lg-40px",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "rounded-sm px-20px px-lg-24px py-18px",
    attrs: {
      "elevation": "8"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-card-title', {
    staticClass: "pa-0 txt txt--dark font-weight-bold"
  }, [_vm._v("알림설정")])], 1), _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('label', {
    staticClass: "txt txt--dark txt--sm cursor-pointer",
    attrs: {
      "for": "badge"
    }
  }, [_vm._v("배지 아이콘 표시")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', _vm._b({
    attrs: {
      "id": "badge"
    },
    on: {
      "change": function ($event) {
        return _vm.update(_vm.form);
      }
    },
    model: {
      value: _vm.form.isShow,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isShow", $$v);
      },
      expression: "form.isShow"
    }
  }, 'v-switch', Object.assign({}, _vm.attrs_switch), false))], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "rounded-sm px-20px px-lg-24px py-18px",
    attrs: {
      "elevation": "8"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-card-title', {
    staticClass: "pa-0 txt txt--dark font-weight-bold"
  }, [_vm._v("알림 종류별 설정")])], 1), _vm._l(_vm.switchValues, function (value, index) {
    return _c('v-row', {
      key: index,
      staticClass: "row--xs",
      attrs: {
        "justify": "space-between"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('label', {
      staticClass: "txt txt--dark txt--sm cursor-pointer",
      attrs: {
        "for": "product"
      }
    }, [_vm._v(_vm._s(value.text))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-switch', _vm._b({
      attrs: {
        "id": "product"
      },
      on: {
        "change": function ($event) {
          return _vm.updateDisabled(_vm.switchValues);
        }
      },
      model: {
        value: value.switch,
        callback: function ($$v) {
          _vm.$set(value, "switch", $$v);
        },
        expression: "value.switch"
      }
    }, 'v-switch', Object.assign({}, _vm.attrs_switch), false))], 1)], 1);
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }