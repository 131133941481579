var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tags ? _c('v-card', {
    staticClass: "px-14px py-16px pa-lg-30px",
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--xs font-weight-medium"
  }, [_vm._v("나의 추천태그")])]), _c('v-row', {
    staticClass: "row--xxs txt txt--dark font-weight-light cursor-pointer"
  }, _vm._l(_vm.tags, function (tag, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto"
      },
      on: {
        "click": function ($event) {
          return _vm.go(tag === null || tag === void 0 ? void 0 : tag._id);
        }
      }
    }, [_vm._v("# " + _vm._s(tag === null || tag === void 0 ? void 0 : tag._id))]);
  }), 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }