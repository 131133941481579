<template>
    <v-row class="row--xxs pt-6px pt-lg-0 pb-32px pb-lg-64px">
        <v-col order-lg="2">
            <v-text-field v-model="query['searchValue']" dense v-bind="{ ...attrs_input_secondary }" @keyup.enter="search" enterkeyhint="go" />
        </v-col>
        <v-col cols="auto" order-lg="3">
            <v-btn text icon class="d-lg-none" @click="search">
                <v-img max-width="20" src="/images/icon/icon-search.svg" />
            </v-btn>
            <v-btn small v-bind="{ ...btn_primary, loading }" class="d-none d-lg-block v-btn--width-fixed" @click="search">검색</v-btn>
        </v-col>
        <v-col cols="12" lg="auto" order-lg="1">
            <v-select v-model="query['mode']" :items="searchKeyItems" dense v-bind="{ ...attrs_input }" class="d-lg-none pr-52px" />
            <v-select v-model="query['mode']" :items="searchKeyItems" dense v-bind="{ ...attrs_input_secondary }" class="d-none d-lg-flex" />
        </v-col>
    </v-row>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_input_secondary, btn_primary, COMMON_LOG_TYPES } from "@/assets/variables";
import { mapMutations } from "vuex";

let initQuery = (query = {}) => ({
    ...query,
    ["mode"]: query?.["mode"] || null,
    ["searchValue"]: query?.["searchValue"] || null,
});

export const SEARCH_LIST = {
    PRODUCT: { text: "상품", value: "PRODUCT" },
    STORY: { text: "스토리", value: "STORY" },
    MINISHOP: { text: "미니샵", value: "MINISHOP" },
    USER: { text: "닉네임", value: "USER" },
};

export default {
    data: () => ({
        loading: false,
        query: initQuery(),
        searchValue: null,

        attrs_input,
        attrs_input_secondary,
        btn_primary,
    }),
    computed: {
        searchKeyItems() {
            return [{ text: "전체게시글", value: null }, ...Object.values(SEARCH_LIST)];
        },
        searches() {
            return this.$store.state.searches;
        },
    },
    methods: {
        ...mapMutations(["setSearches"]),
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.query["searchValue"] && this.query["searchValue"] !== this.searchValue) {
                    await api.v1.shop.logs.saving({
                        type: COMMON_LOG_TYPES.SEARCH.value,
                        value: this.query["searchValue"],
                    });

                    if (this.searches.length > 4) this.searches.shift();
                    this.setSearches({ searches: [...this.searches, this.query["searchValue"]] });
                }
                this.setQuery();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        setQuery() {
            const { ...query } = this.query || {};
            delete query.page;

            if (!query?.["mode"]) delete query["mode"];
            if (!query?.["searchValue"]) delete query["searchValue"];

            this.searchValue = this.query["searchValue"];

            const header = document.querySelector(".header");
            header.setAttribute("data-searchbar", "closed");
            header.setAttribute("data-dimmed", "closed");

            this.$router.push({ path: "/search", query });
        },
        sync() {
            this.query = initQuery(this.$route.query);
            this.searchValue = this.query["searchValue"];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
};
</script>

<style>
</style>