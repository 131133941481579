var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _vm.searches.length ? _c('v-chip-group', _vm._l(_vm.searches, function (search, index) {
    return _c('v-chip', {
      key: index,
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.go(search);
        }
      }
    }, [_vm._v(_vm._s(search))]);
  }), 1) : _c('p', {
    staticClass: "txt--lg"
  }, [_vm._v("최근 입력한 검색어가 없습니다.")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("최근 검색어")])]);

}]

export { render, staticRenderFns }