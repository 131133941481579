var render = function render(){
  var _vm$previewProduct, _vm$previewProduct$pr, _vm$previewProduct$pr2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-avatar', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "aside-button-product",
    attrs: {
      "size": "62"
    },
    on: {
      "click": _vm.toggleShows
    }
  }, [_c('v-img', {
    attrs: {
      "src": (_vm$previewProduct = _vm.previewProduct) === null || _vm$previewProduct === void 0 ? void 0 : (_vm$previewProduct$pr = _vm$previewProduct.product) === null || _vm$previewProduct$pr === void 0 ? void 0 : (_vm$previewProduct$pr2 = _vm$previewProduct$pr.thumb) === null || _vm$previewProduct$pr2 === void 0 ? void 0 : _vm$previewProduct$pr2.url
    }
  })], 1), _vm.shows ? [_vm._l(_vm.productItems, function (item, index) {
    var _item$product, _item$product$thumb;
    return _c('v-avatar', {
      key: index,
      staticClass: "aside-button-item",
      style: {
        marginRight: `${index * 80}px`
      },
      attrs: {
        "size": "62",
        "color": "white"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`/shop/products/${item._product}`);
        }
      }
    }, [_c('v-img', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$thumb = _item$product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-icon', {
            attrs: {
              "color": "grey lighten-2"
            }
          }, [_vm._v("mdi-image-broken-variant")])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _c('v-avatar', {
    staticClass: "aside-button-item",
    style: {
      marginRight: `${_vm.productItems.length * 80}px`
    },
    attrs: {
      "size": "62",
      "color": "rgba(255,255,255,0.7)"
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }