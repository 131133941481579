var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-section"
  }, [this.$slots['containerImmersive'] ? _vm._t("containerImmersive") : _c('v-container', {
    class: _vm.containerSize
  }, [_c('tit-wrap-subtitle', _vm._b({
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'tit-wrap-subtitle', _vm.$attrs, false)), _vm._t("default")], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }